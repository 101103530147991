import * as Elements from "../../../components/Elements";


function Retail() {
    

    return (
        <>
            <Elements.InnerBanner  slim="py-0" title="Retail" subtitle="Jack of All Trades" />
            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                                Local search is crucial for retailers of all types. Local searchers have the highest intent to visit your store out of all searchers. In fact, 76% of people who use location search on mobile phones visit a business within a day, and 28% of those searches result in a purchase. Maximize your opportunity to turn those searchers into repeat, loyal customers with the Amazio Knowledge Engine, securely hosted through Amazon Web Services (AWS).
                            </p>
                            <p className="mt-4">
                                Here are products Amazio offers as a managed service for retailers of all types and sizes.
                            </p>
                            <h5 className="fw-bold">1. Leverage data to reach local searches</h5>
                            <p className="">
                                Retailers rely heavily on local searches to attract customers. Leverage data so you can maximize your reach and connect with people who are using local search queries to find retailers like yours using the Amazio Knowledge Engine.
                            </p>
                            <h5 className="mt-4 fw-bold">2. Enjoy third-party integrations</h5>
                            <p className="">
                                Your third-party integrations ensure you stay in business by managing your payments, scheduling, accounting, and other business needs. Amazio seamlessly integrates with all of your third-party integrations.
                            </p>
                            <h5 className="mt-4 fw-bold">3. Use software to attract customers</h5>
                            <p className="">
                                Our software will help you attract customers by targeting potential customers who are already searching for retailers like you in their location. This will help you increase sales and expand your existing customer base.
                            </p>
                            <h5 className="mt-4 fw-bold">4. Monitor and adapt to changing information</h5>
                            <p className="">
                                Change can happen fast in the retail industry, so it's good to be prepared. Amazio's Knowledge engine will make it easy to monitor and adapt to changing information so you are never behind the curve, no matter what surprise gets thrown your way. You'll stay on top of trends in retail so you can adjust your business needs accordingly as you need to.
                            </p>
                            <h5 className="mt-4 fw-bold">5. Empower and personalize teams at all locations</h5>
                            <p className="">
                                Even chain retailers vary from location to location. Make sure every one of your retail locations is able to personalize their Amazio Knowledge Base to their specific needs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Retail