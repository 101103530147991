import OwlCarousel from "react-owl-carousel";

function ClientTestimonials() {
    const optionsReview = {
		margin: 20,
		responsiveClass: true,
		nav: false,
		dots: true,
		autoplayTimeout : 2500,
		autoHeight: true,
		autoplay : true,
		responsive: {
			0: {
				items: 1,
				autoHeight: true
			},
			992: {
				items: 2,
				autoHeight: true,
				margin: 30,
			}
		},
	}; 
	return (
		<>
          <div className="new-review-wrapper">
                <OwlCarousel className="owl-theme px-1" {...optionsReview}>
                        <div className="new-review-card">
                            <div className="new-review-head">
                            <div className="new-review-head-thumb">
                                <img src="images/reviews/digital-tandem.jpg" alt="digital tandem" />
                            </div>
                            <div className="new-review-head-content">													
                                <h5>Digital Tandem</h5>
                            </div>
                            <img src="images/new/quote.png" alt="" />
                        </div>
                        <div className="new-review-body">												
                            <p>
                                <strong>Amazio</strong> helped grow our customer engagement by managing our social content & reviews while being brand concious. Our local marketing has improved and so has our customer base. Thank you for the guidance & insights based on true analytics. Highly recommened their managed services!
                            </p>
                        </div> 
                        </div>
                        <div className="new-review-card">
                            <div className="new-review-head">
                            <div className="new-review-head-thumb">
                                <img src="images/reviews/mega-marketing-group.jpg" alt="i3 Mega Marketing Group" />
                            </div>
                            <div className="new-review-head-content">													
                                <h5>i3 Mega Marketing Group</h5>
                            </div>
                            <img src="images/new/quote.png" alt="" />
                        </div>
                        <div className="new-review-body">												
                            <p>
                                <strong>Amazio has been a great partner to work with.</strong> The dashboard has helped us manage our client’s online presence and scale our agency’s services.
                            </p>
                        </div> 
                        </div>
                        <div className="new-review-card">
                            <div className="new-review-head">
                            <div className="new-review-head-thumb">
                            <img src="images/reviews/cara-leising.jpg" alt="Cara Leising" />
                            </div>
                            <div className="new-review-head-content">													
                                <h5>Cara Leising</h5>
                                <h6>Liberty Hotel</h6>
                            </div>
                            <img src="images/new/quote.png" alt="" />
                        </div>
                        <div className="new-review-body">												
                            <p>
                                <strong>Amazio helps the Liberty Hotel spread the word of what is happening at our hotel but also in travel.</strong> Since we became a client, Amazio has made a post to our facebook page 5 dates a month since June and has had a reach up to 295 with Engagements up to 136. Their understanding of the digital world gives us the ability to share more than just a photo and a thought. If I need to reach someone from Amazio, they are a quick phone call away. You will find everyone in that company to be professional and quick to respond.
                            </p>
                        </div> 
                        </div>
                        <div className="new-review-card">
                            <div className="new-review-head">
                            <div className="new-review-head-thumb">
                            <img src="images/reviews/nicol.jpg" alt="Nicole Arend" />
                            </div>
                            <div className="new-review-head-content">													
                                <h5>Nicole Arend</h5>
                                <h6>Senior Manager, SOCi</h6>
                            </div>
                            <img src="images/new/quote.png" alt="" />
                        </div>
                        <div className="new-review-body">												
                            <p>
                            <strong>AMAZIO</strong> has created a robust platform that now manages clients digital presence, social media and reputation management on both a self-serve and full-service experience. By partnering with SOCi, AMAZIO has grown rapidly in the short months we came together to provide customers an integrated experience with listings management, social media publishing, and review management. I recommend AMAZIO to any SMB company looking for a single platform that is easy to use and manage their digital presence and care.
                            </p>
                        </div> 
                        </div>
                        <div className="new-review-card">
                            <div className="new-review-head">
                            <div className="new-review-head-thumb">
                                <img src="images/reviews/pfp.jpg" alt="Perfect Promo" />
                            </div>
                            <div className="new-review-head-content">													
                                <h5>Perfect Promo</h5>
                            </div>
                            <img src="images/new/quote.png" alt="" />
                        </div>
                        <div className="new-review-body">												
                            <p>
                            <strong>Great service!</strong>
                            Just the few weeks they started managing my online presence my sales greatly improved. I didn't know how important social media could be for a growing business until the team at Amazio explained it. Awesome people to work with, very understanding and Knowledgeable.
                            </p>
                        </div> 
                        </div>
                        <div className="new-review-card">
                            <div className="new-review-head">
                            <div className="new-review-head-thumb">
                                <img src="images/new/adept.jpg" alt="Adept Consultants" />
                            </div>
                            <div className="new-review-head-content">													
                                <h5>Adept Consultants</h5>
                            </div>
                            <img src="images/new/quote.png" alt="" />
                        </div>
                        <div className="new-review-body">												
                            <p>
                            <strong>Adept Consultants </strong>approached us with a clear vision: to help their clients establish a strong digital presence. Our team worked closely with Adept to craft personalized branding strategies and implement effective social media management campaigns for their clients. From creating compelling content to executing data-driven advertising strategies, we ensured their clients gained visibility and engagement. Together, we delivered measurable growth, turning Adept’s client goals into digital success stories.</p>
                        </div> 
                        </div>
                        <div className="new-review-card">
                            <div className="new-review-head">
                            <div className="new-review-head-thumb">
                                <img src="images/new/cohort.jpg" alt="Cohort Data" />
                            </div>
                            <div className="new-review-head-content">													
                                <h5>Cohort Data</h5>
                            </div>
                            <img src="images/new/quote.png" alt="" />
                        </div>
                        <div className="new-review-body">												
                            <p>
                            When <strong>Cohort Data </strong>sought our expertise, they needed innovative ways to enhance their clients' digital branding and social media impact. We, at Amazio, partnered with Cohort to design and manage tailored campaigns, leveraging analytics to refine strategies and maximize results. Our collaborative efforts empowered Cohort’s clients to build meaningful online relationships and achieve their branding objectives. Amazio’s dedication to quality and results made all the difference for Cohort Data and their clientele.										
                            </p>
                            </div> 
                        </div>
                </OwlCarousel>
            </div>
        </>		
	)
}

export default ClientTestimonials