import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function PrintMedia() {
	
	return (
		<>
			
			<Elements.InnerBanner title="Print Media" />

			<div className="section-padding">
				<div className="container">
					<div className="row align-items-center g-4">
						<div className="col-md-10 col-lg-8 mx-auto">
							<div className="service-content-box">
								<div className="new-heading mb-0 text-center">
									<h4>Your Story, Our Prints, Endless Impressions
									</h4>
									<p>Let us transform your ideas into stunning print media that leaves a lasting impression.
									</p>
									<div className="mt-3">
										<Link to="/plans-pricing" className="new-btn btn-gradiant" >Get a Quote</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="section-padding border-top">
				<div className="container">
					<div className="row ">
						<div className="col-md-6 col-lg-5">
							<div className="service-img-box mb-4 mb-md-0">
								<img src="images/service-imgs/s9.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-0">
									<h4>Your Vision Deserves More than Just Pixels. </h4>
									<p>In an increasingly digital world, it's easy to underestimate the power of tangible media. But print still holds the unique ability to connect, inspire, and create lasting memories. Whether it's a flyer in hand, a brochure on a desk, or a billboard on the street, print materials bring your brand to life in ways digital formats simply can't replicate.</p>
									<p> <strong>Introducing Amazio</strong> - your creative partner in print. We specialize in delivering high-impact print media solutions that not only meet but exceed your expectations. Our mission is simple: to translate your vision into powerful print assets that engage, inform, and captivate your audience.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Our Wide Range of Print Media Services
								</h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Brochures & Flyers:
										</h5>
										<p>Make your message portable and impactful with our eye-catching designs and premium-quality prints.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Posters & Banners:
										</h5>
										<p>Bold, bright, and brilliant perfect for events, advertising, or creating an unmissable statement.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Business Cards: 
										</h5>
										<p>Leave a memorable mark with sleek, professional cards that reflect your brand identity.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i>Magazines & Catalogs:
										</h5>
										<p>Showcase your products and services in stunning detail, with print quality that speaks volumes.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Packaging & Labels:
										</h5>
										<p>Custom-designed solutions that make your products stand out on any shelf.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Signage & Billboards:
										</h5>
										<p>High-visibility prints that ensure your message reaches the masses.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s9.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Our Roadmap to Print Media Excellence
								</h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s9.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Consultation:
										</h5>
										<p>We start with you. Tell us your needs, goals, and vision.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Design & Conceptualization: 
										</h5>
										<p>Our creative team drafts designs that resonate with your brand's message.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Proofing & Approval:
										</h5>
										<p>You review and approve every detail to ensure perfection.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Precision Printing: 
										</h5>
										<p>Using state-of-the-art technology, we produce high-quality prints.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Delivery & Beyond: 
										</h5>
										<p>We deliver your prints on time, and we're here for future adjustments and needs.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ">
								<div className="new-heading mb-4">
									<h4>Why Choose Amazio?
									</h4>
								</div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Quality That Speaks:
										</h5>
										<p>We combine creativity with high-end printing technology to deliver flawless results.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Customization: 
										</h5>
										<p>No two brands are alike. We tailor every project to your unique identity.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> On-Time Delivery:
										</h5>
										<p>We respect deadlines as much as we respect quality.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Sustainability: 
										</h5>
										<p>We care about the planet. Our eco-friendly practices ensure responsible printing.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Passionate Experts: 
										</h5>
										<p>Our team lives and breathes print media. Your vision is in good hands.
										</p>
									</li>
								</ul>
							</div>
						</div>                      
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s9.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
					</div>
				</div>
			</div>
			<div className="section-padding pt-0">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="bottom-service-ctabox">
								<div className="ps-4">
									<h4>Ready to Transform Your Ideas into Print Perfection? </h4>
									<div className="bottom-service-cta mt-3">
										<Link to="/contact-us" className="new-btn btn-gradiant" >
										Get a Quick Estimate
										</Link>
									</div>
								</div>
								<img src="images/service-cta.jpg" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PrintMedia