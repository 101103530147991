import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'intl-tel-input/build/css/intlTelInput.css';
import { useNavigate, Link } from "react-router-dom";
import { fetchData, ONBOARDING_FORM, ADD_DROPDOWN_DATA } from "../../components/Service"
import { validateForm, state, reactSelectValidation, intlTel_phone, validatePhone } from "../../components/Helper"
import * as Elements from "../../components/Elements";
import Keywords from '../../components/Keywords';
import Competitor from '../../components/Competitor';
import PaymentMethod from '../../components/PaymentMethod';
import { addField, jsReload } from '../ManageBusinessListing/LocationJs';

function OnboardingForm() {
    
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [category, setCategory] = useState([])
    const [intlTel, setIntlTel] = useState()
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    const submitFormData = (e) => {
        let formData = new FormData(document.getElementById('onBoardingForm'));
        if (validateForm(e, 'onBoardingForm') && validatePhone(intlTel, '#main_phone')) {
            let phone = document.getElementById("main_phone").value
            phone = phone.replaceAll("-", "")
            formData.append('main_phone', phone)
            
            fetchData(ONBOARDING_FORM, 'POST', formData, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    setTinyloader(false)
                    navigate('/')
                }
            })
        }
    }

    useEffect(() => {
        setIntlTel(intlTel_phone('#main_phone'))
        jsReload()
        document.querySelectorAll('.account-section').forEach(ele => {
            ele.addEventListener('change', (e) => {
                var detailSection = e.target.id.replace(/Input/g, '')
                var allInputTag = document.querySelector(`#${detailSection}`).getElementsByTagName('input');

                if (e.target.checked) {
                    [...allInputTag].forEach(element => {
                        element.value = '';
                        element.disabled = true;
                    })
                } else {
                    [...allInputTag].forEach(element => {
                        element.disabled = false;
                    })
                }
            }, false);
        });

        fetchData(`${ADD_DROPDOWN_DATA}?select=category`, 'GET', '', true, false, (res) => {
            if (res && res.records && res.records.length > 0) {
                setCategory(res.records)
            }
        }, false, false, false);

    }, [])

    const showFeilds = (id = '') => {
        var element = document.getElementById(id);
        element.classList.toggle("d-none");
    }

    return (
        <>
            <Elements.InnerBanner slim="true" title="Onboarding Form" />

            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <form className="row px-3 px-sm-0 justify-content-center needs-validation" id="onBoardingForm" method="post" noValidate>
                                <div className="col-md-12 shadow-sm bg-white rounded p-3 px-md-5">
                                    <h4 className="fw-bold mt-0 mb-3 mb-md-4"><span className="text-warning">&bull;</span> Digital Listings</h4>
                                    <div className="row">
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">Country <strong className="text-danger">*</strong></label>
                                            <select className="form-select" required name="country">
                                                <option value="United States">United States</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">Business Name <strong className="text-danger">*</strong></label>
                                            <input type="text" className="form-control" placeholder="Business Name" defaultValue="" name="business_name" id="business_name" required />
                                            <div className="invalid-feedback">The business name field is required</div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">PC number (Business Alias) <strong className="text-danger">*</strong></label>
                                            <input type="text" className="form-control" placeholder="Enter PC number" defaultValue="" name="business_alias" id="business_alias" required/>
                                            <div className="invalid-feedback">The PC number field is required.</div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="category_ids" className="form-label fw-bold mb-1">Categories<strong className="text-danger">*</strong></label>
                                            <Select
                                                placeholder={'-- Select Categories --'}
                                                value={category.name}
                                                options={category ?? []}
                                                isMulti={true}
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                onChange={(e) => { reactSelectValidation(e, 'category_ids'); }}
                                                className="basic-multi-select react-select"
                                                name="category_ids[]"
                                                id="category_ids"
                                                required
                                            />
                                            <div className="invalid-feedback">The categories field is required</div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">Address <strong className="text-danger">*</strong></label>
                                            <input type="text" name="address" id="address" className="form-control" defaultValue="" placeholder="Address" required />
                                            <div className="invalid-feedback">The address field is required</div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">City <strong className="text-danger">*</strong></label>
                                            <input type="text" name="city" id="city" defaultValue="" className="form-control" placeholder="City" required />
                                            <div className="invalid-feedback">The city field is required</div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">State <strong className="text-danger">*</strong></label>
                                            <select className="form-select scan-select px-2" name="state" id="state" defaultValue="" placeholder="State" required>
                                                <option value="">--Select State--</option>
                                                {
                                                    Object.keys(state).map((ele, num) => <><option value={ele} key={num}>{state[ele]}</option></>)
                                                }
                                            </select>
                                            <div className="invalid-feedback">The state field is required</div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">Zip <strong className="text-danger">*</strong></label>
                                            <input type="text" onKeyPress={(e) => { if (!(e.charCode >= 48 && e.charCode <= 57)) e.preventDefault(); }} name="zip_code" id="zip_code" minLength={4} maxLength={9} defaultValue="" className="form-control" placeholder="Zip" required />
                                            <div className="invalid-feedback">The zip field is required</div>
                                        </div>
                                      
                                        <div className="col-md-6 mb-3 mb-xxl-4 intl_phone">
                                            <label htmlFor="" className="form-label fw-bold mb-1">Business Phone No <strong className="text-danger">*</strong></label>
                                            <input type="tel" name="main_phone" id="main_phone" minLength={8} maxLength={15} defaultValue="" className="form-control" placeholder="Business Phone No" required />
                                            <div className="invalid-feedback">The business phone field is required</div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">Featured message/Tagline<strong className="text-danger">*</strong> :</label>
                                            <input type="text" name="featured_message" id="featured_message" defaultValue="" className="form-control" placeholder="Featured message/Tagline" required />
                                            <div className="invalid-feedback">The featured message/tagline field is required</div>
                                        </div>
                                        <div className="col-md-12 mb-3 mb-xxl-4">
                                            <label htmlFor="" className="form-label fw-bold mb-1">Business Description: <strong className="text-danger">*</strong></label>
                                            <textarea className="form-control minh-140" minLength={10} maxLength={1000} name="business_description" id="business_description" placeholder="Business Description" defaultValue="" required></textarea>
                                            <div className="invalid-feedback">The business description field is required</div>
                                        </div>

                                        <div className="row p-0 mb-3 mx-0">
                                            <Keywords />
                                        </div>

                                        <div className="row p-0 mb-3 mx-0">
                                            <Competitor />
                                        </div>

                                        <div className="row p-0 mb-3 mx-0">
                                            {/* <BusinessHrs /> */}
                                            <div className="col-sm-4 col-lg-3 fw-bold pt-1">Business Hours:</div>
                                            <div className="col-sm-8 col-lg-9" >
                                                <div className="col-md-12">
                                                    <label className="form-check mb-0 fs-16px d-inline-block">
                                                        <input className="form-check-input" type="checkbox" value="" id='specifyBusinghours' data-specify="#specifyBusinghours" />
                                                        <div className="mb-2">
                                                            <input type="checkbox" className="form-check-input me-2" id="business_hours" name='specifyBusinesshours' onClick={() => showFeilds('hours')} />
                                                            <label className="form-check-label" htmlFor="business_hours">
                                                                Specify business hours
                                                            </label>
                                                        </div>

                                                    </label>
                                                    <div className="d-none" id="hours">
                                                        <div className="row mt-3" id="editHour">
                                                            {weekDays.map((item, index) => {
                                                                return (<>
                                                                    <div className="input-group mb-3" key={index}>
                                                                        <input type="text" readOnly className="form-control" name="days[]" value={item} />
                                                                        <select className="form-select split_time" name="business_hourtype[]">
                                                                            <option value="">Select</option>
                                                                            <option value="open">Open</option>
                                                                            <option value="split">Split</option>
                                                                            <option value="24">24 Hours</option>
                                                                            <option value="close">Close</option>
                                                                        </select>
                                                                        <input type="text" className="form-control" name="business_hour_from[]" readOnly placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" defaultValue="00:00" />
                                                                        <span className="input-group-text">to</span>
                                                                        <input type="text" className="form-control col-4" name="business_hour_to[]" readOnly placeholder="00:00" pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}" defaultValue="00:00" />
                                                                    </div>
                                                                </>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-2">
                                                    <input type="text" name="additional_hours_info" id="additional_hours_info" className="form-control" placeholder="Additional Hours Info" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row p-0 mb-3 mx-0">
                                            {/* <HolidayHrs /> */}
                                            <div className="col-sm-4 col-lg-3 fw-bold pt-1">Holidays Hours:</div>
                                            <div className="col-sm-8 col-lg-9" >
                                                <div className="col-md-12" id="holiday_hours">
                                                    <button type="button" className="btn btn-sm d-inline-flex align-items-center btn-outline-dark" onClick={() => addField('holiday_hours')}>
                                                        <i className="bi bi-plus-circle-fill bi-1p2 me-2"></i> Add Another
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row p-0 mb-3 mx-0">
                                            <div className="col-sm-4 col-lg-3 fw-bold mt-2">Payment Method:</div>
                                            <div className="col-sm-8 col-lg-9 mt-2">
                                                <div className="row row-cols-2 row-cols-md-3">
                                                    <PaymentMethod />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row p-0 mb-3 mx-0">
                                            <div className="col-sm-4 col-lg-3 fw-bold pt-9px pt-2">Contact Email: <strong className="text-danger">*</strong></div>
                                            <div className="col-sm-8 col-lg-9">
                                                <input type="email" name="email" id="email" className="form-control" defaultValue="" required />
                                                <div className="invalid-feedback">The email field is required</div>
                                                <div className="row">
                                                    <div className="col-lg-6 mt-3">
                                                        <input type="url" name="main_website" id="main_website" defaultValue="" className="form-control" placeholder="Main Website" />
                                                        <div className="invalid-feedback">Enter valid url</div>
                                                    </div>
                                                    <div className="col-lg-6 mt-3">
                                                        <input type="url" name="display_website" id="display_website" defaultValue="" className="form-control" placeholder="Display Website" />
                                                        <div className="invalid-feedback">Enter valid url</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row p-0 mb-3 mx-0">
                                            <div className="col-sm-4 col-lg-3 fw-bold pt-2 mt-2">Business Logo:</div>
                                            <div className="col-sm-8 col-lg-9 mt-2">
                                                <input type="file" accept=".jpg,.jpeg,.png" className="form-control" id="business_logo" name="business_logo" defaultValue="" />
                                                <div className="invalid-feedback">Invalid image format</div>
                                            </div>
                                        </div>
                                        <div className="row p-0 mx-0">
                                            <div className="col-sm-4 col-lg-3 fw-bold pt-2 mt-2">More Photos:</div>
                                            <div className="col-sm-8 col-lg-9 mt-2">
                                                <input type="file" accept=".jpg,.jpeg,.png" className="form-control" name="more_images[]" id="more_images" multiple />
                                                <div className="invalid-feedback">Invalid image format</div>
                                                <div className="small font-wt700 text-muted py-1">Select Multiple Files:</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 shadow-sm bg-white rounded p-3 px-md-5 mt-3">
                                    <h3 className="fw-bold mt-0 mb-3 mb-md-4">
                                        <span className="text-warning">&bull;</span> Social Media Management
                                    </h3>
                                    <div className="row p-0 mb-3">
                                        <div className="col-sm-4 col-lg-3 fw-bold">YouTube Video:</div>
                                        <div className="col-sm-8 col-lg-9">
                                            <button type="button" className="btn btn-sm d-inline-flex align-items-center btn-outline-dark" data-bs-target="#youtubelink" data-bs-toggle="collapse">
                                                <i className="bi bi-youtube bi-1p3 me-2"></i> Add YouTube Video
                                            </button>
                                            <div className="collapse" id="youtubelink">
                                                <div className="py-3">
                                                    <input type="url" name="youtube_video" id="youtube_video" defaultValue="" className="form-control" placeholder="Please Enter YouTube Video Link" />
                                                    <div className="invalid-feedback">Enter valid url </div>
                                                </div>
                                            </div>


                                            <hr className="opacity-10 my-4" />

                                            <div className="row g-3 mb-4">
                                                <div className="col-lg-6">
                                                    <input type="url" name="twitter_handle" id="twitter_handle" defaultValue="" className="form-control" placeholder="Twitter Handle" />
                                                    <div className="invalid-feedback">Enter valid url </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <input type="url" name="facebook_page_url" id="facebook_page_url" defaultValue="" className="form-control" placeholder="Facebook Page URL" />
                                                    <div className="invalid-feedback">Enter valid url </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <input type="url" name="instagram_handle" id="instagram_handle" defaultValue="" className="form-control" placeholder="Instagram Handle" />
                                                    <div className="invalid-feedback">Enter valid url </div>
                                                </div>
                                            </div>


                                            <div className="card mt-2 mb-3">
                                                <div className="card-header">
                                                    <label className="form-check mb-0 fs-16px d-inline-block">
                                                        <input className="form-check-input account-section" id="googleInput" type="checkbox" name="create_google" data-inabledisable=".googlefields" />
                                                        <div className="form-check-label pt-2px text-common fw-bold">
                                                            The account doesn't exist, please create.
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="card-body py-2" id="google">
                                                    <div className="row">
                                                        <div className="col-lg-6 my-2">
                                                            <input type="url" name="google_my_business_url" id="google_my_business_url" defaultValue="" className="form-control googlefields" placeholder="Google my business page url" />
                                                            <div className="invalid-feedback">Enter valid url </div>
                                                        </div>
                                                        <div className="col-lg-6 my-2">
                                                            <input type="text" name="google_my_business_username" id="google_my_business_username" className="form-control googlefields" defaultValue="" placeholder="Google my business username" />
                                                        </div>
                                                        <div className="col-lg-12 my-2">
                                                            <input type="text" name="google_my_business_password" id="google_my_business_password" className="form-control googlefields" defaultValue="" placeholder="Google my business password" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-2 mb-3">
                                                <div className="card-header">
                                                    <label className="form-check mb-0 fs-16px d-inline-block">
                                                        <input className="form-check-input account-section" type="checkbox" id="facebookInput" name="create_facebook" data-inabledisable=".facebookfields" />
                                                        <div className="form-check-label pt-2px text-common fw-bold">
                                                            The account doesn't exist, please create.
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="card-body py-2" id="facebook">
                                                    <div className="row">
                                                        <div className="col-lg-6 my-2">
                                                            <input type="url" name="facebook_url" id="facebook_url" defaultValue="" className="form-control facebookfields" placeholder="Facebook page url" />
                                                            <div className="invalid-feedback">Enter valid url </div>
                                                        </div>
                                                        <div className="col-lg-6 my-2">
                                                            <input type="text" name="facebook_username" id="facebook_username" defaultValue="" className="form-control facebookfields" placeholder="Facebook username" />
                                                        </div>
                                                        <div className="col-lg-12 my-2">
                                                            <input type="text" name="facebook_password" id="facebook_password" defaultValue="" className="form-control facebookfields" placeholder="Facebook password" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-2 mb-3">
                                                <div className="card-header">
                                                    <label className="form-check mb-0 fs-16px d-inline-block">
                                                        <input className="form-check-input account-section" type="checkbox" id="linkedinInput" name="create_linkedin" data-inabledisable=".linkedinfields" />
                                                        <div className="form-check-label pt-2px text-common fw-bold">
                                                            The account doesn't exist, please create.
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="card-body py-2" id="linkedin">
                                                    <div className="row">
                                                        <div className="col-lg-6 my-2">
                                                            <input type="url" name="linkedin_page_url" id="linkedin_page_url" defaultValue="" className="form-control linkedinfields" placeholder="Linkedin page url" />
                                                            <div className="invalid-feedback">Enter valid url </div>
                                                        </div>
                                                        <div className="col-lg-6 my-2">
                                                            <input type="text" name="linkedin_username" id="linkedin_username" defaultValue="" className="form-control linkedinfields" placeholder="Linkedin username" />
                                                        </div>
                                                        <div className="col-lg-12 my-2">
                                                            <input type="text" name="linkedin_password" id="" defaultValue="" className="form-control linkedinfields" placeholder="Linkedin password" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mt-2 mb-3">
                                                <div className="card-header">
                                                    <label className="form-check mb-0 fs-16px d-inline-block">
                                                        <input className="form-check-input account-section" type="checkbox" id="twitterInput" name="create_twitter" data-inabledisable=".twitterfields" />
                                                        <div className="form-check-label pt-2px text-common fw-bold">
                                                            The account doesn't exist, please create.
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="card-body py-2" id="twitter">
                                                    <div className="row">
                                                        <div className="col-lg-6 my-2">
                                                            <input type="url" name="twitter_page_url" id="twitter_page_url" defaultValue="" className="form-control twitterfields" placeholder="Twitter page url" />
                                                            <div className="invalid-feedback">Enter valid url </div>
                                                        </div>
                                                        <div className="col-lg-6 my-2">
                                                            <input type="text" name="twitter_username" id="twitter_username" defaultValue="" className="form-control twitterfields" placeholder="Twitter username" />
                                                        </div>
                                                        <div className="col-lg-12 my-2">
                                                            <input type="text" name="twitter_password" id="twitter_password" defaultValue="" className="form-control twitterfields" placeholder="Twitter password" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card mb-0 mt-2">
                                                <div className="card-header">
                                                    <label className="form-check mb-0 fs-16px d-inline-block">
                                                        <input className="form-check-input account-section" type="checkbox" id="instagramInput" name="create_instagram" data-inabledisable=".instagramfields" />
                                                        <div className="form-check-label pt-2px text-common fw-bold">
                                                            The account doesn't exist, please create.
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="card-body py-2" id="instagram">
                                                    <div className="row">
                                                        <div className="col-lg-6 my-2">
                                                            <input type="url" name="instagram_page_url" id="instagram_page_url" className="form-control instagramfields" placeholder="Instagram page url" />
                                                            <div className="invalid-feedback">Enter valid url </div>
                                                        </div>

                                                        <div className="col-lg-6 my-2">
                                                            <input type="text" name="instagram_username" className="form-control instagramfields" placeholder="Instagram username" />
                                                        </div>
                                                        <div className="col-lg-12 my-2">
                                                            <input type="text" name="instagram_password" className="form-control instagramfields" placeholder="Instagram password" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12 py-3 px-3 px-md-5">
                                    <div className="row p-0">
                                        <div className="col-sm-8 offset-sm-4 col-lg-9 offset-lg-3">
                                            <div className="mb-1">
                                                <label className="form-check mb-0 fs-16px d-inline-block">
                                                    <input className="form-check-input" type="checkbox" value="yes" name="need_website" id="" />
                                                    <div className="form-check-label pt-2px text-common">
                                                        I'd like to be contacted for my business website needs
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-check mb-0 fs-16px d-inline-block">
                                                    <input className="form-check-input" type="checkbox" value="yes" name="term" required />
                                                    <div className="form-check-label pt-2px text-common">
                                                        I have read and agree to the <Link to="/terms-conditions" className="text-decoration-none">Terms and Conditions</Link> of service with Amazio
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-8 offset-sm-4 col-lg-9 offset-lg-3">
                                            <button type="button" className="btn btn-lg btn-primary px-4 px-md-5" onClick={(e) => submitFormData(e)} disabled={tinyloader}>
                                                {!tinyloader ? 'Submit' :
                                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OnboardingForm
