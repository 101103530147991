import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function DigitalIntegration() {
    

    return (
        <>

            <Elements.InnerBanner title="Integrated Digital Marketing Services"/>
            <div className="section-padding">
                <div className="container">
                    <div className="row align-items-center g-4">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <div className="service-content-box">                                
                                <div className="new-heading mb-0 text-center">
                                   <h4>Empowering Your Brand with Seamless Digital Integration</h4>
                                   <p>Unlock growth with Amazio’s integrated digital marketing services – where strategy, creativity, and technology converge to drive impactful results.
                                   </p>
                                   {/* <div className="mt-3">
                                       <Link to="#" className="new-btn btn-gradiant" >Contact Us Today</Link>
                                   </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding border-top">
                <div className="container">
                    <div className="row align-items-lg-center ">
                        <div className="col-lg-8 mx-auto">                                                      
                            <div className="new-heading text-md-center mb-4 mb-md-5">
                                <h4>Integrated Digital Marketing Services by Amazio</h4>
                                <p>At Amazio, we specialize in delivering results-driven digital marketing solutions tailored to your business needs. Our integrated approach ensures every aspect of your online presence works together seamlessly to achieve your goals. Discover our core services below</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ">      
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Search Engine Optimization (SEO):
                                        </h5>
                                        <p>Boost your website's visibility and drive organic traffic with our advanced SEO techniques. From keyword optimization to technical audits, we ensure your site ranks high in search engine results.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Social Media Optimization (SMO):
                                        </h5>
                                        <p>Enhance your brand’s online presence through strategic social media optimization. We create engaging profiles, optimize posts, and improve reach to build a loyal audience across platforms.</p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Social Media Marketing (SMM):
                                        </h5>
                                        <p>Maximize your social media potential with targeted campaigns. From creative content to paid promotions, we help you connect with the right audience and drive engagement.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Search Engine Marketing (SEM):
                                        </h5>
                                        <p>Achieve immediate visibility with our SEM services. Our expert team manages pay-per-click (PPC) campaigns and optimizes ad spending to maximize your ROI.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Email Marketing:
                                        </h5>
                                        <p>Reach your audience directly with personalized email campaigns. We design, execute, and analyze impactful email strategies that drive conversions and customer retention.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Lead Generation:
                                        </h5>
                                        <p>Fuel your sales pipeline with qualified leads. Our lead generation strategies identify and attract prospects who are genuinely interested in your offerings.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Content Marketing:
                                        </h5>
                                        <p>Engage and inspire your audience with high-quality content. From blogs to videos, we craft content that drives traffic, builds trust, and boosts conversions.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Influence Marketing:
                                        </h5>
                                        <p>Leverage the power of influencers to amplify your brand message. We connect you with the right influencers to enhance credibility and expand your reach.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Mobile Marketing:
                                        </h5>
                                        <p>Stay ahead in a mobile-first world with targeted mobile marketing. From app promotions to SMS campaigns, we ensure your brand connects with users on the go.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Analytics and Data-Driven Marketing:
                                        </h5>
                                        <p>Make informed decisions with our data-driven marketing insights. We analyze campaign performance and customer behavior to refine strategies and achieve better results.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/Integrated-1.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-lg-8 mx-auto">                                                      
                            <div className="new-heading text-md-center mb-4 mb-md-5">
                                <h4>Why Choose Amazio?</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ">       
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Expertise and Experience:
                                        </h5>
                                        <p>With years of experience in digital marketing, our team brings unparalleled expertise to the table. We understand what it takes to succeed in a competitive online landscape.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Customized Solutions:
                                        </h5>
                                        <p>We believe every business is unique. That’s why we tailor our strategies to align with your specific goals, industry, and target audience.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Integrated Approach:
                                        </h5>
                                        <p>Our services work together seamlessly to create a cohesive digital marketing strategy. This integrated approach ensures maximum impact and efficiency.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Cutting-Edge Tools and Techniques:
                                        </h5>
                                        <p>We leverage the latest technologies and trends to stay ahead of the curve. Our data-driven strategies ensure your campaigns deliver measurable results.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Dedicated Support:
                                        </h5>
                                        <p>Our commitment to your success goes beyond strategy. We provide ongoing support and regular updates to ensure you’re always in the loop.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4  mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/integrated-2.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding pt-0">
                <div className="container">
                    <div className="row">                        
                        <div className="col-md-12">
                            <div className="bottom-service-ctabox">
                                <div className="ps-4">
                                    <h4>Ready To Unlock The Full Potential Of Your Online Presence?
                                    </h4>
                                    <p className="mb-0">Partner with Amazio for your integrated digital marketing needs.  
                                    </p>
                                    <div className="bottom-service-cta mt-3">
                                        <Link to="/contact-us" className="new-btn btn-gradiant" >Let’s Talk</Link>
                                    </div>   
                                </div>                        
                                <img src="images/service-cta.jpg" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalIntegration