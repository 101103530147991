import * as Elements from "../../../components/Elements";

function Government() {
    

    return (
        <>
            <Elements.InnerBanner  slim="py-0" title="Government" subtitle="Accurate, timely information" />

            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                            At Amazio, we value the work provided by public servants at the federal, state, and local levels. We also recognize the challenges presented by today's ever-evolving technologies and ever-tightening budgets. The Amazio Knowledge Engine, securely hosted through Amazon Web Services (AWS), helps you deliver perfect information into the palm of every citizen.
                            </p>
                            <p className="mt-4 ">
                            Here are some ways Amazio works with government offices:
                            </p>

                            <h5 className="fw-bold">1. Deliver the information your citizens need when they need it</h5>
                            <p className="">
                            During weather emergencies, major shutdowns, or other important events at the state and local levels, citizens need information, and they need it fast. Make sure people who follow your city or state's official websites and social media pages always have the most up-to-date and accurate information. Trust Amazio to keep your citizens informed across all channels.
                            </p>

                            <h5 className="mt-4 fw-bold">2. Leverage data to optimize for location-based searches</h5>
                            <p className="">
                            Geolocating is great for helping citizens find the right information about their town, city, or state. Amazio will use data to make sure people get the accurate and timely information they need when they perform a local search.
                            </p>

                            <h5 className="mt-4 fw-bold">3. Store, manage and consolidate data</h5>
                            <p className="">Having the data of your site visitors is important, but it's even more important to keep that information safe and secure. Amazio can store, manage, and consolidate personal data so you can easily access it when you need it.</p>
                        
                            <h5 className="mt-4 fw-bold">4. Use the right software to attract visitors</h5>
                            <p className="">
                            You have the information people need. Now, how do you reach your target audience? Amazio can help you reach your target audience at the right place and time.
                            </p>

                            <h5 className="mt-4 fw-bold">5. Centralize control of listings</h5>
                            <p className="">
                            You can keep centralized control of listings to make sure information about your office is accurate, no matter where it lives on the web.
                            </p>

                            <h5 className="mt-4 fw-bold">6. Use rich content</h5>
                            <p className="">
                            Rich content, including graphics, images, videos, and more are incredibly useful for providing your citizens with accessible information. Trust Amazio to create amazing rich content that will provide your citizens with the valuable information they need in a digestible format they can quickly access and understand.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Government