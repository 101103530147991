import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function Digital() {
    

    return (
        <>

            <Elements.InnerBanner title="Business Digital Listing & Branding"/>

            {/* <section className="py-5">
                <div className="container py-md-4">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <img src="images/digital-presence.jpg" className="w-100 shadow rounded p-2" alt="Digital Presence"/>
                        </div>
                        <div className="col-md-6">
                            <p>
                                No matter what type of business you run, your online presence is an important way to show current and potential clients who you are and what you can do. Your online presence is more than just your website (although that's certainly important). It includes every online website, listing, and platform that has information about your brand, including social media platforms and online listings.
                            </p>
                            <p className="mt-2">
                                Keeping up with your online presence can be a challenge, no matter what stage of business development you're in. But it's important to make sure all the information presented about your company online is accurate so your&shy; customers and potential customers can find you and reach out to you when they need to. Neglecting your online presence can end up costing you time and money you can't afford to lose.
                            </p>
                            <p className="mt-2">
                                Fortunately, you don't have to manage your digital presence alone! Amazio can help you build and maintain your digital presence, no matter what your business is. We offer digital presence services as a managed service, so you don't need to do any of the work. Amazio will handle everything from setting up your social media pages to engaging with your users and keeping track of analytics.
                            </p>
                            <p className="mt-2">
                                Whether you're an established business with a large online presence that needs to be managed or you're a brand new business that needs to establish an online presence, here's how we can help.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
            <div className="section-padding">
                <div className="container">
                    <div className="row align-items-center g-4">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <div className="service-content-box">                                
                                <div className="new-heading mb-0 text-center">
                                   <h4>From Local to Global - One Click Away.</h4>
                                   <p>Ready to be discovered? Let’s make your business unforgettable, attract more customers, and build a brand that resonates. 
                                   </p>
                                   <div className="mt-3">
                                       <Link to="/contact-us" className="new-btn btn-gradiant" >Contact Us Today</Link>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding border-top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s1.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">                                
                                <div className="new-heading mb-0">
                                <h4>Hire a Professional Branding Company to Establish a Dominant Market Presence</h4>
                                <p>If customers can’t find you online or don’t trust your digital presence, you're losing out on valuable opportunities. At Amazio, we provide comprehensive Business Digital Listing and Branding services designed to get your business discovered, recognized, and trusted. Whether you’re a local enterprise or a growing brand, our solutions ensure that your business appears on major digital platforms, maintains consistent branding, and engages the right audience.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-lg-center border-top pt-5 mt-5">
                        <div className="col-lg-8 mx-auto">                                                      
                            <div className="new-heading text-md-center mb-4 mb-md-5">
                                <h4>Explore Our Broad Spectrum of Business Digital Listing & Branding Services</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ">      
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Digital Business Listings:
                                        </h5>
                                        <p>Ensure your business is listed accurately across major platforms like Google My Business, Yelp, Bing, and industry-specific directories.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Brand Development & Design:
                                        </h5>
                                        <p>Craft a distinctive visual identity with logos, colors, and design elements that reflect your business values.</p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Social Media Branding:
                                        </h5>
                                        <p>Create cohesive and visually appealing social media profiles that resonate with your target audience.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Online Reputation Management:
                                        </h5>
                                        <p>Improve your online reviews, monitor feedback, and build trust with a positive digital footprint.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> SEO-Optimized Profiles:
                                        </h5>
                                        <p>Boost your digital visibility with profiles optimized for search engines, driving organic traffic to your business.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s1.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                    <div className="row border-top pt-5 mt-5 align-items-center">
                        <div className="col-lg-8 mx-auto">                                                      
                            <div className="new-heading text-md-center mb-5">
                                <h4>Our Complete Roadmap to Business Digital Listing & Branding</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s1.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">     
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Consultation & Assessment:
                                        </h5>
                                        <p>We understand your business needs, audience, and current digital standing.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Strategic Planning:
                                        </h5>
                                        <p>Develop a tailored digital listing and branding strategy to ensure maximum exposure and consistency.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Implementation:
                                        </h5>
                                        <p>List your business on key platforms, optimize profiles, and design a cohesive brand identity.
                                        </p>
                                    </li>
                                    <li>                                        
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Monitoring & Enhancement:
                                        </h5>
                                        <p>Continuously track your digital presence, making improvements and updates to keep your brand relevant.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Reporting & Insights:
                                        </h5>
                                        <p>Provide monthly reports to show growth, performance metrics, and areas of opportunity.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-column-reverse flex-md-row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ">                                
                                <div className="new-heading mb-4">
                                    <h4>Why Makes Amazio Ideal for Business Digital Listing & Branding?</h4>
                                </div>
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Comprehensive Approach:
                                        </h5>
                                        <p>We cover everything from digital listings to branding consistency.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Tailored Solutions:
                                        </h5>
                                        <p>Custom strategies that fit your business goals and target market.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Expertise You Can Trust:
                                        </h5>
                                        <p>Our team has experience in helping businesses get discovered online.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4  mb-4 mb-md-0">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s1.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding pt-0">
                <div className="container">
                    <div className="row">                        
                        <div className="col-md-12">
                            <div className="bottom-service-ctabox">
                                <div className="ps-4">
                                    <h4>Ready to Elevate Your Brand? 
                                    </h4>
                                    <p className="mb-0">Let’s Start Your Digital Journey Today!
                                    </p>
                                    <div className="bottom-service-cta mt-3">
                                        <Link to="/contact-us" className="new-btn btn-gradiant" >Contact Us</Link>
                                    </div>   
                                </div>                        
                                <img src="images/service-cta.jpg" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Digital