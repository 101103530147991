import React, { useContext, useEffect, useState } from "react";
import 'intl-tel-input/build/css/intlTelInput.css';
import { useNavigate, Link } from "react-router-dom";
import { fetchData, PLAN_PRICE_LISTING, FAQ_LIST, ADD_REQUEST_DEMO, UPGRADE_PLAN } from "../../components/Service"
import { validateForm, intlTel_phone, validatePhone } from "../../components/Helper"
import _ from "lodash"
import { Context } from "../../components/Context";
import * as Elements from "../../components/Elements";

function Pricing({ trialMode = false, UpgradePlan = false, setBillingDetails, PlanStatus }) {

    const navigate = useNavigate();
    const [data, setData] = useState();
    const [faq, setFaq] = useState('');
    const [intlTel, setIntlTel] = useState()
    const [tinyloader, setTinyloader] = useState(false);
    const [total, setTotal] = useState(0)
    var total_simple = total > 0 ? total : '0';
    const calculatedValueCustomer = (total_simple / 30);
    const [amazioTotal, setAmazioTotal] = useState(0)
    var total_plus = amazioTotal > 0 ? amazioTotal : '0'
    const calculatedValueAmazio = (total_plus / 30);
    const [trialTotal, setTrialTotal] = useState(0)
    var total_trial = trialTotal > 0 ? trialTotal : '0';
    const calculatedValueTrial = (total_trial / 30);
    const [TrialDays, setTrialDays] = useState(1);
    const [buttons, setButtons] = useState([]);
    const [context] = useContext(Context)
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch available plans from API
        fetch('/api/plan-price-details')
            .then(response => response.json())
            .then(data => setPlans(data))
            .catch(error => console.error('Error fetching plans:', error));
    }, []);

    const selectPlan = (plan) => {
        setSelectedPlan(plan);
    };

    const initiateTrial = () => {
        if (!selectedPlan) {
            alert('Please select a plan to start the trial.');
            return;
        }
        setLoading(true);
        // Call API to start trial
        fetch('/api/start-trial', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ planId: selectedPlan.id })
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.success) {
                    navigate('/dashboard');
                } else {
                    alert('Error starting trial: ' + data.message);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error starting trial:', error);
            });
    };

    useEffect(() => {
        if(PlanStatus == 'Inactive' || context?.auth?.user_plan === null){
            setButtons([
                { title: '', url: '/sign-up', className: 'btn-warning', disabled: true, action: false },
                { title: 'Subscribe', url: '/sign-up', className: 'btn-warning', disabled: false, action: true, type: 'upgradePlan', key: 'signup_customer' },
                { title: 'Subscribe', url: '/sign-up', className: 'btn-warning', disabled: false, action: true, type: 'upgradePlan', key: 'signup_amazio' },
            ])
        }else{
            if (trialMode) {
                const planType = context?.auth?.user_plan?.plan_type;
                if (planType === 'Trial') {
                    setButtons([
                        { title: 'Active', url: '/sign-up', className: 'btn-primary', disabled: true, action: false, key: 'signup_trial' },
                        { title: 'Upgrade', url: '/sign-up', className: 'btn-warning', disabled: false, action: true, type: 'upgradePlan', key: 'signup_customer' },
                        { title: 'Upgrade', url: '/sign-up', className: 'btn-warning', disabled: false, action: true, type: 'upgradePlan', key: 'signup_amazio' },
                    ])
                } else if (planType === 'Simple Start') {
                    setButtons([
                        { title: '', url: '/sign-up', className: 'btn-warning', disabled: true, action: false },
                        { title: 'Active', url: '/sign-up', className: 'btn-primary', disabled: true, action: false },
                        { title: 'Upgrade', url: '/sign-up', className: 'btn-warning', disabled: false, action: true, type: 'upgradePlan', key: 'signup_amazio' },
                    ])
                } else if (planType === 'Plus') {
                    setButtons([
                        { title: '', url: '/sign-up', className: 'btn-warning', disabled: true, action: false },
                        { title: '', url: '/sign-up', className: 'btn-warning', disabled: true, action: false },
                        { title: 'Active', url: '/sign-up', className: 'btn-primary', disabled: true, action: (e) => null },
                    ])
                } else {
                    setButtons([
                        { title: 'Upgrade', url: '/sign-up', className: 'btn-warning', disabled: true, action: true, key: 'signup_trial' },
                        { title: 'Upgrade', url: '/sign-up', className: 'btn-warning', disabled: true, action: true, key: 'signup_customer' },
                        { title: 'Upgrade', url: '/sign-up', className: 'btn-warning', disabled: true, action: true, key: 'signup_amazio' },
                    ])
                }
            } else {
                setButtons([
                    { title: '7 Day Trial - Sign Up', url: '/sign-up', className: 'btn-warning', disabled: false, action: true, key: 'signup_trial' },
                    { title: 'Sign Up', url: '/sign-up', className: 'btn-warning', disabled: false, action: true, key: 'signup_customer' },
                    { title: 'Sign Up', url: '/sign-up', className: 'btn-warning', disabled: false, action: true, key: 'signup_amazio' },
                ])
            }
        }
    }, [context, trialMode, PlanStatus])

    const saveData = (e) => {
        e.preventDefault();
        let formData = new FormData(document.getElementById('multiBusinessForm'));
        formData.append('business_phone', intlTel.getNumber())
        if (validateForm(e, 'multiBusinessForm') && validatePhone(intlTel, '#business_phone')) {
            setTinyloader(true)
            fetchData(ADD_REQUEST_DEMO, 'POST', formData, true, true, (res) => {
                if (res.success) {
                    setTinyloader(false)
                    navigate('/')
                }
            })
        }
    }

    useEffect(() => {
        if (context && context.auth) {
            // user_group_id == 3 means user is logged in 
            if (context.auth.user_group_id == 3) {
                navigate('/users/account-setting')
            } else {
                navigate('/admin/admin-dashboard');
            }

        }
        setIntlTel(intlTel_phone('#business_phone'))

        fetchData(FAQ_LIST, 'GET', '', true, false, (res) => {
            if (res.data) {
                var data = _.orderBy(res.data, ['rank'], ['asc']);
                setFaq(data);
            }
        })

        fetchData(PLAN_PRICE_LISTING + '?type=front', 'GET', '', true, false, (res) => {
            if (res.records) {
                setData(res.records)
                setTotal(res.records.length > 0 ? res.records[0].total_simple : 0)
                setAmazioTotal(res.records.length > 0 ? res.records[0].total_plus : 0)
                setTrialTotal(res.records.length > 0 ? res.records[0].total_trial : 0)
                setTrialDays(res.records.length > 0 ? res.records[0].trial_days : 0)
            }
        })

    }, []);
    const updateElements = (selector, checked) => {
        let amount = 0;
        document.querySelectorAll(selector).forEach(element => {
            if (!element.checked) { amount += parseFloat(element.value); }
            element.checked = checked;
            element.setAttribute('disabled', checked);
        });
        return amount;
    };

    const updateLocalStorage = (typehint, type, className, calculatedValue) => {
        let final_price = 0;
        if (typehint === 'signup_customer') {
            final_price=(total)
            
        } else if (typehint === 'signup_amazio') {
            final_price=(amazioTotal)
        } else if (typehint === 'signup_trial') {
            final_price=(total)
        }
        
        let simple_services = '';
        document.querySelectorAll(className).forEach(element => {
            if (element.checked) {
                simple_services += element.id + ',';
            }
        });
    
        

        localStorage.setItem('pricing', `${type}||${simple_services.slice(0, -1)}||${calculatedValue.toFixed(2)}||${total.toFixed(2)}||${TrialDays}||${final_price}`);
    };

    const customerManaged = (e, type) => {
        e.preventDefault();
        let amount = 0;

        if (_.includes(e.target.classList, 'reputation-customer')) {
            amount += updateElements(".business-customer", e.target.checked);
        } else if (_.includes(e.target.classList, 'reputation-amazio')) {
            amount += updateElements(".business-amazio", e.target.checked);
        }


        if (type === 'self' || type === 'amazio') {
            amount += e.target.checked ? parseFloat(e.target.value) : -parseFloat(e.target.value);
            type === 'self' ? setTotal(_.add(total, amount)) : setAmazioTotal(_.add(amazioTotal, amount));
        }

        if (type === 'signup_customer') {
            updateLocalStorage(type, 'Simple Start', ".customer", calculatedValueCustomer);
            
        } else if (type === 'signup_amazio') {
            updateLocalStorage(type, 'Plus', ".amazio", calculatedValueAmazio);
        } else if (type === 'signup_trial') {
            updateLocalStorage(type, 'Trial', ".customer", calculatedValueCustomer);
        }

        if (!UpgradePlan) {
            navigate('/sign-up');
        }

    };

    const upgradePlan = (e, key) => {
        customerManaged(e, key);
        setBillingDetails(true)
    }

    return (
        <>

            <style>{`
                .btn__group .btn[disabled] {
                    opacity: .5;
                    pointer-events: none;
                    cursor: none;
                }
            `}</style>
            {/* <Elements.InnerBanner  slim="py-0" title="Plans & Pricing"/> */}
            <section className="pt-5 position-relative z-1">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-7 text-center text-md-start mb-4 mb-md-5 pe-md-5">
                            <h1 className="fw-bold">Choose the Right Plan for Your Business</h1>
                            <p>Our flexible pricing plans are designed to scale with your needs, whether you're a startup or an established global enterprise.</p>
                            <div className="d-flex flex-wrap gap-2 justify-content-center justify-content-md-start">
                                <Link to="/sign-up" className="new-btn btn-gradiant"  >Get Started Today
                                </Link>
                            </div>
                        </div>
                        <div className="col-8 col-md-5 pt-4 px-md-5">
                            <img src="images/plan-price.png" className="w-100" alt="contact" />
                        </div>
                    </div>
                </div>
                <div className='z-n1 position-absolute top-0 start-0 w-100 h-100 bg-gradiant2'></div>
            </section>
            <section className="pricing section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="pricing-box">
                                <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                                    {trialMode ? '' :
                                        <>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active fw-bolder" id="pills-individual-tab" data-bs-toggle="pill" data-bs-target="#tab_individual" type="button">
                                                    <div className="py-1">FOR INDIVIDUALS AND PROFESSIONALS</div>
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link fw-bolder" id="pills-business-tab" data-bs-toggle="pill" data-bs-target="#tab_business" type="button">
                                                    <div className="py-1">MULTI LOCATION BUSINESS</div>
                                                </button>
                                            </li>
                                        </>
                                    }

                                </ul>
                                <div className="tab-content p-3 p-sm-4" id="pills-tabContent">
                                    <div className="tab-pane tab-individual fade show active" id="tab_individual">
                                        <table className="pricing-table table table-bordered text-center">
                                            <thead>
                                                <tr>
                                                    <td className="border-0 fw-bold">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <img src="images/services.svg" className="d-block maxh-28 me-3" alt="services" />
                                                            SERVICES
                                                        </div>
                                                    </td>
                                                    <td className="border-0 fw-bolder">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <img src="images/price.svg" className="d-block maxh-28 me-3" alt="price" />
                                                            PRICE
                                                        </div>
                                                    </td>

                                                    <td className="border-0 fw-bolder" style={{ width: '140px', width: '140px' }}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <img src="images/self-managed.svg" className="d-block maxh-28 me-3" alt="Trial" />
                                                            TRIAL
                                                        </div>
                                                    </td>
                                                    <td className="border-0 fw-bolder" style={{ width: '140px', width: '140px' }}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <img src="images/self-managed.svg" className="d-block maxh-28 me-3" alt="customer" />
                                                            CUSTOMER MANAGED
                                                        </div>
                                                    </td>
                                                    <td className="border-0 fw-bolder" style={{ width: '140px', width: '140px' }}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <img src="images/amazio-managed.svg" className="d-block maxh-28 me-3" alt="amazio" />
                                                            AMAZIO MANAGED ($249.99)
                                                        </div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data && data.map((ele, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-start">
                                                                {ele.name}
                                                                {
                                                                    ele.tooltip ? <span className="ms-2 bi bi-info-circle-fill bi-0p9 d-inline-block" data-bs-placement="top" data-bs-toggle="tooltip" title={ele.tooltip}>
                                                                    </span> : ''
                                                                }

                                                            </td>
                                                            <td>
                                                            {ele.name === 'Custom Website' ? (
                                                                            <>
                                                                                <small>Starting from $</small>{ele.recurring}
                                                                            </>
                                                                        ) : (
                                                                            Number(ele.recurring) === 0 ? (
                                                                                <span className="">Included</span>
                                                                            ) : Number(ele.recurring) ? (
                                                                                Number(ele.offer_price) ? (
                                                                                    <>
                                                                                        <del className="text-secondary">${ele.recurring}</del>
                                                                                        <span className="badge bg-success">${ele.offer_price}</span>
                                                                                    </>
                                                                                ) : (
                                                                                    <span>${ele.recurring}</span>
                                                                                )
                                                                            ) : (
                                                                                ele.recurring
                                                                            )
                                                                        )}
                                                            </td>
                                                            <td>
                                                                {ele.trial === 'dash' ? <i defaultValue={ele.offer_price ? ele.offer_price : ele.recurring} defaultChecked={ele.trial === 'checked' ? true : false} className="bi bi-x-circle-fill bi-1p2 d-inline-block text-danger"></i> : ''}
                                                                {ele.trial === 'tick' ? <><input type="checkbox" className="d-none trial" id={ele.id} defaultChecked={true} /><i className="bi bi-check-circle-fill bi-1p2 d-inline-block text-success"></i></> : ''}
                                                                {ele.trial === 'checkbox' || ele.trial === 'checked' ? <input type="checkbox" className={`form-check-input trial ${_.includes(ele.name, 'Reputation Management') ? 'reputation-customer' : ''} ${_.includes(ele.name, 'Business Digital Listings') ? 'business-customer' : ''}`} id={ele.id} defaultValue={ele.offer_price ? ele.offer_price : ele.recurring} onClick={(e) => customerManaged(e, 'self')} defaultChecked={ele.trial === 'checked' ? true : false} /> : ''}
                                                            </td>
                                                            <td>
                                                                
                                                                {ele.simple === 'dash' ? <i className="bi bi-x-circle-fill bi-1p2 d-inline-block text-danger"></i> : ''}
                                                                {ele.simple === 'tick' ? <><input type="checkbox" className="d-none customer" id={ele.id} defaultChecked={true} /><i className="bi bi-check-circle-fill bi-1p2 d-inline-block text-success"></i></> : ''}
                                                                {ele.simple === 'checkbox' || ele.simple === 'checked' ? <input type="checkbox" className={`form-check-input customer ${_.includes(ele.name, 'Reputation Management') ? 'reputation-customer' : ''} ${_.includes(ele.name, 'Business Digital Listings') ? 'business-customer' : ''}`} id={ele.id} defaultValue={ele.offer_price ? ele.offer_price : ele.recurring} onClick={(e) => customerManaged(e, 'self')} defaultChecked={ele.simple === 'checked' ? true : false} /> : ''}
                                                            </td>

                                                            <td>
                                                                {ele.plus === 'dash' ? <i className="bi bi-x-circle-fill bi-1p2 d-inline-block text-danger"></i> : ''}
                                                                {ele.plus === 'tick' ? <><input type="checkbox" className="d-none amazio" id={ele.id} defaultChecked={true} /><i className="bi bi-check-circle-fill bi-1p2 d-inline-block text-success" id={ele.id}></i></> : ''}
                                                                {ele.plus === 'checkbox' || ele.plus === 'checked' ? <input type="checkbox" className={`form-check-input amazio ${_.includes(ele.name, 'Reputation Management') ? 'reputation-amazio' : ''} ${_.includes(ele.name, 'Business Digital Listings') ? 'business-amazio' : ''}`} id={ele.id} defaultValue={ele.offer_price ? ele.offer_price : ele.recurring} onClick={(e) => customerManaged(e, 'amazio')} defaultChecked={ele.plus === 'checked' ? true : false} /> : ''}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            <tfoot>
                                                {/* <tr>
                                                    <th colSpan="2" className="fw-bold text-start">TOTAL</th>
                                                    <th className="fw-bold bg-gradiant text-white text-center">{` ${TrialDays} Day Trial`}</th>
                                                    <th className="fw-bold bg-gradiant text-white text-center">{`$ ${calculatedValueCustomer.toFixed(2)} /Day`}</th>
                                                    <th className="fw-bold bg-gradiant text-white text-center">{`$ ${calculatedValueAmazio.toFixed(2)} /Day`}</th>
                                                </tr> */}
                                                <tr className="btn__group">
                                                    <td ></td>
                                                    <td >
                                                        {/* <button type="button" onClick={()=>navigate('/sign-up')} >Hii</button> */}
                                                    </td>
                                                    {buttons.map((btn, index) => {
                                                        return <td key={index}>
                                                            {btn.title && <Link to={btn.url} disabled={btn.disabled} style={{whiteSpace:'nowrap'}} className={`new-btn btn-green px-3 ms-3 fw-bolder ${btn.className}`} onClick={(e) => {
                                                                if (btn.action) {
                                                                    btn.type === 'upgradePlan' ? upgradePlan(e, btn.key) : customerManaged(e, btn.key);
                                                                }
                                                            }}>{btn.title}</Link>}
                                                        </td>
                                                    })}
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className="tab-pane fade" id="tab_business">
                                        <div className="row justify-content-center">
                                            <div className="col-sm-12 col-lg-10">
                                                <form method="post" className="row mt-5 needs-validation" id="multiBusinessForm" noValidate>
                                                    <div className="col-sm-6 col-md-6">
                                                        <label className="mb-5 w-100">
                                                            <input type="text" name="first_name" id="first_name" className="form-control form-bottomline" placeholder="First Name" required />
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <label className="mb-5 w-100">
                                                            <input type="text" name="last_name" id="last_name" className="form-control form-bottomline" placeholder="Last Name" required="" />
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <label className="mb-5 w-100">
                                                            <input type="text" name="business_name" id="business_name" className="form-control form-bottomline" placeholder="Business Name" required="" />
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <label className="mb-5 w-100">
                                                            <input type="email" name="business_email" id="business_email" className="form-control form-bottomline" placeholder="Business E-mail" required="" />
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <label className="mb-5 w-100">
                                                            <input type="tel" className="form-control form-bottomline" placeholder="Business Phone Number" aria-label="Business Name" defaultValue="" autoComplete="off" id="business_phone" data-intl-tel-input-id="0" required />
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <select name="time_zone" className="form-select form-bottomline" id="time_zone">
                                                            <option defaultValue="">Select Your Timezone</option>
                                                            <option>EST</option>
                                                            <option>CST</option>
                                                            <option>MST</option>
                                                            <option>PST</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <label className="mb-5 w-100">
                                                            <input type="datetime-local" className="form-control form-bottomline" title="Select Date and time" defaultValue="" name="requested_date" id="requested_date" autoComplete="off" />
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12">
                                                        <textarea className="textarea form-bottomline form-control" rows="4" placeholder="Notes" name="notes" id="notes" maxLength="1000"></textarea>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 py-3">
                                                        <div className="g-recaptcha mb-5" data-sitekey="6Ldbdg0TAAAAAI7KAf72Q6uagbWzWecTeBWmrCpJ"></div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 py-3 text-end">
                                                        <button type="button" className="btn btn-warning fw-bolder mb-5 py-2 px-5" onClick={(e) => saveData(e)} disabled={tinyloader} id="btn-validate">
                                                            {
                                                                !tinyloader ? 'Submit'
                                                                    :
                                                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                            }
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <section className="pricing pt-0 section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="pricing-box ">
                                <div className="nav-pills">
                                    <button className="nav-link w-100 fw-bolder px-3 py-3 bg-gradiant text-center text-white">
                                        AMAZIO - CUSTOM PLAN
                                    </button>
                                </div>
                                <div className="tab-content pt-3 px-1 p-md-4">
                                    <div className="table-responsive">
                                        <table className="pricing-table table table-bordered text-center">
                                            <tbody>
                                                <tr>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Custom Website
                                                    </td>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Price starting from $299
                                                    </td>

                                                    <td className="" style={{width:'290px', minWidth:'290px'}}>
                                                        <a class="new-btn btn-green px-3 py-1 m-0"  href="contact-us?msg=Inquiry%20for%20the%20Custom%20Website." >Book a Free Consultation</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Integrated Digital Marketing
                                                    </td>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Price starting from $299
                                                    </td>

                                                    <td className="" style={{width:'290px', minWidth:'290px'}}>
                                                        <a class="new-btn btn-green px-3 py-1 m-0" href="contact-us?msg=Inquiry%20for%20the%20Integrated%20Digital%20Marketing.">Book a Free Consultation</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Branding and Creative Service
                                                    </td>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Price starting from $199
                                                    </td>

                                                    <td className="" style={{width:'290px', minWidth:'290px'}}>
                                                        <a class="new-btn btn-green px-3 py-1 m-0" href="contact-us?msg=Inquiry%20for%20the%20Branding%20and%20Creative%20Service.">Book a Free Consultation</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Content Services
                                                    </td>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Price starting from $149
                                                    </td>

                                                    <td className="" style={{width:'290px', minWidth:'290px'}}>
                                                        <a class="new-btn btn-green px-3 py-1 m-0" href="contact-us?msg=Inquiry%20for%20the%20Content%20Services.">Book a Free Consultation</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Print Media
                                                    </td>
                                                    <td className="px-4" style={{minWidth:'240px'}}>
                                                            Custom Made
                                                    </td>

                                                    <td className="" style={{width:'290px', minWidth:'290px'}}>
                                                        <a class="new-btn btn-green px-3 py-1 m-0" href="contact-us?msg=Inquiry%20for%20the%20Print%20Media.">Book a Free Consultation</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {((context && trialMode) || (context?.auth?.user_plan === null)) ? '' :
                <section className="container py-5 tab" >
                    <div class="row">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading text-center">
                            <h4>Frequently Asked Questions</h4>
                            <p>Quickly find out whether we have already addressed any questions that may be on your mind. There is no such thing as a stupid question, and we welcome all inquires.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <img className="w-100" src="images/faq.gif" alt="faq" />
                            <p className="fw-bold text-center">
                                Do You Have a Question?<br />
                                We Have an Answer.
                            </p>
                        </div>
                        <div className="col-12 col-md-9" id="faq-section">
                            <div className="accordion" id="accordionExample">
                                {faq && faq.map((ele, i) => {
                                    return (
                                        <div className="accordion-item" key={i}>
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className={`accordion-button ${i !== 0 ? 'collapsed' : ''} fw-bold`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapseThree${i}`} aria-expanded={`${i !== 0 ? 'false' : 'true'}`} aria-controls="collapseThree">
                                                    {ele.question}
                                                </button>
                                            </h2>
                                            <div id={`collapseThree${i}`} className={`accordion-collapse collapse ${i !== 0 ? '' : 'show'}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        {ele.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>}

            <div>
                {plans.map(plan => (
                    <div key={plan.id}>
                        <h3>{plan.name}</h3>
                        <p>{plan.description}</p>
                        <p>Price: ${plan.price}/month</p>
                        <button onClick={() => selectPlan(plan)}>Select Plan</button>
                    </div>
                ))}
                {/* <button onClick={initiateTrial} disabled={!selectedPlan || loading}>
                    {loading ? 'Starting Trial...' : 'Start Trial'}
                </button> */}
            </div>
        </>
    )
}

export default Pricing