import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function SocialMedia() {
	return (
		<>
            <Elements.InnerBanner title="Social Media Management"/>

            <div className="section-padding">
                <div className="container">
                    <div className="row align-items-center g-4">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <div className="service-content-box">                                
                                <div className="new-heading mb-0 text-center">
                                   <h4>Turn Followers into Fans, Fans into Customers.</h4>
                                   <p> <strong>We’re here </strong> to transform your social media presence, making every post count and every campaign resonate. 
                                   </p>
                                   <div className="mt-3">
                                       <Link to="/contact-us" className="new-btn btn-gradiant" >Let’s Talk</Link>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-padding border-top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s2.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">                                
                                <div className="new-heading mb-0">
                                <h4>Harness Our Social Media Management Services to Establish Your Online Presence</h4>
                                <p>In today's digital age, having a social media presence is non-negotiable. At Amazio, we simplify social media management and elevate your brand's online presence. Our team of experts creates engaging content, develops data-driven strategies, and optimizes campaigns to achieve real, measurable results. We take care of your social media, so you can focus on what you do best running your business.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
                                <h4>Explore Our Wide Range of Social Media Management Services</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ">   
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Content Creation & Scheduling:
                                        </h5>
                                        <p>Eye-catching graphics, engaging copy, and timely posting to keep your audience hooked.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Community Management:
                                        </h5>
                                        <p>Active monitoring, responding to comments, and fostering a loyal online community.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Social Media Strategy Development:
                                        </h5>
                                        <p>Data-driven planning tailored to your brand’s goals and target audience.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i>Paid Social Media Advertising:
                                        </h5>
                                        <p>Optimized ad campaigns to drive traffic, increase conversions, and boost ROI.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Social Media Audits & Analysis: 
                                        </h5>
                                        <p>In-depth audits to identify growth opportunities and measure performance.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s2.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
                                <h4>Our Strategic Roadmap to Your Social Media Success</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s2.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">      
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Discovery & Analysis: 
                                        </h5>
                                        <p>We learn about your brand, audience, and goals.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Strategic Planning:
                                        </h5>
                                        <p>Develop a customized social media strategy based on insights and objectives.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Content Creation & Scheduling:
                                        </h5>
                                        <p>Craft visually appealing, on-brand content and plan a consistent posting schedule.
                                        </p>
                                    </li>
                                    <li>                                        
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Engagement & Community Building: 
                                        </h5>
                                        <p>Manage interactions, nurture relationships, and encourage brand loyalty.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Performance Tracking & Optimization:
                                        </h5>
                                        <p>Regularly monitor analytics and refine strategies to maximize results.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
                                    <h4>Why Amazio is an Ideal Social Media Management Partner? </h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box "> 
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Expert Team: 
                                        </h5>
                                        <p>Our social media specialists stay ahead of trends and best practices.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Customized Strategies:
                                        </h5>
                                        <p>No cookie-cutter solutions everything we do is tailored to your brand’s unique needs.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Transparent Reporting: 
                                        </h5>
                                        <p>Clear, detailed reports to show how your social media efforts are paying off.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Time-Saving: 
                                        </h5>
                                        <p>We handle your social media, giving you more time to focus on your business.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Result-oriented:
                                        </h5>
                                        <p>Our goal is to boost engagement, increase followers, and drive conversions.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s2.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding pt-0">
                <div className="container">
                    <div className="row">                        
                        <div className="col-md-12">
                            <div className="bottom-service-ctabox">
                                <div className="ps-4">
                                    <h4>Ready to Elevate Your Brand’s Social Presence? 
                                    </h4>
                                    {/* <p className="mb-0">Boost your brand’s visibility with expert strategies that elevate your social media presence.
                                    </p> */}
                                    <div className="bottom-service-cta mt-3">
                                        <Link to="/contact-us" className="new-btn btn-gradiant" >Schedule a Call</Link>
                                    </div>   
                                </div>                        
                                <img src="images/service-cta.jpg" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</>
	)
}

export default SocialMedia
