import { Link } from "react-router-dom";
import * as Elements from "../../../components/Elements";

function Industries() {
    

    return (
        <>
            <Elements.InnerBanner  slim="py-0" title="Industries"/>

            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-md-12">
                            <div className="services-list-wrapper flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Hospitality</h4>
                                        <h5>Guests, visitors, or strangers.</h5>
                                        <p>
                                            Today’s travelers are mobile and looking to act quickly. In fact, 65% of today’s same-day bookings are made via smartphone. With the Amazio Knowledge Engine, you deliver digital knowledge listings are always accurate, actionable, and differentiated.
                                        </p>
                                        <Link to="/industries/hospitality" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">                                        
                                        <img src="images/industies/1.gif" className="img-cover " alt="Hospitality"/>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/industies/2.gif" className="w-100" alt="Retail"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Retail</h4>
                                        <h5>Jack of All Trades</h5>
                                        <p>
                                            Local searchers have the highest intent to visit your store. In fact, 76% of people who use location search on mobile phones visit a business within a day, and 28% of those searches result in a purchase. Maximize your opportunity to turn those searchers into customers with the Amazio Knowledge Engine.
                                        </p>
                                        <Link to="/industries/retail" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5 flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Food</h4>
                                        <h5>Hungry consumers find you fast</h5>
                                        <p>
                                            Smart devices, voice assistants, and AI are changing the way people make dining decisions. Consumers search for food more than any other business category, so you need to make sure you show up no matter where, when, or how they're searching.
                                        </p>
                                        <Link to="/industries/food" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/industies/3.gif" className="w-100" alt="Food"/>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                         <img src="images/industies/4.gif" className="w-100" alt="Healthcare"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Healthcare</h4>
                                        <h5>Attract more patients</h5>
                                        <p>
                                            Our Healthcare Knowledge Engine is purpose-built to help health systems of all sizes solve their office, facility, and physician digital knowledge challenges, make their websites more discoverable in search, and ultimately attract new patients in the moments that matter most.
                                        </p>
                                        <Link to="/industries/healthcare" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5 flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Insurance</h4>
                                        <h5>Accurate, timely information</h5>
                                        <p>
                                            We value the work provided by public servants at the federal, state, and local levels. We also recognize the challenges presented by today’s ever-evolving technologies and ever-tightening budgets. The Amazio Knowledge Engine, securely hosted through Amazon Web Services (AWS).
                                        </p>
                                        <Link to="/industries/insurance" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/industies/5.gif" className="w-100" alt="Insurance"/>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/industies/6.gif" className="w-100" alt="Government"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Government</h4>
                                        <h5>Accurate, timely information</h5>
                                        <p>
                                            At Amazio, we value the work provided by public servants at the federal, state, and local levels. We also recognize the challenges presented by today’s ever-evolving technologies and ever-tightening budgets. The Amazio Knowledge Engine, securely hosted through Amazon Web Services (AWS), helps you deliver perfect information into the palm of every citizen.
                                        </p>
                                        <Link to="/industries/government" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5 flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Franchises</h4>
                                        <h5>All-in-one enterprise</h5>
                                        <p>
                                            You don’t have a lot of time; after all, you have a business to run. And yet, you know that you must attract today’s consumers to thrive. Maximize your visibility with the Amazio Knowledge Engine. Read on to learn how we can supercharge your business.
                                        </p>
                                        <Link to="/industries/franchises" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                    <img src="images/industies/7.gif" className="w-100" alt="Franchises"/>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/industies/8.gif" className="w-100" alt="Automotive"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Automotive</h4>
                                        <h5>Drive Consumers</h5>
                                        <p>
                                            Buying and maintaining a car is an intensely local experience. As a manufacturer, dealer or service provider, you aim to be front and center with consumers from their first search to the last mile. Maximize your visibility.
                                        </p>
                                        <Link to="/industries/automotive" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5 flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Small Business</h4>
                                        <h5>Local search no matter your size.</h5>
                                        <p>
                                            You don’t have a lot of time; after all, you have a business to run. And yet, you know that you must attract today’s mobile consumers to thrive. With Amazio, it just takes a few minutes a month to update, control, and manage how your business appears online.
                                        </p>
                                        <Link to="/industries/small-business" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/industies/9.gif" className="w-100" alt="Small Business"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Industries