import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function AllServices() {
    

    return (
        <>
            <Elements.InnerBanner  slim="py-0" title="Services"/>
            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-md-12">
                            <div className="services-list-wrapper flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Business Digital Listing & Branding</h4>
                                        <p>
                                            Amazio ensures your business stands out by creating, managing, and optimizing your online listings and branding. Establish trust and visibility across all platforms seamlessly.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>Consistent branding across digital directories</li>
                                            <li>Optimization for local SEO</li>
                                            <li>Cross-platform reputation management</li>
                                        </ul>
                                        <Link to="/business-listing-management-usa" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/business-digital.gif" className="objfit-cover" alt="Branding" />
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/review-reputation.gif" className="w-100 rounded-lg" alt="Reviews"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Reviews, Reputation & Sentiment Analysis</h4>
                                        <p>
                                        Monitor, analyze, and improve your online reputation with real-time feedback and sentiment tracking. Let Amazio help you build trust with your audience.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>AI-powered sentiment analysis</li>
                                            <li>Review aggregation and response tools</li>
                                            <li>Alerts for real-time reputation risks</li>
                                        </ul>
                                        <Link to="/reviews-reputation-sentiment-analysis" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5 flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Integrated Digital Marketing Services</h4>
                                        <p>
                                        At Amazio, we specialize in delivering results-driven digital marketing solutions tailored to your business needs. Our integrated approach ensures every aspect of your online presence works together seamlessly to achieve your goals. Discover our core services below
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>Search Engine Optimization (SEO):
                                            </li>
                                            <li>Social Media Optimization (SMO):</li>
                                            <li>Social Media Marketing (SMM):</li>
                                        </ul>
                                        <Link to="/integrated-digital-marketing" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/integrated-degital.gif" className="w-100 rounded-lg" alt="Reviews"/>
                                    </div>
                                </div>
                            </div>
                            <div className=" services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/social-media-management.gif" className="w-100 rounded-lg" alt="Social Media Management"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4 >Social Media Management</h4>
                                        <p >
                                            Create, schedule, and analyze social media campaigns with ease. Connect with your audience on the platforms they love most.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>Automated posting and scheduling tools</li>
                                            <li>Advanced analytics for engagement tracking</li>
                                            <li>Cross-platform integration</li>
                                        </ul>
                                        <Link to="/social-media-management" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5 flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4 >Branding & Design services</h4>
                                        <p>
                                            Deliver stunning visuals and compelling content that captures your brand’s essence. From photography to digital assets, Amazio has you covered.
                                            </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>High-quality graphic design</li>
                                            <li>Content strategy and execution</li>
                                            <li>Custom photography and imagery</li>
                                        </ul>
                                        <Link to="/brand-design-services" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                         <img src="images/digital-content.gif" className="w-100 rounded-lg" alt="Digital Content"/>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                        <img src="images/competetive-intelligence.gif" className="w-100 rounded-lg" alt="Competitive"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Competitive Intelligence, Insights & Analytics</h4>
                                        <p>
                                        Stay ahead with Amazio’s cutting-edge analytics tools that track and evaluate competitor strategies and industry trends.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>Competitor performance benchmarking</li>
                                            <li>Industry trend forecasting</li>
                                            <li>Actionable insights and reports</li>
                                        </ul>
                                        <Link to="/competitive-intelligence-insights-analytics" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5  flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Web & Mobile Informational Tags</h4>
                                        <p>Enhance your website and app’s discoverability with optimized tags and metadata designed to attract the right audience.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>SEO-friendly metadata creation</li>
                                            <li>Structured data for voice and mobile search</li>
                                            <li>Keyword optimization tools</li>
                                        </ul>
                                        <Link to="/web-mobile-informational-tags" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                    <img src="images/web-mobile-information-tags.gif" className="w-100 rounded-lg" alt="Web"/>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                    <img src="images/web-app-development.gif" className="w-100 rounded-lg" alt="Web & App Development"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Web & App Development with Maintenance</h4>
                                        <p>
                                             Build robust websites and mobile applications with ongoing support and maintenance to ensure peak performance.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>Scalable and secure development</li>
                                            <li>Regular updates and bug fixes</li>
                                            <li>Seamless integration with Amazio’s tools</li>
                                        </ul>
                                        <Link to="/web-app-development-maintenance" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5  flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Voice Search Pro</h4>
                                        <p>
                                        Stay ahead of the curve with optimized voice search capabilities, ensuring your business is easily found through smart devices.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>Conversational keyword targeting</li>
                                            <li>Integration with voice assistants like Alexa and Google</li>
                                            <li>Real-time search optimization</li>
                                        </ul>
                                        <Link to="/voice-search-pro" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                    <img src="images/voice-search.gif" className="w-100 rounded-lg" alt="Voice Search Pro"/>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5">
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                    <img src="images/print-media.gif" className="w-100 rounded-lg" alt="Small Print Media"/>
                                    </div>
                                </div>
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Print Media</h4>
                                        <h5 className="fw-600">Local search no matter your size.</h5>
                                        <p>
                                            Traditional marketing still matters. Amazio’s print media solutions provide high-quality materials to support your offline campaigns.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>Customizable flyers and posters</li>
                                            <li>Premium-quality printing options</li>
                                            <li>Design consistency with your digital branding</li>
                                        </ul>
                                        <Link to="/print-media" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="services-list-wrapper border-top pt-5 mt-5  flex-column-reverse flex-md-row">
                                <div className="services-content">
                                    <div className="new-heading">
                                        <h4>Digital Content Services</h4>
                                        <p>
                                        At Amazio, we know that compelling content drives engagement and conversions. Our Digital Content Services focus on delivering high-quality, relevant content that resonates with your target audience. We bring your brand’s message to life through strategic content development, storytelling, and tailored communication.
                                        </p>
                                        <h5>Features</h5>
                                        <ul className="services-features">
                                            <li>Content Strategy & Development</li>
                                            <li>Social Media Content</li>
                                            <li>Distribution & Promotion</li>
                                        </ul>
                                        <Link to="/digital-content-services" className="new-btn btn-gradiant">Learn More</Link>
                                    </div>
                                </div>
                                <div className="services-thumb">
                                    <div className="ratio ratio-1x1 rounded-10">
                                    <img src="images/services/dcservices.gif" className="w-100 rounded-lg" alt="Voice Search Pro"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AllServices