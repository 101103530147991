import { Link } from "react-router-dom"
import * as Elements from "../../components/Elements";

function KnowledgeBase() {
    

    return (
        <>
            <Elements.InnerBanner  slim="py-0" title="Knowledge Base"/>

            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-start justify-content-center justify-content-sm-between py-3">
                        <div className="col-sm-12 col-md-6">
                            <div className="new-heading mb-4 mb-md-5">
                                <h4>
                                    Wondering what "SSO", "Local SEO", "Geo Data" and "Schema Markup" mean? Our knowledge base has the answers
                                </h4>
                                <div className="mt-4">
                                <Link to="/request-demo" className="new-btn btn-gradiant">Discover More</Link>
                                </div>
                            </div>
                            <div className="row justify-content-end text-center mt-5 pt-sm-5">
                                <div className="col-sm-12 col-lg-offset-6 col-lg-6">
                                    <a href="contact-us" className="ourservices-box minh-auto">
                                        <img src="images/icons/investor.svg" alt="Investors"/>
                                        <h2 className="pb-1">Investors</h2>
                                        <p>Get in touch with our investor relations manager.</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 text-center">
                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <a href="/#" className="ourservices-box minh-auto">
                                        <img src="images/icons/sso.svg" alt="sso"/>
                                        <h2 className="pb-1">SSO</h2>
                                        <p>Amazio support partners with SAML and JWT based dynamic single sign on capabilities. Contact our technical team today to learn more.</p>
                                    </a>
                                    <Link to="/resources" className="ourservices-box minh-auto">
                                        <img src="images/icons/resource.svg" alt="resource"/>
                                        <h2 className="pb-1">Resources</h2>
                                        <p>Watch videos on the basics. Find Courses for more detailed tips and tricks.  </p>
                                    </Link>
                                    <a href="/contact-us" className="ourservices-box minh-auto">
                                        <img src="images/icons/become-partner.svg" alt="become a partner"/>
                                        <h2 className="pb-1">Become A Partner</h2>
                                        <p>Looking for reseller, agency or other partnership, contact us today.</p>
                                    </a>
                                </div>
                                <div className="col-sm-12 col-lg-6 mt-lg-5 pt-lg-5">
                                    <Link to="/contact-us" className="ourservices-box mt-130 minh-auto">
                                        <img src="images/icons/white-label-services.svg" alt="whitelabel"/>
                                        <h2 className="pb-1">Whitelabel Services</h2>
                                        <p>Customize your account with your logo, color scheme, brand name, and terms of use. Note, this white-labeling feature does not apply to all Partner accounts.</p>
                                    </Link>
                                    <Link to="/contact-us" className="ourservices-box minh-auto">
                                        <img src="images/icons/white-label-services.svg" alt="contact us"/>
                                        <h2 className="pb-1">Contact Us</h2>
                                        <p>Contact our support team for any technical, business, marketing or sales related queries.</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default KnowledgeBase