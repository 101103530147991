import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function VoiceSearch() {
	
	return (
		<>
			<Elements.InnerBanner title="Voice Search Optimization"/>
			
			
            <div className="section-padding">
                <div className="container">
                    <div className="row align-items-center g-4">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <div className="service-content-box">                                
                                <div className="new-heading mb-0 text-center">
                                   <h4>Your Voice, Our Innovation - Command the Future.</h4>
                                   <p>Elevate your search experience with Voice Search Pro – where innovation meets convenience.
                                   </p>
                                   <div className="mt-3">
                                       <Link to="/plans-pricing" className="new-btn btn-gradiant" >Get Started Today</Link>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding border-top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s8.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">                                
                                <div className="new-heading mb-0">
									<h4>Are You Ready for the Future of Search? Hire Our Voice Search Pro Solutions</h4>
									<p>Traditional search methods are slowing you down, and your audience is moving towards quicker, more seamless interactions. The question is, are you ready to keep up with them? </p>
									<p>Amazio’s Voice Search Pro is here to revolutionize how you and your customers access information. We specialize in creating voice-enabled search solutions that let your audience ask questions naturally and receive instant, accurate results. Whether it’s through smartphones, smart speakers, or virtual assistants, we ensure your business stays ahead of the voice revolution.
									</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
                                <h4>Explore Our Range of Voice Search Pro Services</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ">         
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Voice-Optimized Content Creation:
                                        </h5>
                                        <p>We craft SEO-friendly content tailored for voice search queries.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Voice Search Integration:
                                        </h5>
                                        <p>Integrate voice search capabilities seamlessly into your website or app.
										</p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Voice Command Development:
                                        </h5>
                                        <p>Build customized voice commands for your business needs.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Smart Speaker Compatibility:
                                        </h5>
                                        <p>Ensure your services are discoverable via Alexa, Siri, and Google Assistant.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i>  Analytics and Optimization:
                                        </h5>
                                        <p>Track voice search performance and refine for optimal results.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s8.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
                                <h4>Our Complete Roadmap to Voice Search Pro</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s8.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">    
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Assessment & Analysis:
                                        </h5>
                                        <p>We evaluate your current digital presence and voice search readiness.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Strategy Development:
                                        </h5>
                                        <p>Create a custom voice search optimization plan tailored to your audience.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Implementation: 
                                        </h5>
                                        <p>Seamlessly integrate voice-enabled solutions into your platforms.
                                        </p>
                                    </li>
                                    <li>                                        
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Testing & Refinement:
                                        </h5>
                                        <p>Ensure flawless execution through rigorous testing and fine-tuning.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Performance Monitoring:
                                        </h5>
                                        <p>Track, report, and optimize for continuous success.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ">                                
                                <div className="new-heading mb-4">
                                <h4>Why Choose Amazio?
								</h4>
                                </div>
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Cutting-Edge Expertise:
                                        </h5>
                                        <p>Our team stays ahead of the curve with the latest in voice search technology.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Custom Solutions: 
                                        </h5>
                                        <p>We tailor our services to meet your unique business goals.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> User-Focused Approach:
                                        </h5>
                                        <p>We prioritize seamless user experiences for your customers.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Results-driven:
                                        </h5>
                                        <p>Our strategies are designed to deliver measurable results and boost engagement.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> End-to-End Support:
                                        </h5>
                                        <p>From planning to post-launch, we’re with you every step of the way.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>                        
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s8.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding pt-0">
                <div className="container">
                    <div className="row">                        
                        <div className="col-md-12">
                            <div className="bottom-service-ctabox">
                                <div className="ps-4">
                                    <h4>Ready to Amplify Your Business with Voice? 
                                    </h4>
                                    <div className="bottom-service-cta mt-3">
                                        <Link to="/contact-us" className="new-btn btn-gradiant" >
										Contact Us Now</Link>
                                    </div>   
                                </div>                        
                                <img src="images/service-cta.jpg" alt="Digital Presence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</>
	)
}

export default VoiceSearch