import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";


function CompetitiveAnalytics() {
	

	return (
		<>
			<Elements.InnerBanner title="Competitive Intelligence, Insights, & Analytics" />
			<div className="section-padding">
				<div className="container">
					<div className="row align-items-center g-4">
						<div className="col-md-10 col-lg-8 mx-auto">
							<div className="service-content-box">
								<div className="new-heading mb-0 text-center">
									<h4>Your Data. Our Intelligence. Your Competitive Edge.
									</h4>
									<p>Gain actionable insights and powerful analytics to outthink, outsmart, and outperform the competition. 
									</p>
									<div className="mt-3">
										<Link to="/contact-us" className="new-btn btn-gradiant" >Get a 30-Mins Free Consultation Call</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="section-padding border-top">
				<div className="container">
					<div className="row ">
						<div className="col-md-6 col-lg-5">
							<div className="service-img-box mb-4 mb-md-0">
								<img src="images/service-imgs/s5.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-0">
									<h4>The Winning Edge in a Competitive World with Amazio’s Insights & Analytics
									</h4>
									<p>In today's hyper-competitive landscape, flying blind is not an option. Competitors are innovating, markets are shifting, and consumer preferences are evolving faster than ever. </p>
									<p>At Amazio, we arm you with cutting-edge <strong>Competitive Intelligence, Insights, and Analytics.</strong> We help you track, decode, and analyze your competitors' moves, providing the clarity you need to make bold, informed decisions. Whether you're launching a new product, entering a new market, or refining your strategy, Amazio gives you the insights to act with confidence and precision.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Discover Our Wide Gamut of Competitive Intelligence, Insights, and Analytics Services</h4>
								<p>Amazio offers a full suite of services designed to sharpen your competitive edge:</p>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Competitor Research & Analysis:
										</h5>
										<p>Deep-dive into competitor performance, pricing models, marketing tactics, and product innovation.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Market Trends & Sentiment Analysis:
										</h5>
										<p>Stay updated with real-time data on emerging trends and shifts in consumer sentiment.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Visual Data & Analytics Dashboards:
										</h5>
										<p>Custom, easy-to-digest dashboards and visual reports for quick, actionable insights.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Performance Benchmarking:
										</h5>
										<p>Measure where you stand against your competitors and identify areas for improvement.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Content Imaging & Visualization:
										</h5>
										<p>High-quality imaging to visualize complex data and insights, making them easy to understand.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s5.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Our Roadmap to Competitive Intelligence, Insights, and Analytics
								</h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s5.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Discovery Phase:
										</h5>
										<p>Understanding your needs, industry, and the key competitors you want to track.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Research & Analysis:
										</h5>
										<p>Our experts collect and analyze data, spotting patterns, trends, and opportunities.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Customized Insights Delivery:
										</h5>
										<p>Receive tailored reports, dashboards, and visual content that turn data into strategic intelligence.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Continuous Optimization:
										</h5>
										<p>As the market evolves, so do our insights. We provide ongoing updates to keep you ahead.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row align-items-center border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ">
								<div className="new-heading mb-4">
									<h4>Why Choose Amazio?
									</h4>
								</div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Tailored Solutions:
										</h5>
										<p>We don't believe in one-size-fits-all. Our competitive insights are customized to your specific goals and industry.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Real-Time Data: 
										</h5>
										<p>Stay current with up-to-date intelligence to make decisions at the speed of the market.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Expert Analysis:
										</h5>
										<p>Our team blends AI-driven analytics with human expertise for the most accurate insights.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s5.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-padding pt-0">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="bottom-service-ctabox">
								<div className="ps-4">
									<h4>Ready to Gain a Competitive Edge? Get Started with Amazio Today
									</h4>
									<div className="bottom-service-cta mt-3">
										<Link to="/contact-us" className="new-btn btn-gradiant" >
										Contact Us
										</Link>
									</div>
								</div>
								<img src="images/service-cta.jpg" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CompetitiveAnalytics