
import * as Elements from "../../../components/Elements";

function Automotive() {
    

    return (
        <>
            {/* <PageTitleSection title="Automotive" subtitle="Drive Consumers"/> */}
            <Elements.InnerBanner  slim="py-0" title="Automotive" subtitle="Drive Consumers" />
            
            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                                As an automotive manufacturer, dealer, or service provider, you want to be in front of your customers from the very beginning of their new car search to their last mile on the road. Amazio understands your unique challenges as a business that relies heavily on local customers and has several products to offer as a managed service for your automotive business.
                            </p>
                            <p className="mt-4 ">
                                Here are some ways Amazio can help you generate more automotive business in less time so you can focus on making your customers happy instead of managing your website and listings.
                            </p>
                            <h5 className="fw-bold">1. Consolidate listings</h5>
                            <p className="">
                                Even though you might only operate in one physical location, your automotive listings are all over the internet. This means it can be difficult to keep track of them to ensure they're accurate and fairly representing your business. Amazio's ability to consolidate listings is a well-rounded managed service that ensures accuracy across your listings, including information such as your address and phone number. This makes it even easier for customers to find you when they're ready to buy a car or get a service.
                            </p>
                            <h5 className="mt-4 fw-bold">2. Create a branded experience</h5>
                            <p className="">
                                Branding your automotive business is essential if you want customers to remember you. Amazio can give you a branded experience on your website so customers recognize your brand throughout the buying process. As they move from getting that new car, to needing service, to finally trading that care in for another one, your branded assets across the web will help keep you front and center on their mind.
                            </p>
                            <h5 className="mt-4 fw-bold">3. Monitor and analyze sales events</h5>
                            <p className="">Every company in the automotive industry has sales events throughout the year. Amazio's managed services include monitoring and analyzing your sales events so you can determine what works best to bring in new business and keep current customers coming back again and again to see you for their vehicle or service needs.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Automotive