import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function KnowledgeTags() {
	

	return (
		<>
			<Elements.InnerBanner title="Web & Mobile Informational Tags" />
			<div className="section-padding">
				<div className="container">
					<div className="row align-items-center g-4">
						<div className="col-md-10 col-lg-8 mx-auto">
							<div className="service-content-box">
								<div className="new-heading mb-0 text-center">
									<h4>Inform, Engage, Connect – The Power of Tagging Done Right
									</h4>
									<p>Make your digital presence smarter and more informative with Amazio's Web & Mobile Informational Tags. 
									</p>
									<div className="mt-3">
										<Link to="/plans-pricing" className="new-btn btn-gradiant" >Get Started with Smarter Tags</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="section-padding border-top">
				<div className="container">
					<div className="row ">
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s6.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-0">
									<h4>Your Digital World, Simplified With Amazio
									</h4>
									<p> Every second, millions of users struggle to find the right information or navigate through digital clutter. This confusion leads to frustration, lower engagement, and lost opportunities for businesses. Without the right informational tags, your content risks being overlooked or misunderstood.
 									</p>
									<p>At Amazio, we bring structure to the chaos. We provide tailored Web & Mobile Informational Tags that guide your users through content with ease and precision. Our tagging solutions ensure that your audience finds what they need, understands what they see, and interacts seamlessly with your digital platforms. Whether on a website or a mobile app, Amazio’s tags make every interaction meaningful.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Our Wide Range of Web & Mobile Informational Tags
								</h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Navigation Tags:
										</h5>
										<p>Help users move easily across sections, pages, or features.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Content Tags: 
										</h5>
										<p>Add context to articles, media, and documents to provide quick insights.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Action Tags:
										</h5>
										<p>Guide users to take specific actions like “Download,” “Sign Up,” or “Learn More.”
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Categorization Tags: 
										</h5>
										<p>Group related content and make it easier to discover.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Accessibility Tags:
										</h5>
										<p>Improve usability for all users, including those who rely on assistive technologies.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s6.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Our Roadmap to Web & Mobile Informational Tags
								</h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s6.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Discovery:
										</h5>
										<p>We identify your content goals and challenges.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Planning: 
										</h5>
										<p>Design a tagging strategy tailored to your needs.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Implementation: 
										</h5>
										<p>Integrate the tags seamlessly into your website or mobile app.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Testing: 
										</h5>
										<p>Ensure usability, accuracy, and efficiency of the tags.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Optimization: 
										</h5>
										<p>Continuously refine the tags based on user behavior and feedback.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Why Choose Amazio?
								</h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> User-Centric Approach:
										</h5>
										<p>We prioritize ease of navigation and clarity for your audience.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Custom Solutions:
										</h5>
										<p>No generic solutions – we tailor tags to fit your unique business needs.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Seamless Integration:
										</h5>
										<p>Our tags are easy to implement on any platform.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Proven Results:
										</h5>
										<p>Our strategies improve engagement, reduce bounce rates, and enhance user satisfaction.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Ongoing Support:
										</h5>
										<p>We stay with you to optimize and enhance your tagging strategy.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s6.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-padding pt-0">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="bottom-service-ctabox">
								<div className="ps-4">
									<h4>Ready to Transform Your User Experience with Amazio Tags?
									</h4>
									<div className="bottom-service-cta mt-3">
										<Link to="/contact-us" className="new-btn btn-gradiant" >
										Contact Us
										</Link>
									</div>
								</div>
								<img src="images/service-cta.jpg" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default KnowledgeTags