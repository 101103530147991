import * as Elements from "../../components/Elements";
function AboutUs() {
    
    return (
        <>
            {/* <PageTitleSection title="About Us"/> */}
            <Elements.InnerBanner slim="true" title="About us" />
            <section className="section-padding" id="abt">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 py-3">                            
                            <div className="new-heading mb-4 mb-md-5 pe-4">
                                <h3>Your reputation. Amazing management.</h3>
                                <p>
                                    We take pride in helping our clients shine online. The digital world comes with a variety of unique challenges, and we work with you to overcome them and build a stronger presence—one that helps you grow your company.
                                </p>
                                <p>
                                    At Amazio, we look at the big picture. It's not just about your website or your Facebook page. Your digital presence extends far beyond that, and our comprehensive approach ensures we're addressing your needs all over the digital landscape.
                                </p>
                                <p>
                                    Don't just exist online. Be amazing. We can help.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 py-3">
                            <img src="images/about-us.gif" alt="" className="w-100"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AboutUs