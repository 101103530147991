import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function BrandDesignServices() {
	

	return (
		<>
			<Elements.InnerBanner title="Branding & Design services" />
			<div className="section-padding">
				<div className="container">
					<div className="row align-items-center g-4">
						<div className="col-md-10 col-lg-8 mx-auto">
							<div className="service-content-box">
								<div className="new-heading mb-0 text-center">
									<h4>Crafting Visual Experiences That Resonate
									</h4>
									<p>At Amazio, we specialize in creating visually captivating designs that bring your brand’s identity to life. Our Design Services focus on delivering creative, impactful visuals that engage your audience and communicate your message effectively. From branding to marketing materials, we help you leave a lasting impression.
									</p>
									<div className="mt-3">
										<Link to="/request-demo" className="new-btn btn-gradiant" >Book a Consultation Today</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="section-padding border-top">
				<div className="container">
					{/* <div className="row align-items-center">
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s4.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-0">
									<h4>Bringing Your Vision to Life in a Digital World
									</h4>
									<p>Low-quality images and uninspired content can leave your audience disengaged and your message lost. You need visuals that don’t just catch the eye but also tell a compelling story.</p>
									<p>At <strong>Amazio</strong> we specialize in creating digital content and imaging services that do just that. We combine creativity, technology, and strategy to provide high-quality visuals and content that connect with your audience. Whether it's eye-catching graphics, professional photography, or dynamic digital assets, we’re here to bring your ideas to life and help your brand stand out.
									</p>
								</div>
							</div>
						</div>
					</div> */}
					<div className="row align-items-center">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
									<h4>Our Services
									</h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Graphic Design:
										</h5>
										<p>Custom visuals for social media, websites, print materials, and more, all designed to reflect your brand’s unique identity.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Brand Identity Design:
										</h5>
										<p>Creating logos, color schemes, and brand guidelines that provide a strong and consistent visual presence across all platforms.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Website & User Interface Design:
										</h5>
										<p>Designing visually appealing and user-friendly websites that offer an optimal user experience, increasing engagement and driving conversions.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Professional Photography:
										</h5>
										<p>High-quality product shots, corporate headshots, event photography, and more, elevating your brand’s visual presence with stunning imagery.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Video Production & Motion Graphics:
										</h5>
										<p>Engaging promotional videos, animations, and tutorials that captivate your audience and effectively communicate your message.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> 3D Visualization & Rendering: 
										</h5>
										<p>Striking 3D visuals and renderings to showcase products, architectural designs, and conceptual ideas with realistic detail.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Custom Illustrations: 
										</h5>
										<p>Unique, bespoke illustrations that capture the essence of your brand’s personality and message.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Print Design: 
										</h5>
										<p>Designs for brochures, flyers, posters, business cards, and more, ensuring your print materials leave a memorable impression.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s4.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Our Approach to Design Creation
								</h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s4.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Consultation & Vision Understanding: 
										</h5>
										<p>We begin by gaining a deep understanding of your brand’s goals, vision, and target audience to ensure the design aligns with your overall strategy.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Creative Concept Development:
										</h5>
										<p>Our design team develops and refines ideas to create visuals that tell your brand’s story in the most captivating and effective way.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Design & Refinement:
										</h5>
										<p>We bring your concepts to life through designs, working closely with you for feedback, revisions, and refinements to ensure the results exceed your expectations.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Final Delivery & Support:
										</h5>
										<p>Once the designs are finalized, we provide high-quality files and ongoing support to ensure everything remains consistent and up to your standards.
										</p>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row align-items-center border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ">
								<div className="new-heading mb-4">
									<h4>Why Choose Amazio for Content and Design Services?
									</h4>
								</div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Creativity & Innovation:
										</h5>
										<p>We push creative boundaries to deliver unique and engaging results that resonate with your audience.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Attention to Detail:
										</h5>
										<p>Every project is executed with meticulous attention to detail, ensuring high levels of precision and quality in every design.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Client-Centric Approach:
										</h5>
										<p>We prioritize collaboration and place your vision at the heart of every project, ensuring complete satisfaction.
										</p>
									</li>
									<li>

										<p>Ready to make an impact with exceptional content or stunning design? Let’s connect and create something incredible together.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s4.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-padding pt-0">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="bottom-service-ctabox">
								<div className="ps-4">
									<h4>Ready to Make an Impact? Let’s Create Visual Magic Together.</h4>
									<div className="bottom-service-cta mt-3">
										<Link to="/contact-us" className="new-btn btn-gradiant" >
										Let’s Talk
										</Link>
									</div>
								</div>
								<img src="images/service-cta.jpg" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BrandDesignServices