import { upperFirst } from "lodash";
import Select from "react-select"
import { Spinner } from 'react-bootstrap';
import { EXPORT, fetchData } from "./Service";
import _ from "lodash"
import { initialFormState, downloadOnboarding } from "./Helper";


export const PageTitleSection = props => {
    return (
        <section className="bg-white py-3">
            <div className="container mt-3">
                <div className="row">
                    <h2 className="fw-bolder">{props.title} <span className="fs-5">{props.subtitle && props.subtitle}</span></h2>
                </div>
            </div>
        </section>
    )
}
export const InnerBanner = props => {
    return (
    <section className="inner-banner position-relative py-4 z-9">
        <div className="container">
            <div className={`row  text-center ${props.slim ? '' : 'py-2'} text-white`}>
                <h1 className="fw-bold mb-0">{props.title}</h1>
                { props.subtitle && <p className="mb-0">{props.subtitle}</p>}
            </div>
        </div>
        <div className="z-n1 position-absolute top-0 start-0 w-100 h-100">
            <img className='img-cover' src="images/new/hero-bg.jpg" alt="" />
        </div>
    </section>
    )
}

export const ListSection = props => {
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">{props.title}</h4>
                    </div>
                </div>
                {props.children}
            </div>
        </section>
    )
}

export const ModalSection = props => {
    return (
        <div className="modal fade" id={props.modal_id} tabIndex="-1" aria-labelledby={props.modal_id} aria-hidden="true" data-bs-backdrop="static">
            <div className={`modal-dialog modal-dialog-scrollable modal-${props.size}`}>
                <div className="modal-content">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title fw-bold">{props.title}</h5>
                        <button type="button" className="btn-close" onClick={ props.form_id && props.setData ? ((e) => initialFormState(props.form_id, props.setData)) : null } data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className={`text-center py-5 ${props.page_loader ? ' d-block' : ' d-none'}`}>
                            <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p className="mt-3">Please wait for response. This may take a while...</p>
                        </div>
                        {!props.page_loader && props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ConfirmationModal = props => {
    return (
        <div className="modal fade" id="confModal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content border-0 rounded-15">
                    <div className="modal-body fs-5 text-dark py-4 fw-medium text-center">
                        {props.msg}
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-secondary rounded-pill minw-100" data-bs-dismiss="modal">No</button>
                        <button type="button" className="btn btn-dark rounded-pill minw-100 ms-2" onClick={props.method}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const SelectAgentLocation = props => {
    return (
        <Select
            placeholder={props.placeholder ? props.placeholder : '-- Select --'}
            name={props.name}
            isClearable={true}
            closeMenuOnSelect={false}
            options={props.location_list}
            isMulti={true}
            id={props.id}
            className={props.className ? props.className : "basic-multi-select react-select"}
            value={props.value}
            onChange={props.setOption}
            required
            isSearchable
        />
    )
}

export const ExportButton = (props) => {
    const exportData = (query, name = '') => {
        fetchData(`${EXPORT}?export=${query}`, 'GET', '', true, false, (res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }, "blob");
    }

    return (
        <button type="button" className="btn btn-dark px-3 text-nowrap me-2 rounded-0" disabled={!props.records} onClick={() => exportData(props.exportType, props.fileName)}>
            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_download" /></svg> Export
        </button>
    )
}

export const Badge = props => {
    return (
        <span className={`badge bg-${props.type} rounded-pill`} onClick={props.func ? props.func : null} role={props.role ?? ''} data-bs-toggle={props.toggle ? props.toggle : null} data-bs-target={props.target ? props.target : null} >{_.replace(upperFirst(props.name), '_', ' ')}</span>
    )
}

export const AddButton = props => {
    return (
        <>
            {props.func ? <button type="button" className="btn btn-dark px-3 text-nowrap me-2 rounded-0" data-bs-toggle="modal" data-bs-target={`#${props.tid}`} onClick={props.func}>
                <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> {props.title ?? 'Add New'}
            </button>
                :
                <button type="button" className="btn btn-dark px-3 text-nowrap me-2 rounded-0" data-bs-toggle="modal" data-bs-target={`#${props.tid}`}>
                    <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> {props.title ?? 'Add New'}
                </button>
            }
        </>
    )
}

export const ViewButton = props => {
    return (
        <button type="button" className="btn btn-sm text-primary mx-1" data-bs-toggle="modal" data-bs-target={`#${props.tid}`} onClick={props.func ?? false}>
            <svg className="icon fs-5" role="img"><use href="#icon_eye" /></svg>
        </button>
    )
}

export const EditButton = props => {
    return (
        <button type="button" className="btn btn-sm text-success mx-1" data-bs-toggle="modal" data-bs-target={`#${props.tid}`} onClick={props.func ?? false}>
            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
        </button>
    )
}

export const DeleteButton = props => {
    return (
        <button type="button" className="btn btn-sm text-danger mx-1" data-bs-toggle="modal" data-bs-target={`#${props.tid}`} onClick={props.func ?? false}>
            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
        </button>
    )
}

export const PermissionButton = props => {
    return (
        <button type="button" className="btn btn-dark px-3 text-nowrap me-2" data-bs-toggle="modal" data-bs-target={`#${props.tid}`} onClick={props.func ?? false}>
            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> {props.title ?? 'Add New'}
        </button>
    )
}

export const LoginButton = props => {
    return (
        <button type="button" className="btn btn-dark px-3 text-nowrap me-2" data-bs-toggle="modal" data-bs-target={`#${props.tid}`} onClick={props.func ?? false}>
            <svg className="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg> {props.title ?? 'Add New'}
        </button>
    )
}

export const TextInput = props => {
    return (
        <div className="row mb-3">
            <label htmlFor={props.name} className={`${props.labelSize ? props.labelSize : "col-sm-3"} col-form-label}`}>{props.title} {props.required && <strong className="text-danger">*</strong>}:</label>
            <div className={props.inputSize ? props.inputSize : "col-sm-9"}>
                <input type={props.inputType ? props.inputType : "text"} placeholder={props.placeholder && props.placeholder} className="form-control" id={props.name} name={props.name} defaultValue={props.value} required={props.required} />
                <div className="invalid-feedback">The {_.lowerCase(props.name)} field is required.</div>
            </div>
        </div>
    )
}

export const CardSection = props => {
    return (
        <div className="col">
            <div className="card border-light shadow-sm border-0 h-100 card-loader">
                <div className="card-header fw-bold">{props.title}</div>
                <div className="card-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export const ButtonSpinner = props => {
    return (
        <>
            {
                props.load ?
                    <Spinner className="spinner-border spinner-border-sm mx-3" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    : props.btnName
            }
        </>
    )
}

export const DisableButton = props => {
    return (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="No Permission">
            <button className={`btn btn-sm text-${props.btnType} mx-2 border-0`} style={{ pointerEvents: 'none'}} type="button" disabled>
                <svg className="icon fs-5 tscale-0p9" role="img"><use href={props.icon} /></svg>
            </button>
        </span>
    )
}
