import * as Elements from "../../../components/Elements";

function Hospitality() {
    

    return (
        <>
            <Elements.InnerBanner  slim="py-0" title="Hospitality" subtitle="Guests, visitors, or strangerss" />

            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                                The hospitality industry moves fast. In fact, 65% of today's same-day bookings are made via smartphone. In addition, businesses within the hospitality industry receive customer reviews across a whole range of websites that can be difficult to manage. With the Amazio Knowledge Engine, you deliver digital knowledge listings are always accurate, actionable, and differentiated.
                            </p>
                            <p className="mt-4">
                                Here's how Amazio can benefit your hospitality business:
                            </p>
                            <h5 className="fw-bold">1. Keep up with responsive technology and digital listings</h5>
                            <p className="">
                                Responsive technology is essential for making sure people can easily find your hotel and book a reservation using their mobile device. Managing digital listings is also important to make sure your information is accurately displayed across all listed sites.
                            </p>
                            <h5 className="mt-4 fw-bold">2. Enjoy third-party integrations</h5>
                            <p className="">
                                Amazio can manage all your third-party integrations to make it even easier to handle everything from booking reservations to taking payments and sending out emails and keeping up with online customer reviews. One platform to manage all your listings and communication? That's amazing! No, that's Amazio.
                            </p>
                            <h5 className="mt-4 fw-bold">3. Increase bookings</h5>
                            <p className="">
                                With an accurate and enhanced web presence, you'll quickly find yourself with more bookings in less time. That means more money for your business without spending extra money on marketing.
                            </p>
                            <h5 className="mt-4 fw-bold">4. Monitor and analyze user engagement</h5>
                            <p className="">
                                Knowing what users are doing when they're on your website is important for determining what keeps them there and what drives them away. Amazio will monitor and analyze the user engagement on your website to help you optimize it for the best possible user experience every time.
                            </p>
                            <h5 className="mt-4 fw-bold">5. Empower and personalize franchises</h5>
                            <p className="">
                                Franchises are an important aspect of your business. Empower them by giving them the opportunity to personalize their listings, whether on their own website or on social media channels. Amazio works with hospitality franchises to give them options that are personal while still adhering to your business's guidelines for publishing content.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hospitality