
import React, { useState, useEffect, useContext } from "react";
import Select from "react-select"
import { createRoot } from 'react-dom/client'
import { toastNotify, validateForm, initialFormState, dateFormat } from "../../components/Helper";
import { fetchData, GET_LOCATION_UNIQUE_URL, GET_REVIEW_LIST, GET_REVIEW_COMMENT, POST_REPLY_COMMENT, POST_EDIT_COMMENT, GET_DELETE_COMMENT, GET_REVIEW_LIST_PDF, GET_REVIEW_COUNT } from "../../components/Service";
import ReviewPieChart from "../../components/ReviewPieChart";
import RatingBackDrops from "../../components/RatingBackDrops"
import * as Elements from "../../components/Elements";
import { Context } from "../../components/Context";
import DataTables, { redrawDataTable, reloadUrlDataTable } from "../../components/Datatables";

function ReviewResponse() {
    
    const [offcanvastitle] = useState("Respond to a review")
    const [tinyloader, setTinyloader] = useState(false);
    const [location, setLocation] = useState();
    const [locationDetail, setLocationDetail] = useState({ location_id: '', publisher_data: '' })
    const [records, setRecords] = useState('0');
    const [reviewRespond, setReviewRespond] = useState('0')
    const [selectedlocation, setSelectedLocation] = useState();
    const [context] = useContext(Context)

    let user_group_id = context.auth.user_group_id;

    const selectLocation = (e) => {
        if (e.length > 10) {
            toastNotify({ error: 'You can select maximum 10 location at a time.' })
            return
        }
        setSelectedLocation(e ?? false)
    }

    const getData = () => {
        let formdata = new FormData(document.getElementById('searchForm'));
        let location_selected = formdata.getAll('locations[]')

        if (location_selected.length > 0 && location_selected[0] !== '') {
            document.querySelectorAll(".load-card").forEach(element => element.classList.add('card-loader'));
            document.querySelectorAll(".disabled-button").forEach(element => element.classList.add('disabled'));
            fetchData(GET_REVIEW_COUNT + '?f_type=' + location_selected, 'GET', '', true, false, (res) => {
                document.querySelectorAll(".load-card").forEach(element => element.classList.remove('card-loader'));
                document.querySelectorAll(".disabled-button").forEach(element => element.classList.remove('disabled'));
                if (res.records) {
                    setRecords(res.records)
                } else {
                    setRecords(0)
                }
            }, false, false, false);
        } else {
            toastNotify({ error: 'Please select atleast one location' })
            setRecords(0)
        }
    }

    const getReviewPDF = (e) => {
        let formdata = new FormData(document.getElementById('searchForm'));
        let location_selected = formdata.getAll('locations[]')
        if (location_selected.length > 0 && location_selected[0] !== '') {
            fetchData(`${GET_REVIEW_LIST_PDF}?f_type=${location_selected}`, 'GET', '', true, false, (res) => {
                var name = 'Review_rating' + new Date().toDateString() + '.pdf';
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
            }, "blob");
        } else {
            toastNotify({ error: 'Please select atleast one location' })
        }
    }

    const addResponse = (reviewId, locationId, publisherData) => {
        setLocationDetail(prevState => ({ ...prevState, location_id: locationId, publisher_data: publisherData }))
        fetchData(`${GET_REVIEW_COMMENT}/${reviewId}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setReviewRespond(res.records);
            }
        });
    }

    const modifyComment = (e, type) => {
        var review_id = document.getElementById('review_id').value
        var comment_id = e.target.id;
        if (document.getElementById('comment')) { document.getElementById('comment').required = false; }

        if (type === 'replyComment') {
            var r_text = document.querySelector(`[data-reply_comment='${comment_id}']`)
            r_text.required = true;
            var r_edit_comment = r_text.value
            var r_formData = { review_id: review_id, comment_id: comment_id, comment: r_edit_comment }
            if (validateForm(e)) {
                r_text.required = false;
                setTinyloader(true);
                fetchData(POST_REPLY_COMMENT, 'POST', r_formData, true, false, (res) => {
                    setTinyloader(false);
                    if (res.success) {
                    }
                    document.querySelector('#updateResponse [data-bs-dismiss="modal"]').click()
                });
            }
        }

        if (type === 'updateComment') {
            var u_text = document.querySelector(`[data-edit_comment='${comment_id}']`)
            u_text.required = true;
            var edit_comment = u_text.value
            var u_formData = { review_id: review_id, comment_id: comment_id, edit_comment: edit_comment }
            if (validateForm(e)) {
                u_text.required = false;
                setTinyloader(true);
                fetchData(POST_EDIT_COMMENT, 'POST', u_formData, true, false, (res) => {
                    setTinyloader(false);
                    if (res.success) {
                    }
                    document.querySelector('#updateResponse [data-bs-dismiss="modal"]').click()
                });
            }
        }

        if (type === 'deleteComment') {
            setTinyloader(true);
            fetchData(`${GET_DELETE_COMMENT}/${review_id}/${comment_id}`, 'GET', '', true, false, (res) => {
                setTinyloader(false);
                if (res.success) {
                }
                document.querySelector('#updateResponse [data-bs-dismiss="modal"]').click()
            });
        }

        if (type === 'addComment') {
            var commentValidation = document.getElementById('comment')
            commentValidation.required = true;
            var comment = document.getElementById('comment').value
            var formData = { review_id: review_id, comment: comment }
            if (validateForm(e)) {
                commentValidation.required = false;
                setTinyloader(true);
                fetchData(POST_REPLY_COMMENT, 'POST', formData, true, false, (res) => {
                    setTinyloader(false);
                    if (res.success) {
                    }
                    document.querySelector('#updateResponse [data-bs-dismiss="modal"]').click()
                });
            }
        }
    }

    const [dt] = useState({
        dt_url: GET_REVIEW_LIST,
        dt_name: 'reviewResponseList',
        dt_export: false,
        dt_column: [
            { data: 'date', name: 'reviews.created_at', title: 'Date' },
            { data: 'location_name', name: 'locations.location_name', title: 'Location' },
            { data: 'rating', name: 'reviews.rating', title: 'Rating' },
            { data: 'review_date', name: 'reviews.review_datetime', title: 'Review Date' },
            { data: 'review', name: 'reviews.review', title: 'Review', sortable: false, searchable: false, orderable: false },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{dateFormat(records.date)}</>)
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<><span className="fw-bold" style={{ color: "#3299CC" }}>{records.location_name}</span><br /> {records.address} <br /> {records.city} {records.state} {records.zip_code}, {records.country_code}</>)
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<div className="star-rating mx-3"><span style={{ width: records.review && records.review.publisherId !== 'FACEBOOK' ? Number(records.rating * 20) + '%' : Number(5 * 20) + '%' }}></span></div>)
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{dateFormat(records.review_date)}</>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{records.review && records.review.url ? <a className="fw-bold" href={records.review.url} target="_blank" rel="noreferrer">{records.review && records.review.publisherId}</a> : records.review && records.review.publisherId}<br /> <span className="fw-bold">{records.review && records.review.authorName}</span> <br /> {records.review && records.review.content}</>)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                    {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                        context.auth.permission && context.auth.permission.map((item, key) => {
                            return(
                                <div key={key}>
                                    {item.permission === "Review Respond" &&
                                        <>
                                            {item.add === "yes" ? 
                                                <>
                                                    {records.review && ["GOOGLEMYBUSINESS", "FACEBOOK"].includes(records.review.publisherId) ? <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#updateResponse" onClick={() => addResponse(records.review_id, records.location_id, records.review.publisherId)}>Respond</button> : ''}
                                                </>
                                            : 
                                                <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="No permission">
                                                    <button className="btn btn-primary" style={{ pointerEvents: "none"}} type="button" disabled>Respond</button>
                                                </span>
                                            }
                                        </>
                                    }
                                </div>
                            )
                        })
                    : 
                        <>
                            {records.review && ["GOOGLEMYBUSINESS", "FACEBOOK"].includes(records.review.publisherId) ? <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#updateResponse" onClick={() => addResponse(records.review_id, records.location_id, records.review.publisherId)}>Respond</button> : ''}
                        </>
                    }
                    </>)
                }
            }
        ]
    });

    const loadList = () => {
        let formdata = new FormData(document.getElementById('searchForm'));
        let location_selected = formdata.getAll('locations[]')

        reloadUrlDataTable(dt, `${GET_REVIEW_LIST}?f_type=${location_selected}`);
    }

    useEffect(() => {
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                // setLocation(res.records)

                const amazioID = res.records.filter(item => item.value === "1196301"); 
                setLocation(amazioID)
                if (amazioID.length > 0) {
                    setSelectedLocation(amazioID); 
                }
                
            }
        });
    }, []);

    useEffect(() => {
        if (selectedlocation && selectedlocation.length > 0) {
            getData();  
            loadList(); 
        }
    }, [selectedlocation]);


    useEffect(() => {
        redrawDataTable(dt)

        document.getElementById('updateResponse').addEventListener('hidden.bs.modal', function (e) {
            setReviewRespond('0')
            initialFormState("addNewComment")
        });
    }, [dt])


    return (
        <><section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 m-2 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">Review & Response</h4>
                    </div>
                </div>
                <div className="col-sm-12 mb-2">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                        <form className="col" id="searchForm">
                            <Select
                                placeholder={'-- Select Location --'}
                                value={location}
                                name="locations[]"
                                isClearable={true}
                                closeMenuOnSelect={false}
                                options={location}
                                isMulti={true}
                                className="basic-multi-select react-select required"
                                id="location_id"
                                onChange={(e) => selectLocation(e)}
                                required
                                isSearchable
                            />
                        </form>
                        <div className="col">
                            <button className="btn btn-primary disabled-button ms-2" type="button" onClick={(e) => { getData(e); loadList(e) }}>Submit</button>
                        </div>
                        <div className="col text-end">
                            <button type="button" className="btn btn-primary disabled-button ms-2" onClick={(e) => getReviewPDF(e)}>Generate PDF Report</button>
                        </div>
                    </div>
                </div>
                <div className="row g-4 mt-2">
                    <div className="col">
                        <div className="card border-light shadow-sm border-0 h-100 load-card">
                            <div className="card-header fw-bold">Review</div>
                            <div className="card-body text-center">
                                Number of total Reviews: <h2 className="fw-bold">{records && records.total_review ? records.total_review : 0}</h2><br />
                                Average Overall Rating: <h2 className="fw-bold">{records && records.total_average_rating ? records.total_average_rating : 0}</h2><br />
                                <div className="star-rating mx-3"><span id="" style={{ width: records && records.total_average_rating ? Number(records.total_average_rating * 20) + '%' : Number(0 * 20) + '%' }}></span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-light shadow-sm border-0 h-100 load-card">
                            <div className="card-header fw-bold">Rating Backdrops</div>
                            <div className="card-body">
                                <RatingBackDrops data={records && records.rating_backdrop ? records.rating_backdrop : 0} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-light shadow-sm border-0 h-100 load-card">
                            <div className="card-header fw-bold">Listing Device Usage by Weekday</div>
                            <div className="card-body">
                                <ReviewPieChart data={records && records.chart_data ? records.chart_data : 0} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 mb-2">
                    <div className="row g-2 g-sm-3 g-lg-4 mt-2">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body table-spinner active">
                                    <DataTables dt_name="reviewResponseList" dataPageLength="15" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            {/* Modal Section  */}
            <Elements.ModalSection title={offcanvastitle} modal_id="updateResponse" >
                <form className="needs-validation" id="addNewComment" method="post" noValidate>
                    <input type="text" className="d-none" id="review_id" name="review_id" defaultValue={reviewRespond.review_id ?? ''} />
                    <table className="table-common">
                        <tbody>
                            <tr><td className="text-primary">{reviewRespond.publisher_id ?? ''}</td></tr>
                            <tr><td>{reviewRespond.author_name ?? ''}</td></tr>
                            <tr><td>{reviewRespond.review_date ?? ''}</td></tr>
                            <tr><td><div className="star-rating mx-0"><span style={{ width: Number(reviewRespond.review_date * 20) + '%' }}></span></div></td></tr>
                            {reviewRespond.content && <tr><td>{reviewRespond.content ?? ''}</td></tr>}
                            <tr><td>
                                {reviewRespond.content && <hr />}
                                {
                                    reviewRespond && reviewRespond.down_comment && reviewRespond.down_comment.length > 0 ?
                                        <div style={{ maxHeight: "350px", overflow: "scroll", padding: "10px" }}>

                                            {reviewRespond.down_comment.map((ele, num) => {
                                                return (
                                                    <div key={num}>
                                                        <div>
                                                            {ele.content}<br />
                                                            {ele.author_name}
                                                            <div className="cursor-pointer"> {user_group_id === 1 && reviewRespond.publisher_id === 'FACEBOOK' && <span className="text-primary " data-bs-toggle="collapse" data-bs-target={`#reply${ele.id}`} aria-expanded="false" aria-controls={`reply${ele.id}`}>Reply | </span>} {user_group_id === 1 && <><span className="text-primary" data-bs-toggle="collapse" data-bs-target={`#edit${ele.id}`} aria-expanded="false" aria-controls={`edit${ele.id}`} >Edit </span>| <span className="text-primary" id={ele.id} onClick={(e) => modifyComment(e, 'deleteComment')}>Delete </span>|</>} {ele.publisher_date}</div>
                                                            <div id={`reply${ele.id}`} className="accordion-collapse collapse">
                                                                <textarea className="form-control mt-2" name="reply_comment" data-reply_comment={ele.id} />
                                                                <button type="button" className="btn btn-primary mt-2" name="reply_comment" id={ele.id} onClick={(e) => modifyComment(e, 'replyComment')}>Reply</button>
                                                                <button type="button" className="btn btn-secondary mt-2 ms-2" data-bs-toggle="collapse" data-bs-target={`#reply${ele.id}`} aria-expanded="false">Cancel</button>
                                                            </div>
                                                            <div id={`edit${ele.id}`} className="accordion-collapse collapse">
                                                                <textarea className="form-control mt-2" name="edit_comment" data-edit_comment={ele.id} defaultValue={ele.content} />
                                                                <button type="button" className="btn btn-primary mt-2" id={ele.id} onClick={(e) => modifyComment(e, 'updateComment')} >Edit</button>
                                                                <button type="button" className="btn btn-secondary mt-2 ms-2" data-bs-toggle="collapse" data-bs-target={`#edit${ele.id}`} aria-expanded="false">Cancel</button>
                                                            </div>
                                                            {reviewRespond.child_comment ?
                                                                reviewRespond.child_comment.map((cmnt, num1) => {
                                                                    return (
                                                                        <>
                                                                            <div key={num1}> {ele.id === cmnt.parent_id ? <div className="m-2 p-3 border-start border-3"><h6 className="text-secondary">{cmnt.author_name}</h6> <h6 className="text-secondary">{cmnt.email}</h6>
                                                                                {cmnt.content}<br /><span className="text-secondary">{cmnt.publisher_date}</span></div> : ''} </div>
                                                                        </>
                                                                    )
                                                                }) : ''
                                                            }
                                                        </div>
                                                        <hr />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        ''
                                }
                                {(user_group_id === 1) && (((locationDetail.location_id === "1196301") && (locationDetail.publisher_data === "GOOGLEMYBUSINESS") && (reviewRespond && reviewRespond.down_comment && reviewRespond.down_comment.length === 0)) || (locationDetail.location_id === "1196301" && locationDetail.publisher_data === "FACEBOOK")) ?
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 mb-3">
                                                <textarea className="form-control" id="comment" name="comment" rows="4" placeholder="Write your comment here"></textarea>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-12 text-end">
                                                <button type="button" className="btn btn-dark px-4 ms-2" data-bs-close="add" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                                <button type="button" id="submit" className="btn btn-primary px-4 ms-2" onClick={(e) => modifyComment(e, 'addComment')} disabled={tinyloader}>
                                                    {
                                                        !tinyloader ? 'Submit'
                                                            :
                                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    ''}

                                {user_group_id === 3 &&
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12 mb-3">
                                                <textarea className="form-control" id="comment" name="comment" rows="4" placeholder="Write your comment here"></textarea>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-12 text-end">
                                                <button type="button" className="btn btn-dark px-4 ms-2" data-bs-close="add" data-bs-dismiss="modal" aria-label="Close">Close</button>
                                                <button type="button" id="submit" className="btn btn-primary px-4 ms-2" onClick={(e) => modifyComment(e, 'addComment')} disabled={tinyloader}>
                                                    {
                                                        !tinyloader ? 'Submit'
                                                            :
                                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </td></tr>
                        </tbody>
                    </table>
                </form>
            </Elements.ModalSection>
        </>
    )
}

export default ReviewResponse
