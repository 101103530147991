import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function Pricing() {


	return (
		<>
			<Elements.InnerBanner title="Reviews, Reputation & Sentiment Analysis" />

			<div className="section-padding">
				<div className="container">
					<div className="row align-items-center g-4">
						<div className="col-md-10 col-lg-8 mx-auto">
							<div className="service-content-box">
								<div className="new-heading mb-0 text-center">
									<h4>Your Brand’s Reputation, Analyzed and Amplified
									</h4>
									<p> Turn feedback into your brand's superpower! We analyze reviews, track sentiment, and manage your reputation to keep your business thriving.
									</p>
									<div className="mt-3">
										<Link to="/request-demo" className="new-btn btn-gradiant" >Let’s Get Started</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="section-padding border-top">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-lg-5">
							<div className="service-img-box mb-4 mb-md-0">
								<img src="images/service-imgs/s3.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-0">
									<h4>Understanding the Power of Your Brand's Voice with Our Reviews, Reputation, & Sentiment Analysis
									</h4>
									<p>Brands are not just built on products or services they are shaped by what customers say, how they feel, and the overall sentiment in the market. The challenge lies in managing and understanding all this feedback in a way that drives real change.</p>
									<p>This is where <strong>Amazio</strong> comes in. We specialize in Reviews, Reputation, and Sentiment Analysis, helping you gather and interpret customer feedback. Our platform provides you with the insights needed to manage your online presence, track customer opinions, and protect your brand’s reputation  all in real time.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row  align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Types of Reviews, Reputation, & Sentiment Analysis </h4>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Customer Reviews Analysis:
										</h5>
										<p>We track and analyze customer feedback from platforms like Google, Yelp, Amazon, and more.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Reputation Management:
										</h5>
										<p>Keep your brand's reputation pristine by actively monitoring online discussions and reviews.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Sentiment Analysis:
										</h5>
										<p>Gain a deeper understanding of the overall sentiment around your brand with advanced AI-powered sentiment analysis.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i>Competitor Review Tracking:
										</h5>
										<p>Not only do we track your reviews, but we also keep an eye on your competitors.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Social Social Media Sentiment Monitoring:
										</h5>
										<p>Understand how your brand is being discussed across social media platforms.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s3.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
								<h4>Our Roadmap to Reviews, Reputation, & Sentiment Analysis
								</h4>
								<p>At <strong>Amazio</strong>, we follow a step-by-step approach to help you maximize the value of your customer feedback. </p>
                            </div>
                        </div>
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0  order-n1">
							<div className="service-img-box">
								<img src="images/service-imgs/s3.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Data Collection:
										</h5>
										<p>We gather reviews and feedback from multiple sources, including online review platforms, social media, and surveys.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Sentiment and Reputation Analysis:
										</h5>
										<p>Our AI technology analyzes and categorizes sentiment, pinpointing areas where your brand excels.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Actionable Insights:
										</h5>
										<p>We provide in-depth reports and actionable insights that enable your team to improve customer service and protect your brand’s reputation.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Reputation Management & Alerts:
										</h5>
										<p>Get real-time alerts whenever a review or mention of your brand has a negative impact.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Continuous Monitoring:
										</h5>
										<p>Our system works around the clock, ensuring that you are always in the know about how customers feel about your brand.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ">
								<div className="new-heading mb-4">
									<h4>What Makes Amazio an Ideal Partner for Reviews, Reputation, & Sentiment Analysis?
									</h4>
								</div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Comprehensive Coverage:
										</h5>
										<p>We track reviews and sentiment from across multiple platforms, including websites, social media, and third-party review sites.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Real-Time Alerts:
										</h5>
										<p>Stay informed with real-time notifications on both positive and negative sentiments.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> AI-Powered Analysis:
										</h5>
										<p>Leverage advanced AI algorithms for deep sentiment analysis, allowing you to make data-driven decisions.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s3.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-padding pt-0">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="bottom-service-ctabox">
								<div className="ps-4">
									<h4>Start Protecting Your Brand’s Reputation Today </h4>
									{/* <p className="mb-0">Boost your brand’s visibility with expert strategies that elevate your social media presence.
                                    </p> */}
									<div className="bottom-service-cta mt-3">
										<Link to="/plans-pricing" className="new-btn btn-gradiant" >
											Sign Up for a Free Trial
										</Link>
									</div>
								</div>
								<img src="images/service-cta.jpg" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Pricing