import { Link } from "react-router-dom";
import { BUSINESS_SCAN, fetchData, GET_URL } from "../../components/Service";
import { useContext, useEffect, useState } from "react";
import { initialFormState, state, validateForm } from "../../components/Helper";
import { ModalSection } from '../../components/Elements';
import { Context } from "../../components/Context";
import ClientTestimonials from "./Testimonials";

function ScanYourBusiness() {

    const [context] = useContext(Context) 
    const [tinyloader, setTinyloader] = useState(false);
    const [pageLoader, setPageLoader] = useState(true);
    const [businessUrl, setBusinessUrl] = useState();    
    const [newURL, setNewURL] = useState();
    const [existLocation, setExistLocation] = useState(false)


    const scanFAQs = [
        {
          question: "Is the scan really free?",
          answer: "Yes, the scan is completely free. We offer this to help businesses improve their online presence."
        },
        {
          question: "How long does the scan take?",
          answer: "The scan is completed in just a few seconds, and you’ll receive a detailed report almost immediately."
        },
        {
          question: "Do I need to make changes to my listings?",
          answer: "If there are any inconsistencies or outdated information, we’ll provide recommendations on how to fix them."
        },
        {
          question: "What if my business information is incorrect?",
          answer: "We’ll help you identify any errors, and we can guide you through the process of updating your listings."
        }
      ];
      


    const [faq] = useState(scanFAQs);


    const scanNow = (e) => {
            let formData = new FormData(document.getElementById('scanForm'))
            if (validateForm(e, 'scanForm')) {
                setTinyloader(true)
                setPageLoader(true);
                fetchData(BUSINESS_SCAN, 'POST', formData, true, true, (res) => {
                    setTinyloader(false)
                    if (res.success) {
                        let values = `?name=${formData.get('name')}&address=${formData.get('address')}&phone=${formData.get('phone')}&city=${formData.get('city')}&zip=${formData.get('zip')}`;
                        var mainUrl = businessUrl;
    
                        setNewURL(mainUrl + values)
    
                        document.getElementById('showScanNowModal').click()
                        initialFormState('scanForm')
                    }else{
                        setPageLoader(false)
                        setExistLocation(true)
                        document.getElementById('showScanNowModal').click()
                        initialFormState('scanForm')
                    }
                })
            }
        }

        useEffect(() => {
    
            fetchData(GET_URL + '?type=scanBusiness', 'GET', '', true, false, (res) => {
                if (res.records) {
                    setBusinessUrl(res.records)
                }
            }, (err) => { })
    
        }, [])

    return (
        <>
            <section className="pt-5 position-relative z-1">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-7 text-center text-md-start mb-4 mb-md-5 pe-md-5">
                            <h1 className="fw-bold">Is Your Business Listed Correctly? Get Your Free Scan Now!</h1>
                            <p>Check how your business appears across top online directories. Ensure your customers find you easily with accurate and consistent listings.</p>
                            <div className="d-flex flex-wrap gap-2 justify-content-center justify-content-md-start">
                                <button className="new-btn btn-gradiant" data-bs-toggle="modal" data-bs-target="#scan_now">Scan My Business Listing</button>
                                <Link to="/plans-pricing" className="new-btn btn-cyan-outline" >Get Started for Free</Link>
                            </div>
                        </div>
                        <div className="col-10 col-md-5 pt-4 px-md-5">
                            <img src="images/scan-banner.png" className="w-100" alt="contact" />
                        </div>
                    </div>
                </div>
                <div className='z-n1 position-absolute top-0 start-0 w-100 h-100 bg-gradiant2'></div>
            </section>

            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 mx-auto">
                            <div className="new-heading mb-4 mb-md-5 text-md-center">                                
                                <h4>Why is a Consistent Business Listing Important? </h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box">
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Improved Local Search Rankings: 
                                        </h5>
                                        <p>Accurate business listings help you rank higher in local search results.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Increased Trust & Credibility:
                                        </h5>
                                        <p>Customers are more likely to trust your business when they see consistent information across directories.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Higher Conversion Rates: 
                                        </h5>
                                        <p>Correct and detailed listings make it easier for potential customers to contact you, increasing leads and conversions.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Better User Experience:
                                        </h5>
                                        <p>Ensure that customers can easily find your hours, phone number, location, and other key details.
                                        </p>
                                    </li>
                                </ul>
                                <div className="d-flex flex-wrap gap-2 mt-4 ps-md-4 ms-3">
                                    <button className="new-btn btn-gradiant" data-bs-toggle="modal" data-bs-target="#scan_now">Scan Your Listing Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/scan-busness-1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-padding bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 mx-auto">                                                      
                            <div className="new-heading text-md-center mb-4 mb-md-5">
                                <h4>How the Free Listing Scan Works</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/scan-busness-2.png" className="w-100 shadow rounded p-1" alt="Digital Presence"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">   
                                <ul className="service-content-list">
                                    <li>
                                        <h5>
                                        <i className="counter-icon">1</i>
                                            Enter Your Business Information:
                                        </h5>
                                        <p>Fill out the form with your business name, website, or location.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                        <i className="counter-icon">2</i>
                                        We Scan Your Business Listings:
                                        </h5>
                                        <p>We’ll search through top online directories (Google My Business, Yelp, Yellow Pages, and more) to check the accuracy of your listing.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                        <i className="counter-icon">3</i>
                                         Get a Comprehensive Report:
                                        </h5>
                                        <p>Within seconds, you’ll receive a detailed report showing how your business appears online and where you need to make updates.
                                        </p>
                                    </li>
                                    <li>                                        
                                        <h5>
                                        <i className="counter-icon">4</i>
                                        Take Action:
                                        </h5>
                                        <p>We’ll also provide recommendations on how to correct any inconsistencies or missing information in your listings.
                                        </p>
                                    </li>
                                </ul>
                                <div className="d-flex flex-wrap gap-2 mt-4 ps-4 ms-3">
                                    <button className="new-btn btn-gradiant" data-bs-toggle="modal" data-bs-target="#scan_now">Get Your Free Listing Scan Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-padding">
                <div className="container">
                    <div className="row  align-items-center">
                        <div className="col-lg-8 mx-auto">
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
                                <h4>What Does the Scan Include?
                                </h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box">
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Listing Accuracy: 
                                        </h5>
                                        <p>Check if your business name, address, and phone number (NAP) are consistent across multiple platforms.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Business Hours:
                                        </h5>
                                        <p>Ensure your business hours are correct and up-to-date.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Customer Reviews: 
                                        </h5>
                                        <p>See how your business is rated and what customers are saying about you.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Map Listings:
                                        </h5>
                                        <p>Ensure your location is accurately marked on map services like Google Maps.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Social Media Profiles:
                                        </h5>
                                        <p>Confirm that your social media accounts are linked to your business listing.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> SEO Impact: 
                                        </h5>
                                        <p>Learn how your listings affect your visibility in search engine results.
                                        </p>
                                    </li>
                                </ul>
                                <div className="d-flex flex-wrap gap-2 mt-4 ps-4 ms-3">
                                    <button className="new-btn btn-gradiant" data-bs-toggle="modal" data-bs-target="#scan_now">Start Your Free Scan Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/scan-busness-3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-padding bg-gray">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading mb-4 text-md-center">
                                <h4>How Our Clients Have Benefited</h4>
                            </div>                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ClientTestimonials/>
                            <div className="mt-4 text-center">
                                <a target="_blank" href="https://blog.amazio.com/case-study/" className="new-btn btn-gradiant">Read Our Success Stories</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section-padding bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading mb-4 text-md-center">
                                <h4>Frequently Asked Questions</h4>
                            </div>                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto">                            
                            <div className="accordion" id="accordionExample">
                                {faq && faq.map((ele, i) => {
                                    return (
                                        <div className="accordion-item" key={i}>
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className={`accordion-button ${i !== 0 ? 'collapsed' : ''} fw-bold`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapseThree${i}`} aria-expanded={`${i !== 0 ? 'false' : 'true'}`} aria-controls="collapseThree">
                                                    {ele.question}
                                                </button>
                                            </h2>
                                            <div id={`collapseThree${i}`} className={`accordion-collapse collapse ${i !== 0 ? '' : 'show'}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        {ele.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-md-10 mx-auto text-center mt-4">   
                            <h5>Still have questions? <Link to="/contact-us" className="btn btn-link">Contact Us</Link></h5>
                        </div>
                    </div>
                </div>
            </section>

            {/* modal start  */}
            <div className="modal fade" id="scan_now" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header " style={{ background: '#001e3c'}}>
                            <h5 className="modal-title text-white fw-bold " id="exampleModalLabel">Scan Your Business</h5>
                            <button type="button" className="btn-close bg-white opacity-75 rounded-5" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body py-4 px-4">
                            <div className="row">
                                {/* <div className="col-sm-12">
                                    <h5 className="fw-bold pb-4">Find out how your business appears online</h5>
                                </div> */}
                                <div className="col-12">
                                    <h5 className="mb-3">Find out how your business appears online</h5>  
                                </div>
                                <div className="col-12">
                                    <form className="row needs-validation" action="#" id="scanForm" method="post" autoComplete="off" noValidate>
                                            <div className="col-md-6 mb-3">
                                                <input type="text" name="name" className="form-control" id="name" defaultValue="" placeholder="Business Name" required />
                                                <div className="invalid-feedback">The name field is required</div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <input type="text" name="address" minLength={5} className="form-control" id="address" defaultValue="" placeholder="Address" required />
                                                <div className="invalid-feedback">The address is required</div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <input type="tel" name="phone" id="phone" className="form-control" defaultValue="" placeholder="Phone No." required />
                                                <div className="invalid-feedback">The phone field is required</div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <input type="text" name="city" className="form-control" id="city" defaultValue="" placeholder="City" required />
                                                <div className="invalid-feedback">The city field is required</div>
                                            </div>
                                        
                                        <div className="col-md-6 mb-3">
                                            <select className="form-select scan-select px-2" name="state" id="state" defaultValue="" placeholder="State" required>
                                                <option value="">--Select--</option>
                                                { 
                                                    Object.keys(state).map((ele, num) => <><option value={ele} key={num}>{state[ele]}</option></>)
                                                }
                                            </select>
                                            <div className="invalid-feedback">The state field is required</div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <input type="text" name="zip" id="zip" minLength={4} maxLength={9} className="form-control" placeholder="Zip Code" required />
                                            <div className="invalid-feedback">The zip code field is required</div>
                                        </div>
                                        <div className="col-md-12 col-md-12 mt-3">
                                            <button type="button" id="scanNow" className="new-btn btn-gradiant rounded-2 w-100 " onClick={(e) => scanNow(e)} disabled={tinyloader}>
                                                {
                                                    !tinyloader ? 'Scan Now'
                                                        :
                                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                }
                                            </button>
                                            <button type="button" id="showScanNowModal" className="new-btn btn-gradiant rounded-2 w-100 d-none" data-bs-toggle="modal" data-bs-target="#scanModal"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <ModalSection title="Scan Listing" modal_id="scanModal" size="xl">
                            <div className={`text-center py-5 ${pageLoader ? ' d-block' : ' d-none'}`}>
                                <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <p className="mt-3">Please wait for response. This may take a while...</p>
                            </div>
                            {!existLocation ?
                                <iframe src={newURL} id="newUrl" className={`border-0 w-100 mb-0 ${pageLoader ? ' d-none' : ' d-block'}`} style={{ height: 'calc(100vh - 3.5rem - 72px)', width: `60rem` }} onLoad={() => setPageLoader(false)} title="Report"></iframe>
                                :
                                !pageLoader &&
                                <div className='col-md-12'>
                                    <div className='row mb-3 border-bottom p-3'>
                                        <img src={'images/logo.svg'} alt="Amazio-Logo" width="170" height="31" />
                                    </div>
                                    <div className='row d-flex justify-content-center p-4'>
                                        <div className='col-md-8 text-center'>
                                            <h3 className='fw-bold text-center my-4'>
                                                This location is already registered with Amazio, Please see details about it on Amazio Dashboard.
                                            </h3>
                                            {!context?.auth &&
                                                <Link to={'/log-in'} className='btn btn-primary mt-3' onClick={() => document.querySelector('#scanModal [data-bs-dismiss="close"]').click()}>Login</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </ModalSection>
        </>
    )
}

export default ScanYourBusiness