import React, { useState, useContext } from 'react';
import { fetchData, GET_AUTH_USER_INFO, GET_STRIPE_SECRET, UPGRADE_PLAN } from '../../components/Service';
import { toastNotify } from '../../components/Helper';
import { Context } from "../../components/Context";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';

function UpgradePlan({ billingDetails }) {
    const stripe = useStripe();
    const elements = useElements();
    const [tinyloader, setTinyloader] = useState(false);
    const navigate = useNavigate();

    const [context, setContext] = useContext(Context)
    let get_pricing = localStorage.getItem('pricing') ? localStorage.getItem('pricing').split('||') : [1, 3, 49];
    const [formfield, setFormfield] = useState({
        total_amount: (180 * Number(get_pricing[2])).toFixed(2),
        discount: "0.00",
        payable_amount: (180 * Number(get_pricing[2])).toFixed(2),
        plan_type: get_pricing[0],
        simple_services: get_pricing[1],
        coupon_id: '',
        payment_terms: '6'
    });


    const planCalculate = (e) => {
        setFormfield(prevState => ({
            ...prevState,
            total_amount: (e.target.value * Number(get_pricing[2])).toFixed(2),
            discount: "0.00",
            payable_amount: (e.target.value * Number(get_pricing[2])).toFixed(2),
            coupon_id: ''
        }));
        var element = document.getElementById("error_section");
        element.classList.add("d-none");

        let paymentTerms = '';
        if (e.target.value === '30') {
            paymentTerms = '1';
        } else if (e.target.value === '180') {
            paymentTerms = '6';
        } else if (e.target.value === '360') {
            paymentTerms = '12';
        }
        setFormfield(prevState => ({
            ...prevState,
            payment_terms: paymentTerms
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        if (!stripe || !elements) return;

        setTinyloader(true);
        try {
            
            let request = {
                'method': 'post',
                'body': formData
            };

            var confirmed = document.getElementById("accepted").checked;
            if (!confirmed) {
                let msg = "Please indicate that you have read and agree to the Terms and Conditions";
                toastNotify({ 'error': msg });
                setTinyloader(false);
                return;
            }

            var form = document.getElementById('upgrade_plan_form');

            // Fetch Stripe secret from your backend
            const stripeSecretResponse = await fetch(
                `${process.env.REACT_APP_ENDPOINT}${GET_STRIPE_SECRET}`,
                request
            ).then(response => response.json());

            if (!stripeSecretResponse || !stripeSecretResponse.stripeClientSecret) {
                toastNotify({ 'error': 'Failed to fetch Stripe secret.' });
                throw new Error('Failed to fetch Stripe secret.');
            }

            // Submit the elements form
            const elementsSubmitResponse = await elements.submit();

            if (elementsSubmitResponse.error) {
                // toastNotify({ 'error': elementsSubmitResponse.error.message });
                throw new Error(elementsSubmitResponse.error.message);
            }

            // Confirm the setup intent with Stripe
            const { setupIntent, error: confirmSetupError } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: 'https://your-app.com/return', // Not required for SPA, but good practice
                },
                redirect: 'if_required',
                clientSecret: stripeSecretResponse.stripeClientSecret,
            });

            
            if (confirmSetupError) {
                toastNotify({ 'error': confirmSetupError.message });
                throw new Error(confirmSetupError.message);
            }

            
            // Ensure setupIntent and payment_method are available
            if (!setupIntent || !setupIntent.payment_method) {
                toastNotify({ 'error': 'Setup Intent or Payment Method not found.' });
                throw new Error('Setup Intent or Payment Method not found.');
            }


            // Send the payment method ID to the backend for verification
            const verifyResponse = await fetch(`${process.env.REACT_APP_ENDPOINT}${GET_STRIPE_SECRET}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('amazio-token')}`, // Include auth token if required
                },
                body: JSON.stringify({ payment_method: setupIntent.payment_method, checkPaymentMethod: true }),
            }).then(res => res.json());

            if (!verifyResponse.success) {
                toastNotify({ 'error': verifyResponse.message || 'Payment method verification failed.' });
                throw new Error(verifyResponse.message || 'Payment method verification failed.');
            }
            var formData = new FormData(form);
        
            formData.append('total_amount', formfield.total_amount);
            formData.append('payable_amount', formfield.payable_amount ? formfield.payable_amount : formfield.total_amount);
            formData.append('plan_type', formfield.plan_type);
            formData.append('simple_services', formfield.simple_services);
            formData.append('payment_terms', formfield.payment_terms);
            formData.append('payment_method', setupIntent.payment_method);
            formData.append('interval_count', formfield.payment_terms);
                        
            // If the setup is successful, send form data to your backend for processing
            fetchData(UPGRADE_PLAN, 'POST', formData, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    form.classList.remove('was-validated');
                    form.reset();
                    localStorage.removeItem("plans-pricing");
                    if (res.records) {
                        localStorage.setItem('amazio-token', res.records.accessToken)
                        fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                            if (res.records) {
                                // navigate('/users/account-setting')
                            }
                        });

                    }
                    window.location.reload();
                } else {
                    toastNotify({ 'error': res.message });
                }
            });
        } catch (error) {
            setTinyloader(false);
            toastNotify({ 'error': error.message });
        }
    };

    return (<>
        <PaymentElement />
        <div className="col-md-12">
            <h2 className="fw-bolder mb-0">Your Subscription</h2>
        </div>
        <form id="upgrade_plan_form" className="row g-3 needs-validation" noValidate>
            <div className="col-sm-12">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="bg-dark text-white text-nowrap border-dark">
                            <tr>
                                <th className="border-end border-secondary">Plan Service Type</th>
                                <th className="border-end border-secondary">Services</th>
                                <th className="border-end border-secondary" width={170}>Terms</th>
                                <th width={100}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Recurring {get_pricing[0] === 'Trial' ? <span className="badge bg-warning text-dark">{get_pricing[4]} Day Trial</span> : ''}</td>
                                <td>Amazio Managed ( Business Digital Listings, Analytics, Reports & Intelligent Insights, Assisted Onboarding Including Account Creation & Setup )</td>
                                <td>
                                    <select className="form-select" onChange={(e) => planCalculate(e)}>
                                        <option value='180'>Semi Annual</option>
                                        <option value='360'>Annual</option>
                                    </select>
                                </td>
                                <td>${`${formfield.total_amount}`}</td>
                            </tr>
                            <small className="text-success" id="error_section"></small>
                            <tr>
                                <td className="border-bottom border-light"></td>
                                <td className="border-0 border-bottom border-light"></td>
                                <th className="">Payable Amount</th>
                                <th className="">${formfield.payable_amount ? formfield.payable_amount : formfield.total_amount}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-sm-12 mb-5">
                <div className="row align-items-center">
                    <div className="col-lg-9">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="accepted" />
                            <label className="form-check-label" htmlFor="inlineFormCheck">
                                I have read and agree to the <a href="/terms-conditions" target="_blank">Terms and Conditions</a> of service with Amazio
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-3 text-lg-end">
                        <button type="button" className="btn btn-warning fw-bold px-5" onClick={(e) => handleSubmit(e)} disabled={tinyloader || !stripe || !elements} >
                            {
                                !tinyloader ? 'Continue'
                                    :
                                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </>)
}

export default UpgradePlan