import React from "react";
import { Outlet } from "react-router-dom";
import AsideBar from "../components/AsideBar";
import FooterFront from "./FooterFront";
import HeaderFront from "./HeaderFront";
import HeaderFrontNew from "./HeaderFrontNew";
import FooterFrontNew from "./FooterFrontNew";

function LayoutOfFront() {
    return (
        <>
            {/* <HeaderFront /> */}
            <HeaderFrontNew />
            <Outlet />
            {/* <FooterFront/> */}
            <FooterFrontNew/>
        </>
    )
}

function LayoutOfAsideAndHeader() {
    return (
        <>
            <AsideBar />
            <Outlet />
        </>
    )
}

export { LayoutOfFront, LayoutOfAsideAndHeader}