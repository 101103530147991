import { Link } from 'react-router-dom'
import '../../css/style.scss'
import OwlCarousel from "react-owl-carousel";
import $ from "jquery";
import { useContext, useEffect, useState } from 'react';
import { fetchData, GET_BLOG, GET_URL } from '../../components/Service';
import { Context } from '../../components/Context';
import ClientTestimonials from './Testimonials';

function NewHome() {    
    const [data, setData] = useState();
    const [caseUrl, setCaseUrl] = useState();
	const [context, setContext] = useContext(Context)
	
	const scrollToSection = () => {
		const section = document.getElementById('reviewSection'); 
		if (section) {
		  section.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
		}
	  };

	const optionsCase = {
		margin: 20,
		responsiveClass: true,
		nav: false,
		dots: true,
		autoplayTimeout : 2500,
		autoplay : true,
		responsive: {
			0: {
				items: 1,
			},
			768: {
				items: 2,
			},
			992: {
				items: 3,
			}
		},
	}; 

    const options = {
		margin: 20,
		responsiveClass: true,
		slideTransition: 'linear',
		loop: true,
		nav: false,
		dots: false,
		autoplayTimeout : 2000,
		autoplay : true,
		responsive: {
			0: {
				items: 2,
			},
			768: {
				items: 3,
			},
			992: {
				items: 4,
			},
			1200: {
				items: 5,
	
			}
		},
	}; 
	useEffect(()=>{
        setInterval(() => {
            $('.counter-value').each(function () {
                var $this = $(this), countTo = $this.attr('data-count');
                // $this.removeClass('glow');
                $({ countNum: 0 }).animate({
                    countNum: countTo
                }, {
                    duration: 3500,
                    easing: 'linear',
                    step: function () {
                        $this.text(Math.floor(this.countNum));
                    },
                    complete: function () {
                        $this.text(this.countNum);
                        // $this.addClass('glow');
                    }
                });
            });
        }, 6000);
        fetchData(GET_BLOG + '?f_type=cases', 'GET', '', true, false, (res) => {
            if (res.records) {
                setData(res.records.case)
            }
        }, (err) => { })

        fetchData(GET_URL + '?type=cases', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCaseUrl(res.records)
            }
        }, (err) => { })
    },[])  
    return (
        <>
            <section className="new-hero-container section-padding position-relative">
				<div className="hero-dots">
					<img src="images/new/direction.png" className='flip-x' alt="" />
					<span className='scale'></span>
					<span className='scale'></span>
					<span className='scale'></span>
					<span className='scale'></span>
					<span className='scale'></span>
					<span className='scale'></span>
				</div>
                <div className="container">
                    <div className="row">
						<div className="col-lg-9 mx-auto">
							<div className="new-hero-content text-center">
								<h1>One-Stop Solution for All Your Digital Needs</h1>
								<p>Automate and manage your entire online presence with just a single click.
								</p>
								<div className="new-hero-cta d-flex flex-wrap justify-content-center gap-3">
									<Link to="/request-demo" className="new-btn btn-gradiant">Get Started Today <i className="bi bi-arrow-right-short"></i>
									</Link>
									{context && context.auth ? '' : 
									<Link to="/request-demo" className="new-btn btn-white-outline m-0">Request for Demo <i className="bi bi-arrow-right-short"></i>
									</Link>}
								</div>
							</div>

						</div>
				    </div>
                </div>
			    <div className="z-n1 position-absolute top-0 start-0 w-100 h-100">
					<img className='img-cover' src="images/new/hero-bg.jpg" alt="" />
			    </div>
            </section>
			{/* section  */}
		    <section className="new-about-container section-padding position-relative zn-1">
			     <div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="new-about-img position-relative">								
								<div className="ratio ratio-4x3 rounded-4 overflow-hidden">
									<video controls loop preload className='img-cover'>
										<source src="images/videos/about-us.mp4" type="video/mp4" />
										<source src="images/videos/about-us.webm" type="video/webm" />
									</video>
								</div>
								{/* <button onClick={()=>setPlay((prev=>!prev))} className="new-about-play position-absolute top-50 start-50 translate-middle">
									<i className="bi bi-play-fill"></i>
								</button> */}
							</div>
						</div>
						<div className="col-lg-6 ps-lg-4 ps-xl-5 mt-4 mt-md-5 mt-lg-0">
							<div className="new-about-content">
								<div className="new-heading">
									<h3>Try Amazio Starter Suite for free</h3>
									<p>Unite marketing, sales, and service in a single app. Try Amazio Starter Suite today. There's nothing to install. No credit card required.</p>
								</div>
								<div className="mt-4 mt-xl-5 d-flex flex-wrap justify-content-start   gap-3">
								{context && context.auth ? '' : 
									<Link to="/plans-pricing" className="new-btn btn-gradiant">Start free trial <i className="bi bi-arrow-right-short"></i>
									</Link>}
									<Link to="/scan-your-business" className="new-btn btn-cyan-outline m-0">Scan now <i className="bi bi-arrow-right-short"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
				 </div>
			    <div className="z-n1 position-absolute top-0 start-0 w-100 h-100">
				  <img className='img-cover' src="images/new/bg-2.png" alt="" />
			    </div>
		    </section>
			{/* section  */}
		    <section className="digital-trends-container section-padding position-relative zn-1" hidden>
			     <div className="container">
					<div className="row">
						<div className="col-md-10 mx-auto">
							<div className="new-heading text-center">
								<h3> Struggling to Keep Up with Digital Trends? Meet Amazio 
								</h3>
								<p>Amazio is a comprehensive digital platform designed to be your one-stop solution for managing your digital presence, social engagement, and online reputation.</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-lg-4">
							<div className="digital-trends-box">
								<div className="digital-trends-thumb">
									<img src="images/new/team1.png" alt="" />
								</div>
								<div className="digital-trends-content">
									<span><img src="images/new/dt-icon1.png" alt="" /></span>
									<h4>Custom-Built Digital Solutions </h4>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="digital-trends-box active">
								<div className="digital-trends-thumb">
									<img src="images/new/team2.png" alt="" />
								</div>
								<div className="digital-trends-content">
									<span><img src="images/new/dt-icon2.png" alt="" /></span>
									<h4>Mobile-First-Approach </h4>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="digital-trends-box">
								<div className="digital-trends-thumb">
									<img src="images/new/team3.png" alt="" />
								</div>
								<div className="digital-trends-content">
									<span><img src="images/new/dt-icon3.png" alt="" /></span>
									<h4>Dedicated support & Maintenance</h4>
								</div>
							</div>
						</div>
					</div>
				 </div>
			    <div className="digital-trends-bg z-n1 position-absolute top-0 start-0 w-100 h-100 bg-gradiant2">
				   <span className='flip-y'></span>
			    </div>
		    </section>
			{/* section  */}
		    <section className="platform-container section-padding position-relative z-1">
			     <div className="container">
					<div className="row">
						<div className="col-lg-11 mx-auto">
							<div className="platform-wrapper">
								<div className="platform-upper">
									<div className="platform-upper-icons d-flex flex-wrap">
										<div className="platform-upper-icon">
											<span>
												<img className='img-cover' src="images/new/services/1.svg" alt="" />
											</span>
											<h5>Business digital <br/> Listing & Branding</h5>
										</div>
										<div className="platform-upper-icon">
											<span>
											<img className='img-cover' src="images/new/services/2.svg" alt="" />
											</span>
											<h5>Social Media <br/> Management</h5>
										</div>
										<div className="platform-upper-icon">
											<span>
											<img className='img-cover' src="images/new/services/3.svg" alt="" />
											</span>
											<h5>Integrated Digital Marketing</h5>
										</div>
										<div className="platform-upper-icon">
											<span>
											<img className='img-cover' src="images/new/services/4.svg" alt="" />
											</span>
											<h5>Reviews, Reputation & Sentiment Analysis</h5>
										</div>
										<div className="platform-upper-icon">
											<span>
											<img className='img-cover' src="images/new/services/5.svg" alt="" />
											</span>
											<h5>Branding & <br/> Design services</h5>
										</div>
									</div>
								</div>
								<div className="platform-lower">
									<span className="left-line"></span>
									<div className="left">
										<div className="platform-upper-icon">
											<span><img className="img-cover" src="images/new/icon1.png" alt="" /></span>
											<h5> Digital Content   <br /> services </h5>
										</div>
									</div>
									<div className="right">
										<div className="platform-upper-icon">
											<span>
												<img className='img-cover' src="images/new/services/6.svg" alt="" />
											</span>
											<h5>Competitive Intelligence, <br />  Insights & Analytics </h5>
										</div>
									</div>
									<span className="right-line"></span>
								</div>
								<div className="platform-upper  pb-4">
									<div className="platform-upper-icons pui-four d-flex flex-wrap">
										<div className="platform-upper-icon">
											<span>
												<img className='img-cover' src="images/new/services/10.svg" alt="" />
											</span>
											<h5>Print Media</h5>
										</div>
										<div className="platform-upper-icon">
											<span>
												<img className='img-cover' src="images/new/services/9.svg" alt="" />
											</span>
											<h5>Voice search <br/> Pro</h5>
										</div>
										<div className="platform-upper-icon">
											<span>
												<img className='img-cover' src="images/new/services/8.svg" alt="" />
											</span>
											<h5>Web & App Development <br />  With Maintenance</h5>
										</div>
										<div className="platform-upper-icon">
											<span>
												<img className='img-cover' src="images/new/services/7.svg" alt="" />
											</span>
											<h5>Web & Mobile <br /> Informational Tags</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-10 mx-auto mt-5">
							<div className="new-heading text-white text-center">
								<h4>Your Whole Front Office. One Customer Platform.</h4>
								<p>Your whole front office in one customer platform streamlines operations, integrating communication, support, sales, and service into a unified system, enhancing customer experience and boosting efficiency across all touchpoints.</p>
							</div>
							<div className="text-center  d-flex flex-wrap justify-content-center gap-3">	
								<Link to="/" className="new-btn btn-gradiant">services page <i className="bi bi-arrow-right-short"></i>
								</Link>
								<Link to="/contact-us" className="new-btn btn-white-outline m-0">Contact us <i className="bi bi-arrow-right-short"></i>
								</Link>
							</div>
						</div>
					</div>
				 </div>
			    <div className="z-n1 position-absolute top-0 start-0 w-100 h-100">
				  <img className='img-cover' src="images/new/platform-bg.jpg" alt="" />
			    </div>
		    </section>
			
			{/* section  */}
		    <section className="new-service-container position-relative section-padding">
			     <div className="container">
					<div className="row">
                        <div className="col-md-10 mx-auto">
							<div className="new-heading text-center">
								<h3>Elevate Your Digital Game with Amazio’s Expert Services</h3>
							</div>
						</div>
					</div>
					<div className="row g-3 g-md-4">
						<div className="col-md-6 col-lg-4">
							<div className="new-service-box">
								<div className="new-service-head">
									<div className="new-service-icon">
										<img src="images/new/service-icon-1.png" alt="" />
									</div>
									<h4>Business Digital Listing & Branding</h4>
								</div>
								<div className="new-service-body">
									<div className="new-service-intro">
										<p>Amazio ensures your business stands out by creating, managing, and optimizing your online listings and branding. Establish trust and visibility across all platforms seamlessly.</p>
									</div>
									<div className="new-service-feature">
										<h4>Features</h4>
										<ul className="new-service-features">
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Consistent branding across digital directories
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Optimization for local SEO
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Cross-platform reputation management
											</li>
										</ul>
									</div>
								</div>
								<div className="new-service-footer">
									<Link to="/request-demo" className="new-btn btn-cyan w-100 rounded-3">Learn more</Link>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="new-service-box">
								<div className="new-service-head">
									<div className="new-service-icon">
										<img src="images/new/service-icon-3.png" alt="" />
									</div>
									<h4>Social Media <br/> Management</h4>
								</div>
								<div className="new-service-body">
									<div className="new-service-intro">
										<p>Create, schedule, and analyze social media campaigns with ease. Connect with your audience on the platforms they love most.</p>
									</div>
									<div className="new-service-feature">
										<h4>Features</h4>
										<ul className="new-service-features">
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Automated posting and scheduling tools
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Advanced analytics for engagement tracking
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Cross-platform integration
											</li>
										</ul>
									</div>
								</div>
								<div className="new-service-footer">
									<Link to="/request-demo" className="new-btn btn-cyan w-100 rounded-3">Learn more</Link>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="new-service-box">
								<div className="new-service-head">
									<div className="new-service-icon">
										<img src="images/new/service-icon-6.png" alt="" />
									</div>
									<h4>Integrated Digital Marketing Services</h4>
								</div>
								<div className="new-service-body">
									<div className="new-service-intro">
										<p>At Amazio, we specialize in delivering results-driven digital marketing solutions tailored to your business needs. Our integrated approach ensures every aspect of your online presence works together seamlessly to achieve your goals. Discover our core services below.</p>
									</div>
									<div className="new-service-feature">
										<h4>Features</h4>
										<ul className="new-service-features">
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Search Engine Optimization (SEO)
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Social Media Optimization (SMO)
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Social Media Marketing (SMM)
											</li>
										</ul>
									</div>
								</div>
								<div className="new-service-footer">
									<Link to="/request-demo" className="new-btn btn-cyan w-100 rounded-3">Learn more</Link>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4">
							<div className="new-service-box">
								<div className="new-service-head">
									<div className="new-service-icon">
										<img src="images/new/service-icon-2.png" alt="" />
									</div>
									<h4>Reviews, Reputation & Sentiment Analysis</h4>
								</div>
								<div className="new-service-body">
									<div className="new-service-intro">
										<p>Monitor, analyze, and improve your online reputation with real-time feedback and sentiment tracking. Let Amazio help you build trust with your audience.</p>
									</div>
									<div className="new-service-feature">
										<h4>Features</h4>
										<ul className="new-service-features">
											<li>
												<i className="bi bi-check-circle-fill"></i>
												AI-powered sentiment analysis
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Review aggregation and response tools
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Alerts for real-time reputation risks
											</li>
										</ul>
									</div>
								</div>
								<div className="new-service-footer">
									<Link to="/request-demo" className="new-btn btn-cyan w-100 rounded-3">Learn more</Link>
								</div>
							</div>
						</div>
						
						<div className="col-md-6 col-lg-4">
							<div className="new-service-box">
								<div className="new-service-head">
									<div className="new-service-icon">
										<img src="images/new/service-icon-4.png" alt="" />
									</div>
									<h4>Branding & Design <br/> Services</h4>
								</div>
								<div className="new-service-body">
									<div className="new-service-intro">
										<p>Deliver stunning visuals and compelling content that captures your brand’s essence. From photography to digital assets, Amazio has you covered.</p>
									</div>
									<div className="new-service-feature">
										<h4>Features</h4>
										<ul className="new-service-features">
											<li>
												<i className="bi bi-check-circle-fill"></i>
												High-quality graphic design
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Content strategy and execution
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Custom photography and imagery
											</li>
										</ul>
									</div>
								</div>
								<div className="new-service-footer">
									<Link to="/request-demo" className="new-btn btn-cyan w-100 rounded-3">Learn more</Link>
								</div>
							</div>
						</div>
						{/* <div className="col-md-6 col-lg-4">
							<div className="new-service-box">
								<div className="new-service-head">
									<div className="new-service-icon">
										<img src="images/new/service-icon-5.png" alt="" />
									</div>
									<h4>Competitive Intelligence, Insights & Analytics</h4>
								</div>
								<div className="new-service-body">
									<div className="new-service-intro">
										<p>Stay ahead with Amazio’s cutting-edge analytics tools that track and evaluate competitor strategies and industry trends.</p>
									</div>
									<div className="new-service-feature">
										<h4>Features</h4>
										<ul className="new-service-features">
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Competitor performance benchmarking
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												 Industry trend forecasting
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Actionable insights and reports
											</li>
										</ul>
									</div>
								</div>
								<div className="new-service-footer">
									<Link to="/request-demo" className="new-btn btn-cyan w-100 rounded-3">Learn more</Link>
								</div>
							</div>
						</div> */}
						<div className="col-md-6 col-lg-4">
							<div className="new-service-box">
								<div className="new-service-head">
									<div className="new-service-icon">
										<img src="images/new/service-icon-6.png" alt="" />
									</div>
									<h4>Web & App Development with Maintenance</h4>
								</div>
								<div className="new-service-body">
									<div className="new-service-intro">
										<p>Build robust websites and mobile applications with ongoing support and maintenance to ensure peak performance.</p>
									</div>
									<div className="new-service-feature">
										<h4>Features</h4>
										<ul className="new-service-features">
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Scalable and secure development
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Regular updates and bug fixes
											</li>
											<li>
												<i className="bi bi-check-circle-fill"></i>
												Seamless integration with Amazio’s tools
											</li>
										</ul>
									</div>
								</div>
								<div className="new-service-footer">
									<Link to="/request-demo" className="new-btn btn-cyan w-100 rounded-3">Learn more</Link>
								</div>
							</div>
						</div>
						<div className="mt-4 text-center">
							<Link to='/services' className="new-btn btn-gradiant">View All<i className="bi bi-arrow-right-short"></i>
							</Link>
						</div>
					</div>
				 </div>
		    </section>
			{/* section  */}
		    <section className="case-study-container section-padding pt-0 overflow-hidden" >
			    <div className="container">
					<div className="row">
                        <div className="col-md-12">                           
							<div className="new-heading text-center">
								<h3>Case Studies</h3>
								<p>Learn more about business case studies.</p>
							</div>
							<div className="case-study-wrapper">
								{data && <>
								<OwlCarousel className="owl-theme px-1" {...optionsCase}>
									{data.map((ele, i) => {
										return (
											<div className="case-study-card" key={i}>
												<a className="case-study-thumb" target="_blank" href={ele.link} rel="noreferrer">
													{ele.images ? <img src={ele.images} data-src={ele.images} alt="case-study" />
														:
														<img src="images/lazy-loader.svg" data-src="images/lazy-loader.svg" alt="case-study" />
													}
												</a>
												<div className="case-study-content">
													<h4>{ele.title}</h4>
													{ele.content && <div className='case-study-inn' dangerouslySetInnerHTML={{ __html: ele.content }} />}
												</div>
												<div className="case-study-cta">
													<a target="_blank" href={ele.link} rel="noreferrer" >
														Continue reading 
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
														</svg>
													</a>
												</div>
											</div>
										)
									})}
								</OwlCarousel>
								</>}
							    <div className="mt-4 text-center">
									<Link to={caseUrl} className="new-btn btn-gradiant">All Case Studies<i className="bi bi-arrow-right-short"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			{/* section  */}
		    <section className="service-tab-container position-relative z-3">
			     <div className="container">
					<div className="row">
                        <div className="col-md-12">
							<div className="service-tab-wrapper">
								<div className="service-tab-left">
									<div className="service-tab-left-header">
										<img src="images/new/logo.svg" alt="" />
									</div>
									<div className="service-tab-left-body">
										<ul className="nav nav-tabs" id="serviceTab" role="tablist">
											<li role="presentation">
												<button className="nav-link active" id="one-tab" data-bs-toggle="tab" data-bs-target="#one-tab-pane" type="button" role="tab" aria-controls="one-tab-pane" aria-selected="true">
													<i className="bi bi-people"></i>
													<h4>Manage Super Admins</h4>
												</button>
											</li>
											<li role="presentation">
												<button className="nav-link" id="two-tab" data-bs-toggle="tab" data-bs-target="#two-tab-pane" type="button" role="tab" aria-controls="two-tab-pane" aria-selected="false">
													{/* <img src="images/new/tab-icon-1.png" alt="" /> */}
													<i className="bi bi-star"></i>
													<h4>Manage Business Listing</h4>
												</button>
											</li>
											<li role="presentation">
												<button className="nav-link" id="three-tab" data-bs-toggle="tab" data-bs-target="#three-tab-pane" type="button" role="tab" aria-controls="three-tab-pane" aria-selected="false">
												<i className="bi bi-list"></i>
													<h4>Imprints History</h4>
												</button>
											</li>
											<li role="presentation">
												<button className="nav-link" id="four-tab" data-bs-toggle="tab" data-bs-target="#four-tab-pane" type="button" role="tab" aria-controls="four-tab-pane" aria-selected="false">
													<i className="bi bi-dribbble"></i>
													<h4>Social Media Manager</h4>
												</button>
											</li>
											<li role="presentation">
												<button className="nav-link" id="five-tab" data-bs-toggle="tab" data-bs-target="#five-tab-pane" type="button" role="tab" aria-controls="five-tab-pane" aria-selected="false">
													<i className="bi bi-wallet"></i>
													<h4>Sales Agent</h4>
												</button>
											</li>
											<li role="presentation">
												<button className="nav-link" id="six-tab" data-bs-toggle="tab" data-bs-target="#six-tab-pane" type="button" role="tab" aria-controls="six-tab-pane" aria-selected="false">
													<i className="bi bi-gear"></i>
													<h4>Support</h4>
												</button>
											</li>
										</ul>
									</div>
								</div>								
								<div className="service-tab-right">
									<div className="tab-content" id="myTabContent">
										<div className="tab-pane fade show active" id="one-tab-pane" role="tabpanel" aria-labelledby="one-tab" tabindex="0">
											<div className="service-tab-right-body">
												<h3>Empowering Security, Ensuring Control Manage Super Admins with Care!</h3>
												<p>Managing Super Admins ensures proper oversight of top-level access, user control, and system security, preventing misuse and maintaining integrity.</p>
												<div className="mt-3 mt-md-4">
													{/* <Link to="/amazio1" className="new-btn btn-cyan-outline">View all customer <i className="bi bi-arrow-right-short"></i></Link> */}
												</div>
												<img src="images/new/service-tab-img.png" alt="" />
											</div>
										</div>
										<div className="tab-pane fade" id="two-tab-pane" role="tabpanel" aria-labelledby="two-tab" tabindex="0">
											<div className="service-tab-right-body">
												<h3>Empowering Security, Ensuring Control Manage Super Admins with Care!</h3>
												<p>Managing Super Admins ensures proper oversight of top-level access, user control, and system security, preventing misuse and maintaining integrity.</p>
												<div className="mt-3 mt-md-4">
													{/* <Link to="/amazio1" className="new-btn btn-cyan-outline">View all customer <i className="bi bi-arrow-right-short"></i></Link> */}
												</div>
												<img src="images/new/service-tab-img.png" alt="" />
											</div>
										</div>
										<div className="tab-pane fade" id="three-tab-pane" role="tabpanel" aria-labelledby="three-tab" tabindex="0">
											<div className="service-tab-right-body">
												<h3>Empowering Security, Ensuring Control Manage Super Admins with Care!</h3>
												<p>Managing Super Admins ensures proper oversight of top-level access, user control, and system security, preventing misuse and maintaining integrity.</p>
												<div className="mt-3 mt-md-4">
													{/* <Link to="/amazio1" className="new-btn btn-cyan-outline">View all customer <i className="bi bi-arrow-right-short"></i></Link> */}
												</div>
												<img src="images/new/service-tab-img.png" alt="" />
											</div>
										</div>
										<div className="tab-pane fade" id="four-tab-pane" role="tabpanel" aria-labelledby="four-tab" tabindex="0">
											<div className="service-tab-right-body">
												<h3>Empowering Security, Ensuring Control Manage Super Admins with Care!</h3>
												<p>Managing Super Admins ensures proper oversight of top-level access, user control, and system security, preventing misuse and maintaining integrity.</p>
												<div className="mt-3 mt-md-4">
													{/* <Link to="/amazio1" className="new-btn btn-cyan-outline">View all customer <i className="bi bi-arrow-right-short"></i></Link> */}
												</div>
												<img src="images/new/service-tab-img.png" alt="" />
											</div>
										</div>
										<div className="tab-pane fade" id="five-tab-pane" role="tabpanel" aria-labelledby="five-tab" tabindex="0">
											<div className="service-tab-right-body">
												<h3>Empowering Security, Ensuring Control Manage Super Admins with Care!</h3>
												<p>Managing Super Admins ensures proper oversight of top-level access, user control, and system security, preventing misuse and maintaining integrity.</p>
												<div className="mt-3 mt-md-4">
													{/* <Link to="/amazio1" className="new-btn btn-cyan-outline">View all customer <i className="bi bi-arrow-right-short"></i></Link> */}
												</div>
												<img src="images/new/service-tab-img.png" alt="" />
											</div>
										</div>
										<div className="tab-pane fade" id="six-tab-pane" role="tabpanel" aria-labelledby="six-tab" tabindex="0">
											<div className="service-tab-right-body">
												<h3>Empowering Security, Ensuring Control Manage Super Admins with Care!</h3>
												<p>Managing Super Admins ensures proper oversight of top-level access, user control, and system security, preventing misuse and maintaining integrity.</p>
												<div className="mt-3 mt-md-4">
													{/* <Link to="/amazio1" className="new-btn btn-cyan-outline">View all customer <i className="bi bi-arrow-right-short"></i></Link> */}
												</div>
												<img src="images/new/service-tab-img.png" alt="" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				 </div>
		    </section>
			
			{/* section  */}
		    <section className="new-stats-container position-relative z-1" hidden>
			     <div className="container">
					<div className="row">
                        <div className="col-md-12">
							<div className="new-stats-wraper text-center">
								<div className="new-heading text-white px-md-4">
									<h3>Our 2020 Numbers</h3>
									{/* <p className='px-md-3 px-lg-5'> marketing and automations platform* that recommends ways to get <br /> more opens, clicks, and sales.</p> */}
								</div>
								<div className="new-stats-items d-flex flex-wrap justify-content-center">
									<div className="new-stats-item">
										<h3 className="counter-value " data-count="49771">49771</h3>
										<p>New Listings Created</p>
									</div>
									<div className="new-stats-item">
										<h3 className="counter-value " data-count="342351">342351</h3>
										<p>Digital Listings Updated</p>
									</div>
									<div className="new-stats-item">
										<h3 className="counter-value " data-count="14400000">14400000</h3>
										<p>Views On Google Map</p>
									</div>
									<div className="new-stats-item">
										<h3 className="counter-value " data-count="3100000">3100000</h3>
										<p>Views on Search</p>
									</div>
									<div className="new-stats-item">
										<h3 className="counter-value " data-count="37000">37000</h3>
										<p>Instagram Followers</p>
									</div>
									<div className="new-stats-item">
										<h3 className="counter-value " data-count="19000">19000</h3>
										<p>Facebook Likes</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				 </div>
			    <div className="z-n1 position-absolute top-0 start-0 w-100 h-100">
				  <img className='img-cover' src="images/new/back-bg-2.png" alt="" />
			    </div>
		    </section>
			
			{/* section  */}
		    <section className="new-stats-container position-relative z-1">
			     <div className="container">
					<div className="row">
                        <div className="col-md-12">
							<div className="new-stats-wraper text-center">
								<div className="new-heading mb-0 px-md-4">
									<h4 className='mb-0'>Take control of your online brand across 100+ digital services.
									</h4>
								</div>
								<div className='take-control-img px-5'>
									<img className='img-cover ' src="images/network.gif" alt="Take control of your online brand across 100+ digital services." />
								</div>  
							</div>
							<div className='mt-4 text-center'>
								<Link to="/request-demo" className="new-btn btn-white-outline"> Get Started <i className="bi bi-arrow-right-short"></i></Link>
							</div>
						</div>
					</div>
				 </div>
			    <div className="z-n1 position-absolute top-0 start-0 w-100 h-100">
				  <img className='img-cover' src="images/new/back-bg-2.png" alt="" />
			    </div>
		    </section>
			
			{/* section  */}
		    <section className="new-review-container section-padding position-relative" id="reviewSection">
			     <div className="container">
					<div className="row">
                        <div className="col-md-12">
							<div className="new-stats-wraper text-center">
								<div className="new-heading px-md-4">
									<h3>What Our Clients Say</h3>
									<p className='px-md-3 px-lg-5'> Hear firsthand experiences from those who have partnered with us. Discover the impact we’ve made through their inspiring stories.</p>
								</div>
								<ClientTestimonials/>
							</div>
						</div>
					</div>
				 </div>
		    </section>


			{/* section  */}
		    <section className="client-testi-container  section-padding pt-0 position-relative z-1">
			     <div className="container">
					<div className="row">
						<div className="col-md-10 col-lg-9 mx-auto">
							<div className="new-heading  text-center">
								<h3>Social Proof & Client Testimonials</h3>
								<p>Build trust by showing that others especially recognizable brands are already benefiting from your solutions.
								</p>
							</div>
							<div className="text-center">								
								{/* <a href="/#reviewSection" className="new-btn btn-cyan-outline">View all customer <i className="bi bi-arrow-right-short"></i></a> */}
								<a href="#" className="new-btn btn-cyan-outline" onClick={(e) => { e.preventDefault(); scrollToSection(); }}>View all customer <i className="bi bi-arrow-right-short"></i></a>
							</div>
						</div>
					</div>
					<div className="row  mt-5 pt-lg-3">
						<div className="col-md-12">	
							<OwlCarousel className="owl-theme px-1" {...options}>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/1.png" alt="" />
								</div>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/2.png" alt="" />
								</div>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/3.png" alt="" />
								</div>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/4.png" alt="" />
								</div>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/5.png" alt="" />
								</div>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/6.png" alt="" />
								</div>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/7.png" alt="" />
								</div>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/8.png" alt="" />
								</div>
								<div className="custores-item">
									<img className='img-cover' src="images/new/customer/9.png" alt="" />
								</div>
							</OwlCarousel>
						</div>
					</div>
				 </div>
		    </section>
			
			{/* section  */}
		    <section className="new-partner-container section-padding" hidden>
			     <div className="container">
					<div className="row">
                        <div className="col-md-10 col-lg-8 mx-auto">
							<div className="new-heading text-center">
								<h3> Certifications</h3>
								<p>Reinforce credibility and trust with security badges, industry certifications, and compliance.</p>
							</div>
							<div className="mt-5">
								<div className="d-flex justify-content-center align-items-center gap-3 gap-md-4 px-5">
									<img src="https://placehold.jp/100x100.png" alt="" />
									<img src="https://placehold.jp/100x100.png" alt="" />
									<img src="https://placehold.jp/100x100.png" alt="" />
								</div>
							</div>
						</div>
					</div>
				 </div>
		    </section>

            {/* section  */}		
			{/* section  */}
		    <section className="bottom-cta-container position-relative overflow-hidden z-1">
			     <div className="container">
					<div className="row">
                        <div className="col-md-12">
							<div className="bottom-cta-wraper text-center text-md-start position-relative d-flex flex-column flex-md-row align-items-center gap-4 gap-md-5">
								<img className="assets-1 position-absolute flip-x" src='images/new/bottom-cta-assets-1.png' alt="" />
								<img className="assets-2 position-absolute flip-y" src='images/new/bottom-cta-assets-2.png' alt="" />
								<h2 className='mb-0'>Sign up for Amazio today and unlock your business’s full potential.</h2>
								<div className="flex-shrink-0 ps-lg-5">
									<Link to="/contact-us" className="new-btn btn-gradiant">Let’s Build Your Digital Future <i className="bi bi-arrow-right-short"></i></Link>
								</div>
							</div>
						</div>
					</div>
				 </div>
			    <div className="z-n1 position-absolute top-0 start-0 w-100 h-100">
				  <img className='img-cover' src="images/new/bottom-cta-bg.png" alt="" />
			    </div>
		    </section>
        </>
    )
}
export default NewHome