import React, { useEffect, useState } from "react";
import 'intl-tel-input/build/css/intlTelInput.css';
import { fetchData, ADD_REQUEST_DEMO, GET_URL } from "../../components/Service"
import { validateForm, intlTel_phone, validatePhone, initialFormState } from "../../components/Helper"
import { Link } from "react-router-dom";
import ClientTestimonials from "./Testimonials";


function RequestDemo() {

    const [intlTel, setIntlTel] = useState()
    const [tinyloader, setTinyloader] = useState(false);
    const [caseUrl, setCaseUrl] = useState();


    const faqObj = [
        {
          "question": "Is the demo really free?",
          "answer": "Yes! The demo is free and there are no obligations or hidden fees."
        },
        {
          "question": "How long will the demo take?",
          "answer": "The demo lasts about 30 minutes, but we’ll tailor it based on your needs."
        },
        {
          "question": "Can I ask questions during the demo?",
          "answer": "Absolutely! The demo is interactive, and we encourage you to ask any questions you may have."
        },
        {
          "question": "What if I need to reschedule my demo?",
          "answer": "ou can easily reschedule by using the link we send to you after booking."
        }
    ]


    const [faq, setFaq] = useState(faqObj);

	const optionsReview = {
		margin: 20,
		responsiveClass: true,
		nav: false,
		dots: true,
		autoplayTimeout : 2500,
		autoHeight: true,
		autoplay : true,
		responsive: {
			0: {
				items: 1,
				autoHeight: true
			},
			992: {
				items: 2,
				autoHeight: true,
				margin: 30,
			}
		},
	}; 

    const scrollToDemoSection = () => {
        const section = document.getElementById('demoSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const saveData = (e) => {
        let formData = new FormData(document.getElementById('requestDemoForm'));
        var msg_error = document.getElementById('msg-error');
        formData.append('business_phone', intlTel.getNumber())
        if (validateForm(e, 'requestDemoForm') && validatePhone(intlTel, '#business_phone')) {
            if (formData.get('g-recaptcha-response')) {
                msg_error.classList.add('d-none');
                setTinyloader(true)
                fetchData(ADD_REQUEST_DEMO, 'POST', formData, true, true, (res) => {
                    if (res.success) {
                        initialFormState('requestDemoForm')
                        setTinyloader(false)
                    }
                })
            } else {
                msg_error.classList.remove('d-none');
            }
        }

    }
    useEffect(()=>{

        fetchData(GET_URL + '?type=cases', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCaseUrl(res.records)
            }
        }, (err) => { })
    },[]) 


    useEffect(() => {
        document.querySelector('#video-section').innerHTML = '<video width="100%" autoPlay={true} muted="" loop={true}><source src="images/request-demo.mp4" type="video/mp4" /></video>'
        setIntlTel(intlTel_phone('#business_phone'))

        const script = document.createElement("script"); script.async = true;
        script.src = 'https://www.google.com/recaptcha/api.js';
        document.body.appendChild(script);
    }, [])

    return (
        <>
            <style>{`
                .form-floating>.label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    padding: 1.5rem .4rem 0.4rem .4rem;
                    pointer-events: none;
                    border: 1px solid transparent;
                    transform-origin: 0 0;
                    opacity: .55;
                    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
                }    
                .form-floating>.form-control:focus~.label, .form-floating>.form-control:not(:placeholder-shown)~.label, .form-floating>.form-select~.label {
                    opacity: .65;
                    transform: scale(.85) translateY(-1.3rem) translateX(.125rem);
                }   
            
            `}</style>


            <section className="pt-5 position-relative z-1">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-7 text-center text-md-start mb-4 mb-md-5 pe-md-5">
                            <h1 className="fw-bold">Experience the Power of Digital Marketing with a Free Demo</h1>
                            <p>Discover how Amazio can help you elevate your business with tailored strategies and cutting-edge solutions. Book your personalized demo today!</p>
                            <div className="d-flex flex-wrap gap-2 justify-content-center justify-content-md-start">
                                <Link to="#" className="new-btn btn-gradiant" onClick={scrollToDemoSection} >Request Your Free Demo
                                </Link>
                                <Link to="/plans-pricing" className="new-btn btn-cyan-outline m-0">Get Started
                                </Link>
                            </div>
                        </div>
                        <div className="col-10 col-md-5 pt-4">
                            <img src="images/happy-customer.png" className="w-100" alt="contact" />
                        </div>
                    </div>
                </div>
                <div className='z-n1 position-absolute top-0 start-0 w-100 h-100 bg-gradiant2'></div>
            </section>

            <div className="section-padding bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading text-md-center">
                                <h4>Why Book a Demo with Amazio?</h4>
                            </div>                        
                        </div>
                        <div className="col-md-6">
                            <div className="service-content-box">
                                <ul className="service-content-list">
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i> Tailored Solutions
                                        </h5>
                                        <p>Our demos are personalized to address your business’s unique needs.</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i> Expert Insights:
                                        </h5>
                                        <p>Gain insights from industry-leading experts who can help you maximize your digital marketing efforts.</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i> No Obligations: 
                                        </h5>
                                        <p>The demo is completely free with no obligation to purchase.</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i>Real Results:
                                        </h5>
                                        <p>See how we’ve helped businesses like yours boost online presence and grow revenue.</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i> Cutting-Edge Tools: 
                                        </h5>
                                        <p>Learn about the latest digital marketing tools and strategies we use to drive results.</p>
                                    </li>
                                </ul>
                            </div>
                                <div className="d-flex flex-wrap gap-2 mt-4 ps-4 ">
                                    <Link to="#" className="new-btn btn-gradiant" onClick={scrollToDemoSection}>Book Your Free Demo Now</Link>
                                </div>
                        </div>
                        <div className="col-md-6 order-n1">
                            <div className="service-img-box mb-4 mb-md-0">
                                <img src="images/service-imgs/request-demo-1.png" className="img-cover" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-padding bg-gray">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading text-md-center">
                                <h4>How It Works</h4>
                            </div>                        
                        </div>
                        <div className="col-md-6 order-n1">
                            <div className="service-img-box mb-4 mb-md-0">
                                <img src="images/service-imgs/request-demo-2.png" className="img-cover" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-content-box">
                                <ul className="service-content-list">
                                    <li>
                                        <h5>
                                            <i className="counter-icon">1</i> Fill Out the Request Form:
                                        </h5>
                                        <p>Briefly explain what information you need (Name, Email, Business Size, etc.).</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="counter-icon">2</i> Schedule Your Demo:
                                        </h5>
                                        <p>Visitors can choose a time that works best for them. You can integrate a scheduling tool here (e.g., Calendly, Acuity).</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="counter-icon">3</i> Personalized Walkthrough:
                                        </h5>
                                        <p>Once booked, we’ll walk you through how Amazio can help you achieve your digital marketing goals.</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="counter-icon">4</i>Q&A and Next Steps:
                                        </h5>
                                        <p>Ask questions, get clarifications, and discuss how Amazio’s services fit your needs.</p>
                                    </li>
                                </ul>
                                <div className="d-flex flex-wrap gap-2 mt-4 ps-4 ms-3">
                                    <Link to="#" className="new-btn btn-gradiant" onClick={scrollToDemoSection} >Get Started Today </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-padding bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading text-md-center">
                                <h4>What Will You Learn During Your Free Demo?</h4>
                            </div>                        
                        </div>
                        <div className="col-md-6">
                            <div className="service-content-box">
                                <ul className="service-content-list">
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i> Customized Marketing Strategies: 
                                        </h5>
                                        <p>Get a roadmap for optimizing your digital marketing efforts.</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i> Cutting-Edge Tools & Techniques:
                                        </h5>
                                        <p>Explore the latest tools and best practices that drive measurable results.</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i> ROI Metrics: 
                                        </h5>
                                        <p>Understand how to measure and track your marketing campaigns for maximum return.</p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill "></i>Industry Best Practices:
                                        </h5>
                                        <p>Learn from our experts about industry-specific trends and strategies.</p>
                                    </li>
                                </ul>
                                <div className="d-flex flex-wrap gap-2 mt-4 ps-4">
                                    <Link to="#" className="new-btn btn-gradiant" onClick={scrollToDemoSection} >Reserve Your Spot Now </Link>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-6 order-n1">
                            <div className="service-img-box mb-4 mb-md-0">
                                <img src="images/service-imgs/request-demo-3.png" className="img-cover" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-padding bg-gray">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading mb-4 text-md-center">
                                <h4>Our Clients Love What We Do</h4>
                            </div>                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ClientTestimonials/>
                            <div className="mt-4 text-center">
                                <Link to={caseUrl} className="new-btn btn-gradiant">Read Our Success Stories</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="demoSection" className="section-padding position-relative z-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading text-md-center">
                                <h4>Book Your Free Demo Today</h4>
                            </div>                        
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-8 col-lg-6 order-md-last">
                            <form className="needs-validation p-4 p-md-5  bg-light h-100" id="requestDemoForm"method="post" noValidate>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" name="first_name" id="first_name" className="form-control form-bottomline" placeholder="First Name*" required />
                                        <div className="invalid-feedback">The first name is required.</div>
                                    </div>
                                    <div className="col">
                                        <input type="text" name="last_name" id="last_name" className="form-control form-bottomline" placeholder="Last Name*" required />
                                        <div className="invalid-feedback">The last name is required.</div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col">
                                        <input type="email" className="form-control form-bottomline" placeholder="Business Email*" aria-label="Business Email" defaultValue="" name="business_email" id="business_email" required />
                                        <div className="invalid-feedback">The email is required.</div>
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control form-bottomline" placeholder="PC Number (Business Alias)" aria-label="PC Number" defaultValue="" name="pc_number" id="pc_number" />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col">
                                        <input type="tel" className="form-control form-bottomline" placeholder="Business Phone*" aria-label="Business Name" defaultValue="" id="business_phone" name="business_phone" data-intl-tel-input-id="0" required />
                                        <div className="invalid-feedback">The phone number is required</div>
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control form-bottomline" placeholder="Business Name" aria-label="Business Name" defaultValue="" name="business_name" id="business_name" />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col">
                                        <input type="search" className="form-control form-bottomline" placeholder="Select Your Timezone" list="yourtimezone" defaultValue="" name="time_zone" id="time_zone" />
                                        <datalist id="yourtimezone">
                                            <option>EST</option>
                                            <option>CST</option>
                                            <option>MST</option>
                                            <option>PST</option>
                                        </datalist>
                                    </div>
                                    <div className="col">
                                        <input type="datetime-local" title="Select date and time" className="form-control form-bottomline" placeholder="Select Date and time" aria-label="Business Email" name="requested_date" id="requested_date" defaultValue="" />
                                    </div>
                                </div>  
                                <div className="row mt-4">
                                    <div className="col form-floating">
                                        <textarea className="form-control form-bottomline" rows="5" placeholder="Notes" name="notes" id="notes" defaultValue="" ></textarea>
                                        <label htmlFor="floatingTextarea">Notes</label>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col">
                                        <div className="d-flex flex-column  gap-3 align-items-start">
                                            <div className="captcha_wrapper">
                                                <div className="g-recaptcha" id="g_recaptcha_contact" data-sitekey="6Lexnr8UAAAAAApT7UhSkNFBKTbkya4gnijVi9jb">
                                                </div>
                                                <span className="msg-error text-danger d-none" id="msg-error">Robot verification failed, please try again.</span>
                                            </div>
                                            <button type="button" className="new-btn btn-gradiant m-0" onClick={(e) => saveData(e)} disabled={tinyloader} id="submit-request">
                                                {
                                                    !tinyloader ? 'Schedule My Demo'
                                                        :
                                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                         <p className="mb-0 mt-2  font-medium text-success opacity-75">We respect your privacy. Your information is safe with us.</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4 col-lg-6 text-center">
                            <div className="bg-white h-100" id="video-section"></div>
                        </div>
                    </div>
                </div>
                <div class="digital-trends-bg z-n1 position-absolute top-0 start-0 w-100 h-100 bg-gradiant2"><span class="flip-y"></span></div>
            </section>

            <section className="section-padding bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">
                            <div className="new-heading mb-4 text-md-center">
                                <h4>Frequently Asked Questions</h4>
                            </div>                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto">                            
                            <div className="accordion" id="accordionExample">
                                {faq && faq.map((ele, i) => {
                                    return (
                                        <div className="accordion-item" key={i}>
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className={`accordion-button ${i !== 0 ? 'collapsed' : ''} fw-bold`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapseThree${i}`} aria-expanded={`${i !== 0 ? 'false' : 'true'}`} aria-controls="collapseThree">
                                                    {ele.question}
                                                </button>
                                            </h2>
                                            <div id={`collapseThree${i}`} className={`accordion-collapse collapse ${i !== 0 ? '' : 'show'}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        {ele.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-md-10 mx-auto text-center mt-4">   
                            <h5>Still have questions? <Link to="/contact-us" className="btn btn-link">Contact Us</Link></h5>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RequestDemo