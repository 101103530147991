import * as Elements from "../../../components/Elements";

function SmallBusiness() {
    
    return (
        <>
            <Elements.InnerBanner  slim="py-0" title="Small Business" subtitle="Local search no matter your size" />

            <section className="section-padding">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                                As a small business owner, you already wear at least one too many hats. But that doesn't mean you don't need a way to attract mobile users and get traffic to your website. Amazio is here to help you update, control, and manage how your business appears online so you can get more customers in less time.
                            </p>
                            <p className="mt-4">
                                Amazio can help your small business operate more efficiently in the following ways:
                            </p>
                            <h5 className="fw-bold">1. Leverage data to reach local searchers</h5>
                            <p className="">
                                Get local business with optimized searches for local users. Amazio will drill down into the data to help you reach your target customers to make it easier for them to find you when they perform a local search. This means you'll spend less time seeking out customers and more time helping them!
                            </p>
                            <h5 className="mt-4 fw-bold">2. Store, manage and consolidate user data</h5>
                            <p className="">
                                Once you get user data, what are you supposed to do with it? Amazio will make it simple for you to manage data and keep track of it with easy storage and consolidation options.
                            </p>
                            <h5 className="mt-4 fw-bold">3. Attract customers in the right place at the right time</h5>
                            <p className="">Knowing when your customers are searching for you and what platforms they are searching from is an extremely powerful tool that will help you reach them when they need you the most. Amazio's software application will help you reach the right customers at the right time.</p>
                            <h5 className="mt-4 fw-bold">4. Centralize control of listings</h5>
                            <p className="">The internet is a big place, and there are a lot of websites out there with your information. Manage your listings across the web with centralized control.</p>
                            <h5 className="mt-4 fw-bold">5. Apply rich content for a better user experience</h5>
                            <p className="">Rich content in the form of infographics, helpful charts, and videos can attract customers and get you additional site traffic through channels like YouTube and Facebook. Amazio understands how rich content should be used to create a better user experience for your customers.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SmallBusiness