import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function Websites() {
    

    return (
        <>
            {/* <Elements.PageTitleSection title="Web & App Development with Maintenance"/>

            <section className="py-5 container">
                <div className="row">
                    <div className="col-sm-12">
                        <p className="lh-md mb-4">
                            We organically & strategically design and develop websites that elevate your brand and communicate your intent effectively. We go a step further without stopping at just building a website. We coordinate with you to continuously evolve your online presence on both web and mobile apps.
                        </p>
                        <h4 className="fw-bolder mb-4">Individual Websites</h4>
                        <div className="row g-4">
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template-v1/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-1.png" alt="template-1" style={{bottom:"-817px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 1</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template-v2/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-2.png" alt="template-2" style={{bottom:"-1210px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 2</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template-v3/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-3.png"  alt="template-3" style={{bottom:"-772px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 3</h6>
                                </a>
                            </div>


                            

                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template-v6" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-6.png" alt="template-4" style={{bottom:"-1050px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 4</h6>
                                </a>
                            </div>
                            
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template-v7" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-7.png" alt="template-5" style={{bottom:"-715px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 5</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template1" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-1.png" alt="template-6" style={{bottom:"-568px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 6</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template2" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-2.png" alt="template-7" style={{bottom:"-452px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 7</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template3" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-3.png" alt="template-8" style={{bottom:"-505px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 8</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template5" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-5.png" alt="template-9" style={{bottom:"-653px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 9</h6>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template4/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-old-4.png" alt="template-10" style={{bottom:"-445px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 10</h6>
                                </a>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template11/" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-8.png" alt="template-11" style={{bottom:"-566px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 11</h6>
                                </a>
                            </div>

                           

                        </div>
                        <hr className="opacity-10 my-4"/>
                        <h4 className="fw-bolder pt-2 mb-4">Hub Websites</h4>
                        <div className="row g-4">
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template-v4" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-4.png" alt="template-12" style={{bottom:"-1088px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 12</h6>
                                </a>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://amazio.site/CEDtemplates/template-v5" rel="noopener noreferrer" target="_blank" className="text-decoration-none">
                                    <div className="web-template-ds01">
                                        <img src="images/templates/template-new-5.png" alt="template-13" style={{bottom:"-778px"}} />
                                    </div>
                                    <h6 className="py-3 text-center fs-5 fw-bolder">Template - 13</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

			<Elements.InnerBanner title="Web & App Development with Maintenance" />
			<div className="section-padding">
				<div className="container">
					<div className="row align-items-center g-4">
						<div className="col-md-10 col-lg-8 mx-auto">
							<div className="service-content-box">
								<div className="new-heading mb-0 text-center">
									<h4>Crafting Digital Experiences, Sustaining Perfection
									</h4>
									<p>At Amazio, we don’t just build apps and websites; we craft seamless digital experiences designed to evolve with your needs.</p>
                                    <p><strong>Ready to Launch and Maintain Your Digital Future? 
                                    </strong></p>
									<div className="mt-3">
										<Link to="/contact-us" className="new-btn btn-gradiant" >Let’s Talk</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="section-padding pt-0">
				<div className="container">
					<div className="row ">
						<div className="col-md-12">
							<div className="bottom-service-ctabox right-img">
								<div className="ps-4">
									<h6 className="text-primary">Our Templates</h6>
									<h4>Explore Our Strategically Designed <br className="d-none d-lg-block" /> Web Templates
									</h4>
									{/* <p>We organically & strategically design and develop websites that elevate your brand and communicate your intent effectively. We go a step further without stopping at just building a website. We coordinate with you to continuously evolve your online presence on both web and mobile apps. </p> */}
									<div className="bottom-service-cta mt-3">
										<a className="new-btn btn-gradiant" href="/web-templates">View All Templates</a>
									</div>
								</div>
								<img src="images/cta-templete.jpg" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="section-padding border-top">
				<div className="container">
					<div className="row ">
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-0">
									<h4>Turning Ideas into Timeless Digital Solutions with Our Mobile & App Development </h4>
									<p> In today’s fast-paced digital world, an unoptimized or poorly maintained app or website can hurt your brand’s reputation and push potential customers away. Development alone isn’t enough – ongoing maintenance is critical to keep your digital presence efficient and secure.
 									</p>
									<p>At Amazio, we specialize in building cutting-edge websites and mobile apps and ensuring they continue to run flawlessly. We handle everything - from development and design to ongoing maintenance and optimization - so you can focus on growing your business while we ensure your digital assets stay ahead of the curve.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row border-top pt-5 mt-5">
                        <div className="col-md-12">
                            <div className="new-heading mb-5 text-center">
                                <h4>Types of Web & App Development <br className="d-none d-md-block" /> with Maintenance
                                </h4>
                            </div>
                        </div>
                    </div>
					<div className="row flex-column-reverse flex-md-row align-items-center">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
                                <div className="new-heading mb-4">
                                    <h5>Web Development Services
                                    </h5>
                                </div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Custom Websites: 
										</h5>
										<p>Tailor-made to meet your business goals.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> E-commerce Platforms:
										</h5>
										<p>Optimized for seamless shopping experiences.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Responsive Websites: 
										</h5>
										<p>Designed to work beautifully across all devices.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.2.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.2.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
                                <div className="new-heading mb-4">
                                    <h5>App Development Services
                                    </h5>
                                </div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> iOS & Android Apps:
										</h5>
										<p> Native apps for a seamless user experience.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Cross-Platform Apps:
										</h5>
										<p> Maximize your reach with apps that work on multiple platforms.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Enterprise Apps:
										</h5>
										<p> Scalable solutions for your business operations.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row align-items-center border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
                                <div className="new-heading mb-4">
                                    <h5>Maintenance Services</h5>
                                </div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Performance Optimization:
										</h5>
										<p> Keep your platform running smoothly.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Security Updates: 
										</h5>
										<p>Protect your data with regular updates. 
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Bug Fixes:
										</h5>
										<p> Swift resolution of issues to ensure flawless user experiences.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.2.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-4">
									<h4>Our Roadmap to Web & App Development with Maintenance
									</h4>
								</div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Consultation & Planning:
										</h5>
										<p> Understanding your needs, goals, and vision.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Design & Development:
										</h5>
										<p> Crafting intuitive designs and robust code.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Testing & Launch:
										</h5>
										<p> Rigorous testing for a flawless launch.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Maintenance & Support:
										</h5>
										<p> Regular updates, security patches, and performance optimization to keep your app or website at its best.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row  border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ">
								<div className="new-heading mb-4">
									<h4>Why Choose Amazio?
									</h4>
								</div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Expert Team: 
										</h5>
										<p>A dedicated team of developers, designers, and maintenance specialists.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> End-to-End Solutions:
										</h5>
										<p> From ideation to launch and continuous support. 
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Scalable & Future-Proof:
										</h5>
										<p> Solutions designed to grow with your business.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i>24/7 Support:
										</h5>
										<p> We’re here to help whenever you need us.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Client-Focused Approach: 
										</h5>
										<p>Your vision, your goals – our expertise to bring them to life.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="section-padding border-top">
				<div className="container">
					<div className="row ">
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-0">
									<h4>Turning Ideas into Timeless Digital Solutions with Our Mobile & App Development </h4>
									<p> In today’s fast-paced digital world, an unoptimized or poorly maintained app or website can hurt your brand’s reputation and push potential customers away. Development alone isn’t enough – ongoing maintenance is critical to keep your digital presence efficient and secure.
 									</p>
									<p>At Amazio, we specialize in building cutting-edge websites and mobile apps and ensuring they continue to run flawlessly. We handle everything - from development and design to ongoing maintenance and optimization - so you can focus on growing your business while we ensure your digital assets stay ahead of the curve.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row border-top pt-5 mt-5">
                        <div className="col-md-12">
                            <div className="new-heading mb-5 text-center">
                                <h4>Types of Web & App Development <br className="d-none d-md-block" /> with Maintenance
                                </h4>
                            </div>
                        </div>
                    </div>
					<div className="row flex-column-reverse flex-md-row align-items-center">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
                                <div className="new-heading mb-4">
                                    <h5>Web Development Services
                                    </h5>
                                </div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Custom Websites: 
										</h5>
										<p>Tailor-made to meet your business goals.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> E-commerce Platforms:
										</h5>
										<p>Optimized for seamless shopping experiences.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Responsive Websites: 
										</h5>
										<p>Designed to work beautifully across all devices.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.2.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row align-items-center border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.2.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
                                <div className="new-heading mb-4">
                                    <h5>App Development Services
                                    </h5>
                                </div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> iOS & Android Apps:
										</h5>
										<p> Native apps for a seamless user experience.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Cross-Platform Apps:
										</h5>
										<p> Maximize your reach with apps that work on multiple platforms.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Enterprise Apps:
										</h5>
										<p> Scalable solutions for your business operations.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row align-items-center border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box">
                                <div className="new-heading mb-4">
                                    <h5>Maintenance Services</h5>
                                </div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Performance Optimization:
										</h5>
										<p> Keep your platform running smoothly.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Security Updates: 
										</h5>
										<p>Protect your data with regular updates. 
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Bug Fixes:
										</h5>
										<p> Swift resolution of issues to ensure flawless user experiences.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.2.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
					<div className="row border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-5 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.3.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ps-md-4">
								<div className="new-heading mb-4">
									<h4>Our Roadmap to Web & App Development with Maintenance
									</h4>
								</div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Consultation & Planning:
										</h5>
										<p> Understanding your needs, goals, and vision.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Design & Development:
										</h5>
										<p> Crafting intuitive designs and robust code.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Testing & Launch:
										</h5>
										<p> Rigorous testing for a flawless launch.
										</p>
									</li>
									<li>
										<h5>
											<i className="bi bi-check-circle-fill"></i>
											Maintenance & Support:
										</h5>
										<p> Regular updates, security patches, and performance optimization to keep your app or website at its best.
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row  border-top pt-5 mt-5">
						<div className="col-md-6 col-lg-7">
							<div className="service-content-box ">
								<div className="new-heading mb-4">
									<h4>Why Choose Amazio?
									</h4>
								</div>
								<ul className="service-content-list">
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Expert Team: 
										</h5>
										<p>A dedicated team of developers, designers, and maintenance specialists.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> End-to-End Solutions:
										</h5>
										<p> From ideation to launch and continuous support. 
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Scalable & Future-Proof:
										</h5>
										<p> Solutions designed to grow with your business.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i>24/7 Support:
										</h5>
										<p> We’re here to help whenever you need us.
										</p>
									</li>
									<li>
										<h5><i className="bi bi-check-circle-fill "></i> Client-Focused Approach: 
										</h5>
										<p>Your vision, your goals – our expertise to bring them to life.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
							<div className="service-img-box">
								<img src="images/service-imgs/s7.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-padding pt-0">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="bottom-service-ctabox">
								<div className="ps-4">
									<h4>Let’s Create and Maintain Something Amazing Together! 
									</h4>
									<div className="bottom-service-cta mt-3">
										<Link to="/contact-us" className="new-btn btn-gradiant" >
											Get in Touch
										</Link>
									</div>
								</div>
								<img src="images/service-cta.jpg" alt="Digital Presence" />
							</div>
						</div>
					</div>
				</div>
			</div>
        </>
    )
}

export default Websites