import { Link } from "react-router-dom";
import * as Elements from "../../components/Elements";

function DigitalContentService() {
    

    return (
        <>
            <Elements.InnerBanner title="Digital Content Services" />
            <div className="section-padding">
                <div className="container">
                    <div className="row align-items-center g-4">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <div className="service-content-box">
                                <div className="new-heading mb-0 text-center">
                                    <h4>Transforming Ideas into Impactful Messages
                                    </h4>
                                    <p>At Amazio, we know that compelling content drives engagement and conversions. Our Digital Content Services focus on delivering high-quality, relevant content that resonates with your target audience. We bring your brand’s message to life through strategic content development, storytelling, and tailored communication.
                                    </p>
                                    <div className="mt-3">
                                        <Link to="/request-demo" className="new-btn btn-gradiant" >Book a Consultation Today</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-padding border-top">
                <div className="container">
                    {/* <div className="row align-items-center">
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s4.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">
                                <div className="new-heading mb-0">
                                    <h4>Bringing Your Vision to Life in a Digital World
                                    </h4>
                                    <p>Low-quality images and uninspired content can leave your audience disengaged and your message lost. You need visuals that don’t just catch the eye but also tell a compelling story.</p>
                                    <p>At <strong>Amazio</strong> we specialize in creating digital content and imaging services that do just that. We combine creativity, technology, and strategy to provide high-quality visuals and content that connect with your audience. Whether it's eye-catching graphics, professional photography, or dynamic digital assets, we’re here to bring your ideas to life and help your brand stand out.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row align-items-center">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
                                    <h4>Our Services
                                    </h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box">
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Content Strategy & Development:
                                        </h5>
                                        <p>Customized content strategies that boost brand visibility and engage your audience across digital platforms.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> SEO Copywriting:
                                        </h5>
                                        <p>SEO-optimized content that enhances search engine rankings, helping your message reach the right audience.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Social Media Content:
                                        </h5>
                                        <p>Engaging, shareable content that increases interaction, enhances brand awareness, and drives engagement across social platforms.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Blog Writing & Articles:
                                        </h5>
                                        <p>In-depth, informative, and engaging blog posts and articles to establish your brand’s authority in your industry.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Email Campaigns & Newsletters:
                                        </h5>
                                        <p>Persuasive email content that nurtures leads, fosters brand loyalty, and drives conversions.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Script Writing for Videos: 
                                        </h5>
                                        <p>Tailored scripts for promotional videos, ads, tutorials, and other video content, ensuring clarity and impact in every message.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Content Editing & Proofreading: 
                                        </h5>
                                        <p>Professional editing and proofreading to ensure flawless, polished content that aligns with your brand's voice.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s10.1.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-8 mx-auto">                                                         
                            <div className="new-heading mb-4 mb-md-5 text-md-center">
                                <h4>Our Approach to Content Creation
                                </h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-4 mb-md-0 order-n1">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s10.2.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ps-md-4">
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Consultation & Vision Understanding: 
                                        </h5>
                                        <p>We start by deeply understanding your goals and audience, creating content that aligns with your brand's vision and identity.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Strategy & Content Creation:
                                        </h5>
                                        <p>Our team creates high-quality, relevant content that resonates with your audience, whether it's blog posts, social media updates, or email campaigns.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Review & Refinement:
                                        </h5>
                                        <p>We collaborate with you throughout the process, refining the content to ensure it perfectly fits your objectives.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Delivery & Support:
                                        </h5>
                                        <p>You’ll receive optimized, ready-to-use content, accompanied by ongoing support to ensure long-term success.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>
                                            <i className="bi bi-check-circle-fill"></i>
                                            Distribution & Promotion:
                                        </h5>
                                        <p>We ensure your content is distributed and promoted effectively, enhancing visibility and engagement across multiple platforms, reaching your target audience.
                                        </p>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row flex-column-reverse flex-md-row align-items-center border-top pt-5 mt-5">
                        <div className="col-md-6 col-lg-7">
                            <div className="service-content-box ">
                                <div className="new-heading mb-4">
                                    <h4>Why Choose Amazio for Content and Design Services?
                                    </h4>
                                </div>
                                <ul className="service-content-list">
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Creativity & Innovation:
                                        </h5>
                                        <p>We push creative boundaries to deliver unique and engaging results that resonate with your audience.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Attention to Detail:
                                        </h5>
                                        <p>Every project is executed with meticulous attention to detail, ensuring high levels of precision and quality in every design.
                                        </p>
                                    </li>
                                    <li>
                                        <h5><i className="bi bi-check-circle-fill "></i> Client-Centric Approach:
                                        </h5>
                                        <p>We prioritize collaboration and place your vision at the heart of every project, ensuring complete satisfaction.
                                        </p>
                                    </li>
                                    <li>

                                        <p>Ready to make an impact with exceptional content or stunning design? Let’s connect and create something incredible together.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 ps-md-4 mb-4 mb-md-0">
                            <div className="service-img-box">
                                <img src="images/service-imgs/s4.4.png" className="w-100 shadow rounded p-1" alt="Digital Presence" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="section-padding pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="bottom-service-ctabox">
                                <div className="ps-4">
                                    <h4>Ready to Make an Impact? Let’s Create Visual Magic Together.</h4>
                                    <div className="bottom-service-cta mt-3">
                                        <Link to="/contact-us" className="new-btn btn-gradiant" >
                                        Let’s Talk
                                        </Link>
                                    </div>
                                </div>
                                <img src="images/service-cta.jpg" alt="Digital Presence" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalContentService